import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() total: number = 0;
  @Input() perPage: string = "10";
  @Input() currentPage: string = "0";
  @Input() lastPage: string = "0";
  @Input() totalPage: string = "1";
  @Input() firstPageUrl: string;
  @Input() lastPageUrl: string;
  @Input() nextPageUrl: string;
  @Input() prevPageUrl: string;
  @Input() path: string;
  @Input() from: number = 0;
  @Input() to: number = 0;
  @Input() dataSet: any;;
  @Input() url: string;

  @Output() handleNext = new EventEmitter<string>();
  @Output() handlePrevious = new EventEmitter<string>();
  @Output() handleLastPage = new EventEmitter<string>();
  @Output() handleFirstPage = new EventEmitter<string>();
  @Output() initialiser = new EventEmitter<paginator.Paginator>();
  @Output() onPageSizeChange = new EventEmitter<string>();

  disableFirstPage: boolean = false;
  disableNextPage: boolean = false;
  disablePrevPage: boolean = false;
  disableLastPage: boolean = false;
  constructor() { }

  ngOnInit() {
    this.initialisePaginator();
  }
  initialisePaginator() {
    // Disable Last and Next Page
    if (this.totalPage == "0" || (parseInt(this.currentPage + 1) === parseInt(this.totalPage))) {
      this.disableNextPage = this.disableLastPage = true;
    } else {
      this.disableNextPage = this.disableLastPage = false;
    }
    // Disable First and Prev Page
    if (parseInt(this.currentPage) === 0) {
      this.disablePrevPage = this.disableFirstPage = true;
    } else {
      this.disablePrevPage = this.disableFirstPage = false;
    }
    this.initialiser.emit()
  }
  onFirstPageClick() {
    const body = new HttpParams()
      .set('page', "0")
      .set('size', this.perPage);
    if (this.url.includes("?")) {
      this.handleFirstPage.emit(this.url + '&' + body.toString());
    } else {
      this.handleFirstPage.emit(this.url + '?' + body.toString());
    }
  }
  onPreviousClick() {
    const body = new HttpParams()
      .set('page', (parseInt(this.currentPage) - 1).toString())
      .set('size', this.perPage);
    if (this.url.includes("?")) {
      this.handlePrevious.emit(this.url + '&' + body.toString());
    } else {
      this.handlePrevious.emit(this.url + '?' + body.toString());
    }
  }
  onNextClick() {
    const body = new HttpParams()
      .set('page', (parseInt(this.currentPage) + 1).toString())
      .set('size', this.perPage);
    if (this.url.includes("?")) {
      this.handleNext.emit(this.url + '&' + body.toString());
    } else {
      this.handleNext.emit(this.url + '?' + body.toString());
    }
  }
  onLastPageClick() {
    const body = new HttpParams()
      .set('page', (parseInt(this.totalPage) - 1).toString())
      .set('size', this.perPage);
    if (this.url.includes("?")) {
      this.handleLastPage.emit(this.url + '&' + body.toString());
    } else {
      this.handleLastPage.emit(this.url + '?' + body.toString());
    }
  }
  onSetData(data: any) {
    if (data) {
      this.currentPage = data.number;
      this.perPage = data.size;
      this.lastPage = data.totalPages //totalPages
      this.totalPage = data.totalPages // totalPages
      this.from = data.pageable.offset + 1;
      if (data.content) {
        this.to = data.pageable.offset + data.content.length
      }
      else {
        this.to = 0;
      }
      this.total = data.totalElements;
    }
  }
  onPageSizeChangeClick(event) {
    this.perPage = event.value;
    const body = new HttpParams()
      // .set('page', (parseInt(this.currentPage)).toString())
      .set('page', "0")
      .set('size', this.perPage);
    if (this.url.includes("?")) {
      this.onPageSizeChange.emit(this.url + '&' + body.toString());
    } else {
      this.onPageSizeChange.emit(this.url + '?' + body.toString());
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSet && changes.dataSet.currentValue) {
      this.onSetData(changes.dataSet.currentValue)
      this.initialisePaginator();
    }
  }
}
