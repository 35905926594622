import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-perform-modal',
  templateUrl: './perform-modal.component.html',
  styleUrls: ['./perform-modal.component.scss']
})
export class PerformModalComponent implements OnInit {
  showButtonCheck: boolean = false;
  showDropDown: boolean = false;
  dropData = ['Serviced Sensors', 'Installed Sensors', 'Replaced Sensors'];
  constructor(
    public dialogRef: MatDialogRef<PerformModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    //
    if (this.data.subActivity && this.data.subActivity.activityCatergory) {
      this.showButtonCheck = true;
    }
    //
    if (this.data.step === "SERVICE PROCEDURE") {
      this.showDropDown = true;
    }
  }
}
