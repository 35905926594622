
import { UrlList } from './urlList';
export const environment = {
    production: true,
    // cLogo: './assets/images/logoClients/logoFull.png',
    cLogo: '',
    url: {
        siteUrl: UrlList.stageIop.url,
        notificationUrl: UrlList.stageIop.notificationUrl,
        oAuth: UrlList.stageIop.oAuth,
        userName: UrlList.stageIop.username,
        password: UrlList.stageIop.password,
        jenkinsBuildNumUrl: UrlList.stageIop.jenkinsBuildNumUrl,
        fileName : 'iop-stage-ui-version.json',
        bucketName: 'ui-build-versions',
        identityPoolId : 'us-east-1:dd80a5c1-e0bb-4632-a529-47965b6b136d',
        region : 'us-east-1' 
    }
};