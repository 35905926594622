import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/helpers/authentication.service';

const USER = 'user';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  storage: any;
  monthsNum: string[];
  fullMonthSum: string[];
  languageId: any;
  language: any;

  constructor(private _authService: AuthenticationService) {

    this.storage = this._authService.toggleStorage();
    this.languageId = JSON.parse(this.storage.get(USER)).languageId;
    this.language = JSON.parse(this.storage.get(USER)).language;

    if (this.languageId == 1 || this.language == 'en-US') {

      this.monthsNum = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      this.fullMonthSum = ['January','February','March','April','May','June','July','August', 'September', 'October', 'November' ,'December'];

    } else if (this.languageId == 2 || this.language == 'ja-JP') {

      this.monthsNum = ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"];
      this.fullMonthSum = ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"];

    } else if (this.languageId == 3 || this.language == 'es-SP') {

      this.monthsNum = ['Enero', 'Feb', 'Mar', 'Abr', 'Mayo', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      this.fullMonthSum = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];

    }

   }

  @Output() monthRangeSelected = new EventEmitter<any>();

  currentYearIndex: number;
  years: Array<number>;
  months: Array<string>;
  monthsData: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  rangeIndexes: Array<number>;
  monthViewSlicesIndexes: Array<number>;
  monthDataSlice: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  globalIndexOffset: number;

  // onClick(indexClicked) {
  //   if (this.rangeIndexes[0] === null) {
  //     this.rangeIndexes[0] = this.globalIndexOffset+indexClicked;
  //   } else
  //   if (this.rangeIndexes[1] === null) {
  //     this.rangeIndexes[1] = this.globalIndexOffset+indexClicked;
  //     this.rangeIndexes.sort((a,b) => a-b);
  //     this.monthsData.forEach((month, index) => {
  //       if ((this.rangeIndexes[0] <= index) && (index <= this.rangeIndexes[1])) {
  //         month.isInRange = true;
  //       };
  //       if (this.rangeIndexes[0] === index) {
  //         month.isLowerEdge = true;
  //       };
  //       if (this.rangeIndexes[1] === index) {
  //         month.isUpperEdge = true;
  //       };
  //     })
  //     let fromMonthYear = this.monthsData[this.rangeIndexes[0]];
  //     let toMonthYear = this.monthsData[this.rangeIndexes[1]];
  //     this.emitData(fromMonthYear.monthName,fromMonthYear.monthYear ,toMonthYear.monthName, toMonthYear.monthYear)
  //   } else {
  //     this.initRangeIndexes();
  //     this.initMonthsData();
  //     this.onClick(indexClicked);
  //     this.sliceDataIntoView();
  //   };
  // };

  onClick(indexClicked, checkFlag) { 
    if(!checkFlag) {
      this.rangeIndexes[0] = this.globalIndexOffset+indexClicked;
      let fromMonthYear = this.monthsData[this.rangeIndexes[0]];
      // console.log("fromMonthNameYear >>", fromMonthYear)
      this.emitData(fromMonthYear.monthName,fromMonthYear.monthYear);
    }
  }

  emitData(fromMonthName,fromMonthYear) {

    // const monthsNum = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getIndex = this.monthsNum.indexOf(fromMonthName);
    let fromMonthNameYear;

    // const fullMonthSum = ['January','February','March','April','May','June','July','August', 'September', 'October', 'November' ,'December'];
    const getFullName = this.fullMonthSum[getIndex];

    if(getIndex < 9) {
      let setMonth = `0${getIndex+1}`
      fromMonthNameYear = {display : `${getFullName} ${fromMonthYear}`, payload: `02/${setMonth}/${fromMonthYear} 00:00:00`, checkYear: `${fromMonthYear}`, checkMonth: `${getIndex+1}`};
    } else {
      fromMonthNameYear = {display : `${getFullName} ${fromMonthYear}`, payload: `02/${getIndex+1}/${fromMonthYear} 00:00:00`,checkYear: `${fromMonthYear}`, checkMonth: `${getIndex+1}`};
    }
    // const toMonthNameYear = `${toMonthName} ${toMonthYear}`;
    // const obj = {monthYear: fromMonthNameYear};
    this.monthRangeSelected.emit(fromMonthNameYear);
  };

  sliceDataIntoView() {
    this.globalIndexOffset = this.monthViewSlicesIndexes[this.currentYearIndex];
    this.monthDataSlice = this.monthsData.slice(this.globalIndexOffset,this.globalIndexOffset+24); //24
    // console.log("this.monthDataSlice >>", this.monthDataSlice,"this.globalIndexOffset >>",this.globalIndexOffset )
  };

  incrementYear() {
    if (this.currentYearIndex !== this.years.length-1) {
      this.currentYearIndex++
      this.sliceDataIntoView()
    };
  };

  decrementYear() {
    if (this.currentYearIndex !==0) {
      this.currentYearIndex--;
      this.sliceDataIntoView()
    };
  };

  initRangeIndexes() {
    this.rangeIndexes = [ null, null ];
  };

  initMonthsData() {
    this.monthsData = new Array();
    this.years.forEach( year => {
      if (this.languageId == 1 || this.language == 'en-US') {

        this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
      } else if (this.languageId == 2 || this.language == 'ja-JP') {
  
        this.months = ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"];
  
      } else if (this.languageId == 3 || this.language == 'es-SP') {

        this.months = ['Enero', 'Feb', 'Mar', 'Abr', 'Mayo', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
      }
      this.months.forEach( month => {
        this.monthsData.push({
          monthName: month,
          monthYear: year,
          isInRange: false,
          isLowerEdge: false,
          isUpperEdge: false
        })
      })
    })
  };

  initYearLabels() {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    // this.years = range(currentYear-2, currentYear + 5, 1);
    this.years = range(2019, currentYear, 1);
  };

  initMonthLabels() {
    this.months = new Array(12).fill(0).map((_, i) => {
      return new Date(`${i + 1}/1`).toLocaleDateString(undefined, {month: 'short'})
    });
  };

  initViewSlices() {
    this.monthViewSlicesIndexes = [];
    this.years.forEach((year,index) => {
      if (index===0) { this.monthViewSlicesIndexes.push(0) } else
      if (index===1) { this.monthViewSlicesIndexes.push(6) } else
      this.monthViewSlicesIndexes.push(this.monthViewSlicesIndexes[index-1]+12);
    })
  };

  ngOnInit() {
    this.initYearLabels();
    this.initMonthLabels();
    this.initViewSlices();
    this.initMonthsData();
    this.initRangeIndexes();
    this.currentYearIndex = this.years.findIndex(year => year === (new Date()).getFullYear());
    this.sliceDataIntoView();
  };

}

