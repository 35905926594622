import { AuthenticationService } from './../../helpers/authentication.service';
import { CommonDataService } from './../../helpers/services/common-data.service';
import { apiData } from './../../common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { theme } from 'src/app/classModel/themes';
@Injectable({
  providedIn: 'root'
})

export class LoginService {
  constructor(private _route: Router, private _http: HttpClient, private _commonDataService: CommonDataService, private _auth: AuthenticationService) { }

  showLogin(url) {
    this._route.navigate(['./', url]);
  }

  getAuth(loginVal: FormGroup) {
    return this._auth.login(loginVal);
  }

  getOauth(obj) {
    return this._auth.autoLoginUsingToken(obj);
  }

  login(loginVal: FormGroup) {
    return this._http.post(apiData.url + apiData.login, loginVal.value);
  }

  logOut(userEmail: any) {
    return this._http.get(apiData.url + apiData.logout + userEmail + apiData.webLogin);
  }

  loginForgot(email): Observable<any> {
    const headers = new HttpHeaders();
    let url = apiData.url + apiData.loginForgot
    return new Observable(observer => {
      this._commonDataService.postDataNoHeader(email, url, headers).subscribe((res: any) => {

        observer.next(res);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getUser(email): Observable<any> {
    const headers = new HttpHeaders();
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.user + email, headers).subscribe((res: any) => {
        if (res.status === 'ok') {
          observer.next(res);
          observer.complete();
        }
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  changePassword(data): Observable<any> {
    let url = apiData.url + apiData.changePassword;
    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(data, url).subscribe((changePasswordResponse: any) => {

        observer.next(changePasswordResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // changePassword(data) {
  //   let url = apiData.url + apiData.changePassword;
  //   return this._http.put(url, data);
  // }

}