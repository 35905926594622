export const spanishTranslate = {
    "dashboard": "Tablero",
    "sensors": "Sensores",
    "users": "Usuarias",
    "customers": "Clientas",
    "reports": "Informes",
    "total": "Total",
    "settings": "Ajustes",
    "all": "Todos",
    "sensor id": "ID del sensor",
    "customer": "Cliente",
    "site": "Sitio",
    "site details": "Detalles del sitio",
    "zone": "Zona",
    "zone details": "Detalles de zona",
    "close": "Cerrar",
    "scanner": "Escáner",
    "scan": "Escanear",
    "sensor type": "Tipo de sensor",
    "add sensor": "Agregar sensor",
    "sensor name": "Nombre del sensor",
    "zone map": "Mapa de zona",
    "add user": "Agregar usuario",
    "all users": "Todos los usuarios",
    "superadmin": "Superadmin",
    "regional manager": "Gerente regional",
    "branch manager": "Gerente de sucursal",
    "servicetechnician": "Técnico de mantenimiento",
    "service technician": "Técnico de mantenimiento",
    "custom users": "Usuarios personalizados",
    "username": "Nombre de usuario",
    "email": "Correo electrónico",
    "first name": "Primer nombre",
    "last name": "Apellido",
    "role": "Rol",
    "actions": "acciones",
    "resource allocation": "Asignación de recursos",
    "my profile": "Mi perfil",
    "edit user": "editar usuario",
    "update": "Actualizar",
    "please enter your username": "Por favor, introduzca su nombre de usuario",
    "please enter site name": "Ingrese el nombre del sitio",
    "please enter your email": "Por favor introduzca su correo electrónico",
    "please enter valid email": "Por favor introduzca un correo electrónico válido",
    "please enter your password": "Por favor, introduzca su contraseña",
    "please confirm your password": "Por favor, confirme su contraseña",
    "passwords do not match": "Las contraseñas no coinciden",
    "please enter your first name": "Por favor, introduzca su nombre",
    "please enter your last name": "Por favor ingrese su apellido",
    "please select language": "Seleccione el idioma",
    "please enter your timezone": "Ingrese su zona horaria",
    "please enter your phone no.": "Por favor ingrese su número de telefono.",
    "please enter valid phone no.": "Ingrese un número de teléfono válido",
    "please enter your city": "Ingrese su ciudad ",
    "please enter your state": "Ingrese su estado",
    "please enter your country": "Ingrese su país",
    "please enter your address": "Por favor ingrese su direccion",
    "please enter your zip code/postal code": "Ingrese su código postal",
    "role name": "Nombre de rol",
    "last updated at": "Última actualización a las",
    "create customer": "Crear cliente",
    "resources": "Recursos",
    "sites": "Sitios",
    "zones": "Zonas",
    "address": "Dirección",
    "country": "País",
    "state": "Estado",
    "city": "Ciudad ",
    "zip code/postal code": "Código postal",
    "contact person": "Persona de contacto",
    "upload photo": "Subir foto",
    "details": "Detalles",
    "user details": "Detalles de usuario",
    "enter user details": "Ingrese los detalles del usuario",
    "offline": "Desconectado",
    "rodent": "Roedor",
    "glue board": "Tablero de pegamento",
    "fly": "Mosca",
    "cockroach": "Cucaracha",
    "Cockroach": "Cucaracha",
    "Crawling Insect": "Insecto rastrero",
    "crawling insect": "Insecto rastrero",
    "add site": "Agregar sitio",
    "site name": "Nombre del sitio",
    "select site type": "Seleccione el tipo de sitio",
    "air quality": "Calidad del aire",
    "zone list": "Lista de zonas",
    "zone name": "Nombre de zona",
    "select site": "Seleccionar sitio",
    "edit profile": "Editar perfil",
    "edit theme": "Editar tema",
    "change password": "Cambiar la contraseña",
    "new password": "Nueva contraseña",
    "confirm new password": "Confirmar nueva contraseña",
    "sign out": "Desconectar",
    "sign in": "Iniciar sesión",
    "enter username": "Introducir nombre de usuario",
    "enter password": "Introducir contraseña",
    "password": "Contraseña",
    "remember me": "Recuérdame",
    "forgot password?": "¿Has olvidado tu contraseña?",
    "download csv file": "Descargar archivo CSV",
    "download pdf file": "Descargar archivo PDF",
    "download png file": "Descargar archivo PNG",
    "pest infestation": "Infestación de plagas",
    "notification": "Notificación",
    "timeseries": "Series de tiempo",
    "add action": "Agregar acción",
    "online": "En línea",
    "select customer": "Seleccionar cliente",
    "select zone": "Seleccionar zona",
    "please choose a excel file": "Elija un archivo de Excel",
    "ok": "Ok",
    "clear": "Cancelar",
    "delete": "Borrar",
    "re-assign sensor": "Reasignar sensor",
    "edit sensor": "Editar sensor",
    "update sensor": "Actualizar sensor",
    "sensor details": "Detalles del sensor",
    "sensor unavailable": "Sensor no disponible",
    "sensor id unavailable": "ID de sensor no disponible",
    "sensor installed": "Sensor instalado",
    "re assign sensor": "Volver a asignar sensor",
    "re assigned successfully": "Reasignado correctamente",
    "directional chart": "Carta direccional",
    "no sensor available": "No Sensor Available",
    "to add a sensor.": "Para agregar un sensor.",
    "select pest type": "Seleccione el tipo de plaga",
    "customer-site time series comparison": "Comparación de series de tiempo entre clientes y sitios",
    "hour": "Hora",
    "week": "Semana",
    "month": "Mes",
    "year": "Año",
    "notifications": "Notificaciones",
    "select sensor": "Seleccionar sensor",
    "enable": "Habilitar",
    "disable": "Deshabilitar",
    "user successfully created.": "Usuario creado con éxito.",
    "user successfully updated.": "Usuario actualizado con éxito.",
    "customer successfully created.": "Cliente creado con éxito.",
    "show customer list": "Mostrar lista de clientes",
    "individual customer": "Cliente individual",
    "site successfully created.": "Sitio creado correctamente.",
    "show site list": "Mostrar lista de sitios",
    "show zone list": "Mostrar lista de zonas",
    "choose  a activity category": "Elija una categoría de actividad",
    "processing steps": "Pasos de procesamiento",
    "assigned successfully": "Asignado exitosamente",
    "yes": "SÍ",
    "no": "NO",
    "updated successfully": "Actualizado con éxito",
    "please choose a image file": "Elija un archivo de imagen",
    "no user available": "Ningún usuario disponible",
    "click here": "Haga clic aquí",
    "copy create role": "Copiar Crear rol",
    "edit customer": "Editar cliente",
    "edit site": "Editar sitio",
    "day": "Día",
    "today": "Hoy",
    "deleted successfully": "Eliminado con éxito",
    "created successfully": " Creado con éxito",
    "edited successfully": "Editado con éxito",
    "password changed successfully.": "Contraseña cambiada con éxito.",
    "site successfully updated.": "Sitio actualizado con éxito.",
    "customer successfully updated.": "Cliente actualizado con éxito.",
    "select all": "Seleccionar todo",
    "collapse": "Colapsar",
    "expand": "Expandir",
    "select language": "Seleccione el idioma",
    "direction": "Dirección",
    "sensor": "Sensor",
    "hour of the day": "Hora del dia",
    "select timezone": "Selecciona la zona horaria",
    "roles": "Roles",
    "total count": "Cuenta total",
    "from": "De",
    "to": "Para",
    "no.": "Número.",
    "stop scan": "Parar escaneo",
    "aqi": "AQI",
    "at": "a",
    "edit role": "Editar rol",
    "updated by": "Actualizado por",
    "updated at": "Actualizado a las",
    "created by": "Creado por",
    "successfully sensor installed": "Sensor instalado correctamente",
    "sensor updated successfully": "Sensor actualizado con éxito",
    "custom role created successfully": "Rol personalizado creado correctamente",
    "custom role edited successfully": "Rol personalizado editado correctamente",
    "sensor already added": "Sensor ya agregado",
    "file formats should be JPG/JPEG/PNG": "Los formatos de archivo deben ser JPG/JPEG/PNG",
    "sensor(s) in the list were added successfully": "Los sensores de la lista se agregaron correctamente",
    "enter your pincode": "Ingrese su código PIN",
    "enter your country": "Ingrese su pais",
    "enter your state": "Ingrese su estado",
    "enter your city": "Ingrese su ciudad",
    "enter your street": "Entra en tu calle",
    "please select your timezone": "Seleccione su zona horaria",
    "please enter zone name": "Ingrese el nombre de la zona",
    "please select role": "Por favor seleccione Rol",
    "please enter your customer name": "Ingrese su nombre de cliente",
    "year of establishment should lie between 1900 and ": "El año de establecimiento debe estar comprendido entre 1900 y ",
    "please enter your contact person": "Ingrese su persona de contacto",
    "pest type": "Tipo de plaga",
    "last updated": "Última actualización",
    "please enter your current password": "Por favor ingrese su contraseña actual",
    "Passwords must be between 6 and 12 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.": "Las contraseñas deben tener entre 6 y 12 caracteres y contener al menos dos de los siguientes: letras mayúsculas, letras minúsculas, números y símbolos.",
    "please select customer (optional)": "Seleccione Cliente (opcional)",
    "sensor error": "Error de sensor",
    "please choose customer": "Por favor elija cliente",
    "please choose site": "Por favor elija el sitio",
    "please choose zone": "Por favor elija zona",
    "please enter sensor name": "Ingrese el nombre del sensor",
    "please choose sensor type": "Elija el tipo de sensor",
    "detail sensor": "Sensor detalle",
    "no user avilable.": "Ningún usuario disponible.",
    "AQI (last 30 days)": "AQI （Últimos 30 días）",
    "rodent sensor": "Sensor de roedores",
    "AQI": "AQI",
    "please enter sensor id": "Ingrese el ID del sensor",
    "sensor already installed": "Sensor ya instalado",
    "deployment type": "tipo de despliegue",
    "max length 200": "Longitud máxima 200",
    "max length 20": "Longitud máxima 20",
    "sensor name must be a minimum of 6 characters": "El nombre del sensor debe tener un mínimo de 6 caracteres",
    "sensor name cannot be blank": "El nombre del sensor no puede estar en blanco",
    "max length 8": "Longitud máxima 8",
    "cancel": "Cancelar",
    "qr code scan": "Escaneo de código QR",
    "file size should be less than 5 MB": "El tamaño del archivo debe ser inferior a 5 MB.",
    "successfully uninstalled the sensor": "Desinstaló exitosamente el sensor",
    "successfully reassigned to selected customer": "Reasignado exitosamente al cliente seleccionado",
    "complete installation": "instalación completa",
    "This Sensor is not currently active on the IoP system and cannot be used.": "Este sensor no está activo actualmente en el sistema IoP y no se puede utilizar.",
    "Invalid sensor ID! This sensor is already assigned elsewhere.": "¡ID de sensor no válido! Este sensor ya está asignado a otro lugar.",
    "This sensor is already installed on your account.": "Este sensor ya está instalado en su cuenta.",
    "This sensor is already added to your account.": "Este sensor ya está agregado a su cuenta.",
    "This sensor is has already been installed on the IoP system.": "Este sensor ya se ha instalado en el sistema IoP.",
    "Invalid sensor ID specified! Please enter a valid sensor ID for installation.": "¡Se ha especificado un ID de sensor no válido! Introduzca un ID de sensor válido para la instalación.",
    "error during get sensor metadata details : sensorId not available": "Error al obtener detalles de metadatos del sensor: sensorId no disponible",
    "no whitespace at the beginning and end": "Sin espacios en blanco al principio y al final",
    "date & time": "Fecha y hora",
    "manage roles": "Administrar roles",
    "reset": "Reiniciar",
    "choose a reporting manager": "Elija un gerente de informes",
    "organisation": "Organización",
    "create new custom role": "Crear nuevo rol personalizado",
    "edit custom role": "Editar rol personalizado",
    "parent role": "Rol padre",
    "enter new custom role name": "Ingrese un nuevo nombre de rol personalizado",
    "please enter custom role name": "Ingrese el nombre del rol personalizado",
    "please enter different custom role name": "Ingrese un nombre de rol personalizado diferente",
    "enter the role description": "Ingrese la descripción del rol",
    "no role available": "No hay rol disponible",
    "permission": "Permiso",
    "create": "Crear",
    "view": "Ver",
    "please enter a valid mobile number": "Por favor, introduzca un número de móvil válido",
    "clone": "Clon",
    "description": "Descripción",
    "delete role": "Eliminar rol",
    "max length 225": "Longitud máxima 225",
    "re-enter password": "Escriba la contraseña otra vez",
    "please enter re-enter password": "Por favor ingrese Vuelva a ingresar la contraseña",
    "name": "Nombre",
    "reporting manager": "Gerente de informes",
    "update user": "Actualizar usuario",
    "timezone": "Zona horaria",
    "click to enable": "Haga clic para habilitar",
    "click to disable": "Haga clic para desactivar",
    "account overview": "Descripción de cuenta",
    "reporting users": "Usuarios que informan",
    "detections": "Detecciones",
    "sensors overview": "Descripción general de los sensores",
    "view all": "Ver todo",
    "pest detections": "Detección de plagas",
    "all sensors": "Todos los sensores",
    "new": "Nuevo",
    "active": "Activo",
    "idle": "Inactivo",
    "home": "Hogar",
    "glue board details": "Detalles del tablero de pegamento",
    "added by": "Añadido por",
    "added on": "Añadido",
    "location description": "Descripción de la ubicación",
    "sensor pictures": "Imágenes del sensor",
    "glue board id": "ID del tablero de pegamento",
    "24 hrs": "24 horas",
    "12 hrs": "12 horas",
    "6 hrs": "6 horas",
    "1 hr": "1 hora",
    "1 week": "1 semana",
    "1 month": "1 mes",
    "lifetime": "Tiempo de vida",
    "remove sensor": "Quitar sensor",
    "What is the reason to remove this sensor?": "¿Cuál es la razón para quitar este sensor?",
    "sensor damaged": "Sensor dañado",
    "other reason": "Otra razon",
    "Yes, I confirm this sensor to be permanently removed": "Sí, confirmo que este sensor se eliminará de forma permanente",
    "Enter Sensor ID or Scan QR code": "Ingrese el ID del sensor o escanee el código QR",
    "Enter Sensor name": "Ingrese el nombre del sensor",
    "select deployment type": "Seleccionar tipo de implementación",
    "enter sensor location description": "Ingrese la descripción de la ubicación del sensor",
    "sensor location description": "Descripción de la ubicación del sensor",
    "Describe the placement of the sensor in words.": "Describe la ubicación del sensor en palabras.",
    "add sensors": "Agregar sensores",
    "basic details": "Detalles básicos",
    "select sensors": "Seleccionar sensores",
    "review & complete": "Revisar y completar",
    "number of sensors to be added": "Número de sensores que se agregarán",
    "please choose number of sensors": "Elija el número de sensores",
    "back": "atrás",
    "save & continue": "Guardar y Continuar",
    "I confirm above sensors to be added to my account.": "Confirmo que los sensores anteriores se agregarán a mi cuenta.",
    "max length 255": "Longitud máxima 255",
    "please choose pest type": "Elija el tipo de plaga",
    "last seen": "Visto por ultima vez",
    "Unavailable": "No disponible",
    "comm status": "Estado de comunicación",
    "Comm Status": "Estado de comunicación",
    "sensor images": "Imágenes del sensor",
    "proceed": "Continuar",
    "are you sure want to cancel it ?": "¿Estás seguro de querer cancelarlo?",
    "weeks": "Semanas",
    "days": "Dias",
    "hours": "Horas",
    "customer information": "Información al cliente",
    "Enter Customer Name": "Ingrese el nombre del cliente",
    "contact person details": "Detalles de la persona de contacto",
    "I confirm to add this new customer to my account": "Confirmo agregar este nuevo cliente a mi cuenta",
    "Update Avatar": "Actualizar avatar",
    "Enter Email ID": "Ingrese el ID de correo electrónico",
    "enter phone number": "Ingresa número telefónico",
    "enter contact person name": "Ingrese el nombre de la persona de contacto",
    "all customers": "Todos los clientes",
    "all sites": "Todos los sitios",
    "all zones": "Todas las zonas",
    "customer, site, zone": "Cliente, sitio, zona",
    "manage sites": "Administrar sitios",
    "postal code": "Código postal",
    "phone": "Teléfono",
    "enter address": "Ingresa la direccion",
    "phone number": "Número de teléfono",
    "email id": "Identificación de correo",
    "customer address": "Dirección del cliente",
    "Manage Sites and Zones": "Administrar sitios y zonas",
    "contact person name": "Nombre de la persona de Contacto",
    "I confirm to add this new customer to my account, and to all the reporting managers above me.": "Confirmo agregar este nuevo cliente a mi cuenta y a todos los administradores de informes que están por encima de mí.",
    "This role name is already taken. Please try a different one!": "Este nombre de función ya está en uso. ¡Prueba con uno diferente!",
    "I confirm to add the above sensors to my account, and to all the managers of this site.": "Confirmo agregar los sensores anteriores a mi cuenta y a todos los administradores de este sitio.",
    "please select the checkbox": "Seleccione la casilla de verificación",
    "remove customer": "Eliminar cliente",
    "customer removed successfully": "Cliente eliminado con éxito",
    "please enter a valid sensor id": "Ingrese un ID de sensor válido",
    "This site name is already taken. Please try a different one!": "Este nombre de sitio ya existe. ¡Prueba con uno diferente!",
    "This zone name is already taken. Please try a different one!": "Este nombre de zona ya existe. ¡Prueba con uno diferente!",
    "This sensor name is already taken. Please try a different one!": "Este nombre de sensor ya existe. ¡Prueba con uno diferente!",
    "sensor name already added": "Nombre del sensor ya agregado",
    "24 Hours": "24 horas",
    "No crawl directional chart event detection.": "Sin detección de eventos de gráficos direccionales de rastreo.",
    "This customer name is already taken. Please try a different one!": "Este nombre de cliente ya existe. ¡Prueba con uno diferente!",
    "resolved": "Resuelto",
    "resolve": "Resolver",
    "Recent Detection": "Detección reciente",
    "detection log": "Registro de detección",
    "time stamp": "Sello de tiempo",
    "detection details": "Detalles de detección",
    "new detection": "Nueva detección",
    "new detections": "Nuevas detecciones",
    "no detections": "Ninguna detección",
    "Proof of Detection Resolution": "Prueba de resolución de detección",
    "I have completed the pest management and sensor services required to resolve this detection.": "He completado el manejo de plagas y los servicios de sensores necesarios para resolver esta detección.",
    "Did you find any evidence of presence of pest?": "¿Encontraste alguna evidencia de presencia de plagas?",
    "Successfully resolved activity": "Actividad resuelta con éxito",
    "Resolution Information": "Información de resolución",
    "Resolution Number": "Número de resolución",
    "resolved by": "Resuelto por",
    "resolved on": "Resuelto el",
    "Date": "Fecha",
    "Time": "Tiempo",
    "view location": "Ver ubicación",
    "Detection has been successfully resolved.": "La detección se ha resuelto con éxito.",
    "sensor is malfunctioning": "El sensor está averiado",
    "sensor is no longer required": "El sensor ya no es necesario",
    "This sensor will be marked as malfunctioning and removed permanently. The removed sensor will not be allowed to be re-added.": "Este sensor se marcará como defectuoso y se eliminará permanentemente. No se permitirá volver a agregar el sensor eliminado.",
    "This sensor will be removed and can be later added back as required.": "Este sensor se quitará y luego se podrá volver a agregar según sea necesario.",
    "I confirm to permanently remove this sensor.": "Confirmo la eliminación permanentemente de este sensor.",
    "I confirm to remove this sensor.": "Confirmo la eliminación de este sensor.",
    "The sensor has been permanently removed": "El sensor se ha eliminado de forma permanente",
    "The sensor has been removed successfully": "El sensor se ha eliminado con éxito",
    "date range": "Rango de fechas",
    "no Glue board details": "Ningún detalle de tablero de pegamento",
    "replace sensor": "Reemplazar sensor",
    "basic information": "Información básica",
    "sensor replacement": "Reemplazo del sensor",
    "Glueboard detection breach limit": "Límite de incumplimiento de violación del tablero de pegamento",
    "Glueboard duration breach limit": "Límite de incumplimiento de duración del tablero de pegamento",
    "Communication status": "Estado de comunicación",
    "pictures": "Imágenes",
    "What is the Reason behind to replace the sensor?": "¿Cuál es la razón detrás de reemplazar el sensor?",
    "Sensor malfunctioning": "Fallo del sensor",
    "Sensor Damaged": "Sensor averiado",
    "Sensor Lost": "Sensor perdido",
    "Technician can give the statement of the existing sensor condition.": "El técnico puede dar una declaración de la condición del sensor existente.",
    "Please describe below": "Por favor describa a continuación",
    "confirm & repalce": "Confirmar y reemplazar",
    "enter the new sensor details": "Ingrese los detalles del nuevo sensor",
    "I confirm to add the new sensor in the place of the sensor selected for replacement. You cannot undo this action once completed.": "Confirmo agregar el nuevo sensor en el lugar del sensor seleccionado para reemplazo. No puede deshacer esta acción una vez completada.",
    "The sensor has been replaced successfully!": "¡El sensor ha sido reemplazado con éxito!",
    "View Sensor list": "Ver lista de sensores",
    "breach limits": "Límites de violación",
    "glueboard": "Tablero de pegamento",
    "Default No. of days for the glueboard expiry": "Número predeterminado de días para la caducidad del tablero de pegamento",
    "Default max fly detections allowed on the glueboard": "Detecciones de moscas máximas predeterminadas permitidas en el tablero de pegamento",
    "please enter No. of days for the glueboard expiry": "Ingrese el número de días para la caducidad del tablero de pegamento",
    "please enter max fly detections allowed on the glueboard": "Ingrese las detecciones máximas de moscas permitidas en el tablero de pegamento",
    "Default fly detections limit": "Límite predeterminado de detecciones de moscas",
    "Default Rodent detections limit": "Límite predeterminado de detecciones de roedores",
    "Please Enter Fly Critical Control Limit": "Ingrese el límite de control crítico para moscas",
    "Please Enter Crawling Insect Critical Control Limit": "Ingrese el límite crítico de control de insectos rastreros",
    "Please Enter Rodent Critical Control Limit": "Ingrese el límite de control crítico para roedores",
    "language": "Idioma",
    "No. of days for the glueboard expiry": "Número de días para la caducidad del tablero de pegamento",
    "max fly detections allowed on the glueboard": "Detecciones máximas permitidas en el tablero de pegamento",
    "Default No. of days for the glueboard expiry between 1 to 999": "Número de días predeterminado para la caducidad del tablero de pegamento entre 1 y 999",
    "Default max fly detections allowed on the glueboard between 1 to 999999": "Detecciones predeterminadas máximas de moscas permitidas en el tablero de pegamento entre 1 y 999999",
    "Default fly detections limit between 1 to 999999": "Límite predeterminado de detecciones de moscas entre 1 y 999999",
    "Default Crawling Insect detections limit between 1 to 999999": "Límite predeterminado de detecciones de insectos rastreros entre 1 y 999999",
    "Default Rodent detections limit between 1 to 999999": "Límite predeterminado de detecciones de roedores entre 1 y 999999",
    "This sensor will be marked as malfunctioning and removed permanently. The replaced sensor will not be allowed to be re-added.": "Este sensor se marcará como defectuoso y se eliminará permanentemente. No se permitirá volver a agregar el sensor reemplazado.",
    "max fly detections allowed on the glueboard between 1 to 999999": "Detecciones máximas de moscas permitidas en el tablero de pegamento entre 1 y 999999",
    "No. of days for the glueboard expiry between 1 to 999": "Número de días para la caducidad del tablero de pegamento entre 1 y 999",
    "new sensors": "Nuevos sensores",
    "active sensors": "Sensores activos",
    "idle sensors": "Sensores inactivos",
    "offline sensors": "Sensores fuera de línea",
    "breach": "violación",
    "to create a customer": "Crear un cliente",
    "Replacing sensor should be of the same pest type.": "El sensor de reemplazo debe ser del mismo tipo de plaga.",
    "site type": "Tipo de sitio",
    "no customer available.": "Ningún cliente disponible.",
    "no site available": "Ningún sitio disponible",
    "site event log": "Registro de eventos del sitio",
    "add site & zone": "Agregar sitio y zona",
    "add more": "Añadir más",
    "enter state": "Ingrese el estado",
    "Enter zip code/postal code": "Ingrese el código postal",
    "enter site name": "Ingrese el nombre del sitio",
    "enter city": "Ingrese la ciudad",
    "enter country": "Ingrese el país",
    "service team": "Equipo de mantenimiento",
    "user name": "Nombre de usuario",
    "I confirm to add this new site to my account, service team and to all the reporting managers.": "Confirmo agregar este nuevo sitio a mi cuenta, al equipo de mantenimiento y a todos los gerentes de reportes.",
    "please select timezone": "Seleccione la zona horaria",
    "site information": "Información del sitio",
    "edit site & zone": "Editar sitio y zona",
    "Fly Glueboard Settings": "Configuración de Tablero de Pegamento",
    "Critical Control Limits": "Límites críticos de control",
    "Default Fly Critical Control Limit of the Zone": "Límite predeterminado de control crítico de moscas de la zona",
    "Default Crawling Insect Critical Control Limit of the Zone": "Límite predeterminado de control crítico de insectos rastreros de la zona",
    "Default Rodent Critical Control Limit of the Zone": "Límite predeterminado de control crítico de roedores de la zona",
    "resolved detections": "Detecciones resueltas",
    "is Breached on": "se ha violado el",
    "is Breached at": "se ha violado en",
    "Fly Glueboard Detections": "Detecciones en Tablero de Pegamento para moscas",
    "Zone Rodent Critical Control Limit": "Límite de control crítico de roedores de la zona",
    "Zone Fly Critical Control Limit": "Límite de control crítico de moscas de la zona",
    "Zone Crawling Insect Critical Control Limit": "Límite de control crítico de cucarachas de la zona",
    "manage zones": "Administrar zonas",
    "breach limit": "Límite de incumplimiento",
    "click": "Hacer clic",
    "This service team member is already taken. Please try a different one!": "Este miembro del equipo de servicio ya está ocupado. ¡Prueba con uno diferente!",
    "add team member": "Agregar miembro del equipo",
    "no service team available": "No hay equipo de servicio disponible",
    "to add team member": "Para agregar un miembro del equipo",
    "revoke access": "Revocar el acceso",
    "updated on": "Actualizado el",
    "add member": "Añadir miembro",
    "number of sites assigned": "Número de sitios asignados",
    "select user": "Seleccionar usuario",
    "please select user": "Por favor seleccione usuario",
    "zone detection resolution": "Resolución de detección de zona",
    "action": "Acción",
    "site detection resolution": "Resolución de detección del sitio",
    "I have completed the pest management and sensor services required to resolve the new detections in this site.": "He completado el manejo de plagas y el mantenimiento de sensores necesarios para resolver las nuevas detecciones en este sitio.",
    "Sensor Detection Resolution": "Resolución de detección del sensor",
    "Grant Access": "Autorizará el acceso",
    "Add team member to the site": "Agregar miembro del equipo al sitio",
    "I confirm to grant site access to this member and to all the reporting managers.": "Confirmo otorgar acceso al sitio a este miembro y a todos los administradores de reportes.",
    "has been granted access to the selected site": "se ha otorgado acceso al sitio seleccionado",
    "Remove team member from the site": "Eliminar miembro del equipo del sitio",
    "I confirm to revoke site access to this member.": "Confirmo la revocación de acceso al sitio de este miembro.",
    "has been revoked access to the selected site": "se ha revocado el acceso al sitio seleccionado",
    "detected": "Detectado",
    "house flies detected": "Moscas domésticas detectadas",
    "fruit flies detected": "Moscas de la fruta detectadas",
    "total flies detected": "Total de moscas detectadas",
    "notifications center": "Centro de notificaciones",
    "Edit Service Team Members for the site by changing users below": "Edite los miembros del equipo de mantenimiento para el sitio cambiando los usuarios a continuación",
    "Add Service Team Members for the site by choosing users below": "Agregue miembros del equipo de mantenimiento para el sitio eligiendo los usuarios a continuación",
    "please use different username": "Utilice un nombre de usuario diferente",
    "is": "es",
    "edit": "Editar",
    "replace": "Reemplazar",
    "remove": "Eliminar",
    "Zone Information": "Información de zona",
    "User": "Usuario",
    "important notifications": "Notificaciones importantes",
    "confirmation": "Confirmación",
    "Are you sure you want to mark all the new notifications as read?": "¿Está seguro de que desea marcar todas las notificaciones nuevas como leídas?",
    "Yes, Proceed": "Si, proceda",
    "The new notifications has been successfully cleared. Those notifications can be still accessed from the Notifications Center.": "Las nuevas notificaciones se han borrado correctamente. Aún se puede acceder a esas notificaciones desde el Centro de notificaciones.",
    "Site Detections Resolved by": "Detecciones de sitios resueltas por",
    "Zone Detections Resolved by": "Detecciones de zona resueltas por",
    "New Fly Detections at Site": "Nuevas detecciones de moscas en el sitio",
    "New Crawling Insect Detections at Site": "Nuevas detecciones de insectos rastreros en el sitio",
    "New Rodent Detections at Site": "Nuevas detecciones de roedores en el sitio",
    "Fly Sensors": "Sensores de mosca",
    "Rodent Sensors": "Sensores para roedores",
    "zone detections resolved": "Detecciones de zona resueltas",
    "site detections resolved": "Detecciones de sitios resueltas",
    "by": "por",
    "is Breached!": "está violado!",
    "Breached!": "¡Violado!",
    "All Notifications": "Todas las notificaciones",
    "Breach Alerts": "Alertas de violación",
    "Site Events": "Eventos del sitio",
    "Sensor Events": "Eventos del sensor",
    "Mark all as read": "Marcar todo como leido",
    "I have completed the pest management and sensor services required to resolve the new detections in this zone.": "He completado el manejo de plagas y el mantenimiento de sensores necesarios para resolver las nuevas detecciones en esta zona.",
    "total sensors": "Sensores totales",
    "IoP® Dashboard": "Panel de IoP®",
    "Fly Detections": "Detecciones de moscas",
    "Rodent Detections": "Detecciones de roedores",
    "Fly Detection": "Deteccion de moscas",
    "Rodent Detection": "Deteccion de roedores",
    "Fly Trend": "Tendencia de mosca",
    "Rodent Trend": "Tendencia de roedores",
    "New Detections by Pest Type": "Nuevas detecciones por tipo de plaga",
    "Detections - New vs Resolved": "Detecciones: nuevas vs resueltas",
    "View All New Detections": "Ver todas las detecciones nuevas",
    "Sensors grouped by Pest Type": "Sensores agrupados por tipo de plaga",
    "View All Sensors": "Ver todos los sensores",
    "Sensors grouped by Communication Status": "Sensores agrupados por estado de comunicación",
    "© 2022 Evoscien. All Rights Reserved.": "© 2022 Evoscien. Todos los derechos están reservados.",
    "There are": "Existen",
    "more new notifications,": "Más notificaciones nuevas,",
    "detections overview": "Resumen detecciones",
    "Fly Sensor Detections Resolved by": "Detecciones de sensores de moscas resueltas por",
    "Fly Sensor Detections Resolved": "Detecciones de sensor de moscas resueltas",
    "New Fly Detections": "Nuevas detecciones de moscas",
    "New Rodent Detections": "Nuevas detecciones de roedores",
    "create site": "Crear sitio",
    "to create a site.": "para crear un sitio.",
    "Add Zone Map": "Agregar mapa de zona",
    "please enter the role description": "Ingrese la descripción del rol",
    "Sensor Detections Resolved by": "Detecciones de sensores resueltas por",
    "sensor detections resolved": "Detecciones de sensor resueltas",
    "current password": "Contraseña Actual",
    "reset password": "Restablecer contraseña",
    "Additional Comments": "Comentarios adicionales",
    "Do you want to resolve the detections on this site?": "¿Quieres resolver las detecciones en este sitio?",
    "Do you want to resolve the detections on this sensor?": "¿Quieres resolver las detecciones en este sensor?",
    "Do you want to resolve the detections on this zone?": "¿Quieres resolver las detecciones en esta zona?",
    "Yes, Resolve": "Si, resolver",
    "No, Cancel": "No, cancelar",
    "pest infestation summary": "Resumen de infestación de plagas",
    "time series comparison": "Comparación de series de tiempo",
    "Time Series": "Series de tiempo",
    "Cumulative Sum": "Suma acumulada",
    "Hours Distribution": "Distribución de Horas",
    "Percentage": "Porcentaje",
    "Trend is unavailable for the current selection criteria.": "Tendencia no disponible para los criterios de selección actuales.",
    "Data Aggregation By": "Agregación de datos por",
    "Detections Report": "Reporte de detecciones",
    "- Create Customer -": "- Crear cliente -",
    "Critical Control Limit": "Límite de control crítico",
    "username must be a minimum of 6 characters": "El nombre de usuario debe tener un mínimo de 6 caracteres",
    "max length 12": "Longitud máxima 12",
    "create user": "Crear usuario",
    "to create a user": "para crear un usuario",
    "We use cookies to ensure that we give you the best experience on our website.": "Usamos cookies para asegurarnos de brindarle la mejor experiencia en nuestro sitio web.",
    "By closing this message, you consent to our use of cookies on this device in accordance with our privacy policy unless you have disabled them in your browser settings.": "Al cerrar este mensaje, usted acepta nuestro uso de cookies en este dispositivo de acuerdo con nuestra política de privacidad, a menos que las haya desactivado en la configuración de su navegador.",
    "I Agree!": "¡Estoy de acuerdo!",
    "View Privacy Policy": "Ver política de privacidad",
    "privacy policy": "Política de privacidad",
    "Brandenburg UK LTD is part of the Brandenburg Group which includes Brandenburg NA LLC and Brandenburg India Pvt Ltd. The Brandenburg Group is committed to complying with the relevant data protection legislations. In this privacy notice we will explain how our organisation uses the personal data we collect from you when you use our website.": "Brandenburg UK LTD es parte del Brandenburg Group que incluye Brandenburg NA LLC y Brandenburg India Pvt Ltd. El Brandenburg Group se compromete a cumplir con las legislaciones de protección de datos relevantes. En este aviso de privacidad, explicaremos cómo nuestra organización utiliza los datos personales que recopilamos de usted cuando utiliza nuestro sitio web.",
    "Brandenburg Group is the data controller for this website.": "Brandenburg Group es el controlador de datos de este sitio web.",
    "What data we collect and how we collect it": "Qué datos recopilamos y cómo los recopilamos",
    "Brandenburg Group collects the following data personally identifiable information directly from yourself;": "Brandenburg Group recopila la siguiente información de identificación personal directamente de usted;",
    "Name": "Nombre",
    "Email address": "Dirección de correo electrónico",
    "This information is provided by yourself when completing our contact form.": "Esta información la proporciona usted mismo al completar nuestro formulario de contacto.",
    "We also use a number of third party resources on the website that collect information about your usage on the site through the use of cookies.": "También utilizamos una serie de recursos de terceros en el sitio web que recopilan información sobre su uso en el sitio mediante el uso de cookies.",
    "How will we use your data?": "¿Cómo usaremos sus datos?",
    "Brandenburg Group collects personal data through the website so that we can communicate with you regarding our products and services. This may include relevant marketing materials.": "Brandenburg Group recopila datos personales a través del sitio web para que podamos comunicarnos con usted con respecto a nuestros productos y servicios. Esto puede incluir materiales de marketing relevantes.",
    "In submitting this information through our form, you give us consent to use your data as described above. We hold the data under legitimate interest such that we can contact you about legitimate opportunities.": "Al enviar esta información a través de nuestro formulario, nos da su consentimiento para utilizar sus datos como se describe anteriormente. Conservamos los datos por motivos de interés legítimo de modo que podamos comunicarnos con usted acerca de oportunidades legítimas.",
    "Data sharing": "Compartir datos",
    "We work with a number of third parties to deliver our services and share your data with these parties when necessary to fulfil our service to you.": "Trabajamos con una serie de terceros para brindar nuestros servicios y compartir sus datos con estas partes cuando sea necesario para brindarle nuestro servicio.",
    "Brandenburg will never sell your data and the third parties that we work with do not have implied consent to send you marketing materials, only to process your information for the purpose of fulfilling a service.": "Brandenburg nunca venderá sus datos y los terceros con los que trabajamos no tienen consentimiento implícito para enviarle materiales de marketing, solo para procesar su información con el fin de cumplir con un servicio.",
    "How do we protect your data?": "¿Cómo protegemos sus datos?",
    "We utilise industry standard encryption on our website to protect your data. We also implement other relevant technical and organisational measures to protect data.": "Utilizamos cifrado estándar de la industria en nuestro sitio web para proteger sus datos. También implementamos otras medidas técnicas y organizativas relevantes para proteger los datos.",
    "What are your data protection rights?": "¿Cuáles son sus derechos de protección de datos?",
    "Brandenburg Group would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:": "Brandenburg Group desea asegurarse de que conoce todos sus derechos de protección de datos. Todo usuario tiene derecho a lo siguiente:",
    "The right to access": "El derecho de acceso",
    "You have the right to request Brandenburg Group for copies of your personal data. We may charge you a small fee for this service.": "Tiene derecho a solicitar a Brandenburg Group copias de sus datos personales. Es posible que le cobremos una pequeña tarifa por este servicio.",
    "The right to rectification": "El derecho de rectificación",
    "You have the right to request that Brandenburg Group correct any information you believe is inaccurate. This also forms part of your responsibility to ensure that our records for you are kept up to date and relevant.": "Tiene derecho a solicitar que Brandenburg Group corrija cualquier información que crea que es inexacta. Esto también forma parte de su responsabilidad de garantizar que nuestros registros para usted se mantengan actualizados y sean relevantes.",
    "The right to erasure": "El derecho a borrar",
    "You have the right to request that Brandenburg Group erase your personal data, under certain conditions.": "Tiene derecho a solicitar que Brandenburg Group borre sus datos personales, bajo ciertas condiciones.",
    "The right to restrict processing": "El derecho a restringir el procesamiento",
    "You have the right to request that Brandenburg Group restrict the processing of your personal data, under certain conditions.": "Tiene derecho a solicitar que Brandenburg Group restrinja el procesamiento de sus datos personales, bajo ciertas condiciones.",
    "The right to object to processing": "El derecho a oponerse al procesamiento",
    "You have the right to object to Brandenburg Group’s processing of your personal data, under certain conditions.": "Tiene derecho a oponerse al procesamiento de sus datos personales por parte de Brandenburg Group, bajo ciertas condiciones.",
    "The right to data portability": "El derecho a la portabilidad de datos",
    "You have the right to request that Brandenburg Group transfer the data that we have collected to another organization, or directly to you, under certain conditions.": "Tiene derecho a solicitar que Brandenburg Group transfiera los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones.",
    "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:": "Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos, comuníquese con nosotros a nuestro correo electrónico:",
    "Alternatively you can write to us. See the How to contact us section lower in this notice for additional contact details.": "Alternativamente, puede escribirnos. Consulte la sección Cómo comunicarse con nosotros más abajo en este aviso para obtener detalles de contacto adicionales.",
    "Cookies": "Galletas",
    "Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.": "Las cookies son archivos de texto que se colocan en su computadora para recopilar información estándar de registro de Internet e información sobre el comportamiento de los visitantes. Cuando visita nuestros sitios web, podemos recopilar información suya automáticamente a través de cookies o tecnología similar.",
    "For further information, visit": "Para más información visite",
    "How do we use cookies?": "Cómo usamos las cookies?",
    "Brandenburg Group uses cookies in a range of ways to improve your experience on our website, including:": "Brandenburg Group utiliza cookies de diversas formas para mejorar su experiencia en nuestro sitio web, que incluyen:",
    "Understanding how you use our website": "Entender cómo usa nuestro sitio web",
    "Providing features that are operated by a third party. For example, the hosting of video content.": "Proporcionar funciones operadas por un tercero. Por ejemplo, el alojamiento de contenido de video.",
    "Remembering your preferences for cookies.": "Recordando sus preferencias de cookies.",
    "What types of cookies do we use?": "¿Qué tipos de cookies utilizamos?",
    "There are a number of different types of cookies, however, our website uses:": "Hay varios tipos diferentes de cookies, sin embargo, nuestro sitio web utiliza:",
    "Functionality": "Funcionalidad",
    "Brandenburg Group uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.": "Brandenburg Group utiliza estas cookies para que lo reconozcamos en nuestro sitio web y recordemos sus preferencias previamente seleccionadas. Estos podrían incluir el idioma que prefiere y la ubicación en la que se encuentra. Se utiliza una combinación de cookies de origen y de terceros.",
    "Analytics": "Analítica",
    "Brandenburg Group use analytics tools to identify areas for improvement in the service that we provide. These cookies provide information such as landing/exit pages and the pages you visited whilst on the website so that we can identify any required improvements to content.": "Brandenburg Group utiliza herramientas de análisis para identificar áreas de mejora en el servicio que brindamos. Estas cookies proporcionan información como las páginas de inicio / salida y las páginas que visitó mientras estaba en el sitio web para que podamos identificar las mejoras necesarias en el contenido.",
    "Advertising": "Publicidad",
    "Brandenburg Group uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Brandenburg Group sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.": "Brandenburg Group utiliza estas cookies para recopilar información sobre su visita a nuestro sitio web, el contenido que vio, los enlaces que siguió e información sobre su navegador, dispositivo y su dirección IP. Brandenburg Group a veces comparte algunos aspectos limitados de estos datos con terceros con fines publicitarios. También podemos compartir datos en línea recopilados a través de cookies con nuestros socios publicitarios. Esto significa que cuando visita otro sitio web, es posible que se le muestre publicidad basada en sus patrones de navegación en nuestro sitio web.",
    "How to manage cookies": "Cómo administrar las cookies",
    "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.": "Puede configurar su navegador para que no acepte cookies, y el sitio web anterior le indica cómo eliminar las cookies de su navegador. Sin embargo, en algunos casos, es posible que algunas de las funciones de nuestro sitio web no funcionen.",
    "Google Analytics opt-out": "Exclusión de Google Analytics",
    "If you wish to opt out of Google Analytics cookies completely, you can do so by following the instructions here:": "Si desea excluirse completamente de las cookies de Google Analytics, puede hacerlo siguiendo las instrucciones aquí:",
    "Other websites": "Otros sitios web",
    "The Brandenburg Group website contains links to other websites. We are not responsible for the privacy practices of these external sites therefore our privacy notice applies only to our website. If you click on a link to another website, you should read their privacy notice.": "El sitio web del Grupo Brandenburgo contiene enlaces a otros sitios web. No somos responsables de las prácticas de privacidad de estos sitios externos, por lo tanto, nuestro aviso de privacidad se aplica solo a nuestro sitio web. Si hace clic en un enlace a otro sitio web, debe leer su aviso de privacidad.",
    "Underage data collection": "Recopilación de datos de menores de edad",
    "Brandenburg does not knowingly collect personally identifiable information from anyone under the age of 16. Any such data that is collected is removed.": "Brandenburg no recopila a sabiendas información de identificación personal de ninguna persona menor de 16 años. Se elimina cualquier dato que se recopile.",
    "Changes to our privacy notice": "Cambios en nuestro aviso de privacidad",
    "Brandenburg Group keeps its privacy notice under regular review and places any updates on this web page. This privacy notice was last updated on August 2nd 2021.": "Brandenburg Group mantiene su aviso de privacidad bajo revisión periódica y coloca cualquier actualización en esta página web. Este aviso de privacidad se actualizó por última vez el 2 de agosto de 2021.",
    "How to contact us": "Como contactar con nosotros",
    "If you have any questions about Brandenburg Group’s privacy notice, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.": "Si tiene alguna pregunta sobre el aviso de privacidad de Brandenburg Group, los datos que tenemos sobre usted o si desea ejercer uno de sus derechos de protección de datos, no dude en ponerse en contacto con nosotros.",
    "Email us at:": "Envíanos un email a:",
    "Or write to us at:": "O escríbanos a:",
    "FAO: Data Protection": "FAO: Protección de datos",
    "Brandenburg UK Limited": "Brandenburg UK Limited",
    "29 Navigation Drive,": "29 Unidad de navegación,",
    "Hurst Business Park,": "Parque empresarial Hurst,",
    "Brierley Hill, West Midlands,": "Brierley Hill, West Midlands,",
    "DY5 1UT": "DY5 1UT",
    "How to contact the appropriate authority": "Cómo contactar a la autoridad apropiada",
    "Should you wish to report a complaint or if you feel that Brandenburg Group has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office. However we would request that you contact us directly first with the information provided above so that we can attempt to resolve your complaint first.": "Si desea denunciar una queja o si cree que Brandenburg Group no ha abordado su inquietud de manera satisfactoria, puede comunicarse con la Oficina del Comisionado de Información. Sin embargo, le solicitamos que se comunique con nosotros directamente primero con la información proporcionada anteriormente para que podamos intentar resolver su queja primero.",
    "UK": "Reino Unido",
    "Information Commissioner's Office": "Oficina del Comisionado de Información",
    "Wycliffe House": "Wycliffe House",
    "Water Lane": "Water Lane",
    "Wilmslow": "Wilmslow",
    "Cheshire": "Cheshire",
    "SK9 5AF": "SK9 5AF",
    "Phone: 0303 123 1113": "Teléfono: 0303123 1113",
    "Web address:": "Dirección web:",
    "pest detections report": "Reporte de detección de plagas",
    "all crawling insect": "Todo insecto rastrero",
    "all rodents": "Todos los roedores",
    "all crawling insect sensors": "todos los sensores de insectos rastreros",
    "all rodent sensors": "Todos los sensores para roedores",
    "all fly sensors": "Todos los sensores de mosca",
    "Crawling Insect Critical Control Limit": "Límite crítico de control de insectos rastreros",
    "Fly Critical Control Limit": "Límite de control crítico de moscas",
    "Rodent Critical Control Limit": "Límite de control crítico de roedores",
    "max length 24": "Longitud máxima 24",
    "min length 6": "Longitud mínima 6",
    "year I": "Año I",
    "year II": "Año II",
    "week I": "Semana I",
    "week II": "Semana II",
    "month I": "Mes I",
    "month II": "Mes II",
    "day I": "Día I",
    "day II": "Día II",
    "comparison type": "Tipo de comparación",
    "Max Custom Date Range selection is 90 days at a stretch": "La selección máxima del intervalo de fechas personalizado es de 90 días seguidos",
    "Crawling Insect Cumulative Sum": "Suma acumulativa de insectos rastreros",
    "Rodent Cumulative Sum": "Suma acumulada de roedores",
    "Fly Cumulative Sum": "Suma acumulada de moscas",
    "glue board expiry": "Caducidad del tablero de pegamento",
    "Wi-Fi": "Wi-Fi",
    "max allowed on glueboard": "máximo permitido en el tablero de pegamento",
    "please select upto current month": "Por favor seleccione hasta el mes actual",
    "events log": "Registro de eventos",
    "event": "Evento",
    "month of the year": "Mes del año",
    "day of the month": "Día del mes",
    "day of the week": "Día de la semana",
    "Sensor Management": "Gestión de sensores",
    "LPWAN": "LPWAN",
    "all detections": "Todas las detecciones",
    "Glue Trap": "Trampa de pegamento",
    "Standalone": "Independiente",
    "Bait Station": "Estación de cebo",
    "max": "Max",
    "years": "años",
    "hr": "hora",
    "hrs": "horas",
    "min": "min",
    "mins": "mins",
    "sec": "segundo",
    "secs": "segundos",
    "ago": "atrás",
    "days left": "Días restantes",
    "day left": "días que quedan",
    "Expired": "Caducado",
    "days ago": "hace días",
    "day ago": "hace un dia",
    "Entry from Left Side": "Entrada desde el lado izquierdo",
    "Entry from Right side": "Entrada por el lado derecho",
    "Values denote the side of entry of rodent on the sensor": "Los valores indican el lado de entrada del roedor en el sensor",
    "Values denote the side of entry of crawling insect on the sensor": "Los valores indican el lado de entrada del insecto rastrero en el sensor",
    "Due to variations in size, the side of entry for": "Debido a las variaciones de tamaño, el lado de entrada para",
    "some rodent detections remains unidentified.": "Algunas detecciones de roedores permanecen sin identificar.",
    "events": "Eventos",
    "added": "Agregado",
    "replaced": "Sustituido",
    "removed": "remoto",
    "sensor idle": "Sensor inactivo",
    "sensor active": "Sensor activo",
    "sensor offline": "Sensor fuera de línea",
    "zone critical control limit breached": "Límite de control crítico de zona superado",
    "fly max detections breached": "Detecciones máximas de vuelo violadas",
    "fly glueboard expiry breached": "Volar la caducidad de la placa adhesiva violada",
    "sensor established connection": "Conexión establecida del sensor",
    "wifi configuration successful": "Configuración WiFi exitosa",
    "fly glueboard replaced": "Tablero de pegamento para moscas reemplazado",
    "sensor name edited": "Nombre del sensor editado",
    "sensor pictures edited": "Imágenes del sensor editadas",
    "location description edited": "Ubicación Descripción Editado",
    "Detections between": "Detecciones entre",
    "in": "en",
    "Hrs": "horas",
    "on": "en",
    "during": "durante",
    "between": "Entre",
    "hrs on": "horas en",
    "detection": "detección",
    "Day vs Day": "Día contra Día",
    "Week vs Week": "Semana contra Semana",
    "Month vs Month": "Mes contra Mes",
    "Year vs Year": "Año vs Año",
    "Educational Institution": "Institución educativa",
    "Food Processing": "Procesamiento de alimentos",
    "Home": "Casa",
    "Hospital": "Hospital",
    "Hotel": "Hotel",
    "Loading Bay Warehouse": "Almacén de la bahía de carga",
    "Office": "Oficina",
    "Restaurant": "Restaurante",
    "Retail": "Venta minorista",
    "Theatre": "Teatro",
    "Airport": "Aeropuerto",
    "Bar": "Bar",
    "Cafe": "Cafetería",
    "Cruise line": "Línea de cruceros",
    "Supermarket": "Supermercado",
    "Train Station": "Estación de tren",
    "glueboard expiry": "Caducidad de la placa adhesiva",
    "glueboard max detections": "Detecciones máximas de tablero adhesivo",
    "zone ccl breached": "Zona ccl infringida",
    "established connection": "Conexión establecida",
    "established connection wifi": "Conexión WiFi establecida",
    "wifi config successful": "Configuración WiFi exitosa",
    "glueboard changed": "Tablero adhesivo cambiado",
    "detections resolved": "Detecciones resueltas",
    "Web App": "Aplicación Web",
    "Mobile App": "Aplicación Movil",
    "sensor add": "Sensor Agregar",
    "sensor edit": "Sensor Editar",
    "sensor remove": "Quitar sensor",
    "sensor replace": "Reemplazo de sensor",
    "Site deleted successfully.": "Sitio eliminado con éxito.",
    "Zone deleted successfully.": "Zona eliminada con éxito.",
    "Super Admin": "Superadministrador",
    "Regional Manager": "Administrador regional",
    "Branch Manager": "Gerente de Sucursal",
    "Customer Service Executive": "Ejecutiva de servicio al cliente",
    "Service Manager": "Supervisor",
    "Service Technician": "Técnica de servicio",
    "Customer Representative": "Representante del cliente",
    "Super Admin is the root level access of the organisation.": "Super Admin es el acceso de nivel raíz de la organización.",
    "Regional Manager is the managerial role with scope of multiple branches. Multiple Branch Managers can report under Regional Manager.": "Gerente Regional es el rol gerencial con alcance de múltiples sucursales. Múltiples Gerentes de Sucursales pueden reportar bajo el Gerente Regional.",
    "Branch Manager is the managerial role for one branch. Branch manager is empowered with all functions within scope of a branch.": "Branch Manager es el rol gerencial para una sucursal. El gerente de sucursal está facultado con todas las funciones dentro del alcance de una sucursal.",
    "Customer Service Executive is the custodian of achieving customer satisfaction within a branch. One or more Customer Service Executives can manage the customers in a branch as required.": "El Ejecutivo de Servicio al Cliente es el custodio de lograr la satisfacción del cliente dentro de una sucursal. Uno o más Ejecutivos de Servicio al Cliente pueden administrar los clientes en una sucursal según sea necesario.",
    "In large branches, Service Managers manage the routing and productivity of the Service Technicians on the field. One or more Service Managers, manage technicians under them for best utilisation of their time.": "En sucursales grandes, los gerentes de servicio administran el enrutamiento y la productividad de los técnicos de servicio en el campo. Uno o más Gerentes de Servicio, administran a los técnicos debajo de ellos para la mejor utilización de su tiempo.",
    "Service Technicians (a.k.a PMP, Pest Management Personnel) are the field agents who visit the customers site to deliver the Sensor Installation, Pest Control, Sensor Malfunction and Periodic maintenance services.": "Los técnicos de servicio (también conocidos como PMP, Pest Management Personnel) son los agentes de campo que visitan el sitio del cliente para brindar los servicios de instalación de sensores, control de plagas, mal funcionamiento del sensor y mantenimiento periódico.",
    "Customer Representatives are the site/facility managers of the customer for which the services are provided. A Customer Representative can be mapped to only one customer": "Los representantes del cliente son los administradores del sitio/instalación del cliente para el cual se brindan los servicios. Un representante del cliente se puede asignar a un solo cliente",
    "site events log": "Registro de eventos del sitio",
    "Site Management": "Manejo de sitio",
    "Zone Management": "Gestión de zonas",
    "This sensor ID does not exist! Kindly recheck and try again.": "¡Este ID de sensor no existe! Por favor, vuelva a comprobar e inténtelo de nuevo.",
    "Invalid Sensor! This sensor does not belong to your organisation.": "¡Sensor no válido! Este sensor no pertenece a su organización.",
    "Sensor is validated successfully!": "¡El sensor se validó con éxito!",
    "Invalid sensor! This sensor is already added elsewhere.": "¡Sensor no válido! Este sensor ya está agregado en otro lugar.",
    "Invalid sensor! This sensor has been removed as malfunctioning and can no longer be added.": "¡Sensor no válido! Este sensor se eliminó debido a un mal funcionamiento y ya no se puede agregar.",
    "Invalid sensor! This sensor has been replaced as malfunctioning / damaged / lost and can no longer be added.": "¡Sensor no válido! Este sensor se reemplazó porque funciona mal/se dañó/se perdió y ya no se puede agregar.",
    "Invalid sensor! This sensor was added to site": "¡Sensor no válido! Este sensor fue agregado al sitio",
    " zone": " zona",
    "and is currently in use.": "y actualmente está en uso.",
    "site created": "Sitio creado",
    "site edited": "Sitio editado",
    "service team edited": "Equipo de servicio Editado",
    "zone added": "Zona añadida",
    "zone name edited": "Nombre de zona editado",
    "zone deleted": "Zona eliminada",
    "zone ccl changed rodent": "Zona CCL Cambiado Roedor",
    "zone ccl changed fly": "Vuelo modificado de zona CCL",
    "Zone Rodent critical control limit changed from": "El límite crítico de control de roedores de la zona cambió de",
    "Zone Crawling Insect Critical Control Limit changed from": "El límite crítico de control de insectos que se arrastran en la zona cambió de",
    "Zone Fly critical control limit changed from": "El límite de control crítico de Zone Fly cambió de",
    " to ": " a ",
    "sensor added": "Sensor agregada",
    "delete site": "Eliminar sitio",
    "delete zone": "Eliminar Zona",
    "zone fly critical control limit breached": "Límite de control crítico de vuelo de zona incumplido",
    "grouped crawling insect detections by site": "Detecciones de cucarachas agrupadas por sitio",
    "grouped rodent detections by site": "Detecciones de insectos rastreros agrupados por sitio",
    "grouped fly detections by site": "Detecciones de moscas agrupadas por sitio",
    "type": "Tipo, Editado",
    "Site Contact Person Name": "Nombre de la persona de contacto del sitio, editado",
    "None Selected": "Ninguna seleccionada",
    "All Selected": "Todo seleccionado",
    "Multiple Selected": "Seleccionado múltiple",
    "glueboard max fly detections": "Detecciones máximas de moscas en tableros adhesivos",
    "edited": "editada",
    "sensor photo": "Imágenes del sensor",
    "add sensor pictures": "Agregar imágenes de sensores",
    "sensor picture(s)": "Imagen(es) del sensor",
    "sensor picture": "Imagen del sensor",
    "fly glueboard max detections": "Tablero Fly Glue máximas Detecciones",
    "fly glueboard expiry": "Volar caducidad de la placa adhesiva",
    "Sensor WiFi Configuration Successful": "Configuración WiFi del sensor exitosa",
    "zone crawling insect breached": "Insecto rastrero de zona violado",
    "zone fly breached": "Zona Fly violada",
    "zone rodent breached": "Zona roedor violada",
    "fly glueboard max detections breached": "Volar detecciones máximas de tableros adhesivos infringidas",
    "sensor edited": "Sensor editado",
    "sensor removed": "Sensor eliminado",
    "sensor replaced": "Sensor reemplazado",
    "edit service team": "Editar equipo de servicio",
    "sensor established connection via wifi": "Conexión establecida del sensor a través de WiFi",
    "sensor wifi configuration successful": "Configuración WiFi del sensor exitosa",
    "zone rodent critical control limit edited": "Límite de control crítico de roedores de zona editado",
    "zone fly critical control limit edited": "Límite de control crítico de vuelo de zona editado",
    "zone crawling insect critical control limit edited": "Límite crítico de control de insectos rastreros de zona editado",
    "zone crawling insect critical control limit breached": "Se incumplió el límite crítico de control de insectos que se arrastran en la zona",
    "fly glueboard expiry changed": "Caducidad de la tabla de pegamento de mosca cambiada",
    "fly glueboard max detections changed": "Se ha cambiado la detección máxima de la placa de pegamento para moscas",
    "Detections Resolution": "Resolución de detecciones",
    "Fly Glueboard Expiry Changed from": "Caducidad de Tablero con pegamento para moscas modificada de",
    "days to": "dias para",
    "Days": "días",
    "Fly Glueboard Max Detections Changed from": "Tablero con pegamento para moscas Máximo de detecciones Cambiado de",
    "site information edited": "Información del sitio editada",
    "contact person details edited": "detalles de la persona de contacto editados",
    "zone edited": "Zona editada",
    "Changed from": "Cambiaron desde",
    "contact phone number": "Teléfono de contacto",
    "contact email id": "ID de correo electrónico de contacto",
    "zone map edited": "Mapa de zona editado",
    "Zone Name, Map Edited": "Nombre de zona, mapa editado",
    "New Password and Confirm New Password does not match": "Nueva contraseña y Confirmar nueva contraseña no coinciden",
    "You have entered invalid old password.": "Ha ingresado una contraseña anterior no válida.",
    "profile": "Perfil",
    "preferences": "Preferencias",
    "New Password shoudn't be same as old password.": "La nueva contraseña no debe ser la misma que la anterior.",
    "Fly Glueboard": "Tablero de pegamento de moscas",
    "user roles": "Roles del usuario",
    "The side of the entry for": "El lado de la entrada para",
    "rodent detections is unknown due to variations in the size of the rodent.": "Se desconoce la detección de roedores debido a las variaciones en el tamaño del roedor.",
    "Critically low, requires replacement!": "Críticamente baja, requiere reemplazo!",
    "total sites": "Sitios totales",
    "100% fully charged": "100% completamente cargada",
    "remaining": "Restante",
    "administration": "Administración",
    "created by (username)": "Creado por (nombre de usuario)",
    "reporting manager (username)": "Gerente de informes (nombre de usuario)",
    "Cigarette Beetle Glueboard": "Tablero adhesivo de escarabajo de cigarrillos",
    "Default max cigarette beetle detections allowed on the glueboard": "Detecciones máximas predeterminadas de escarabajos de cigarrillos permitidas en la placa adhesiva",
    "please enter max cigarette beetle detections allowed on the glueboard": "Ingrese las detecciones máximas de escarabajos de cigarrillos permitidas en la placa adhesiva",
    "Default max cigarette beetle detections allowed on the glueboard between 1 to 999999": "Detecciones predeterminadas de Max Cigarette Beetle permitidas en la placa adhesiva entre 1 y 999999",
    "Default Cigarette Beetle Critical Control Limit of the Zone": "Límite predeterminado de control crítico de escarabajos de cigarrillos de la zona",
    "Please Enter Cigarette Beetle Critical Control Limit": "Ingrese el límite de control crítico del escarabajo del cigarrillo",
    "Default cigarette beetle detections limit between 1 to 999999": "Límite predeterminado de detecciones de Cigarette Beetle entre 1 y 999999",
    "cigarette beetle": "Escarabajo del cigarrillo",
    "max cigarette beetle detections allowed on the glueboard between 1 to 999999": "Max Cigarette Beetle detecciones permitidas en la placa adhesiva entre 1 y 999999",
    "max cigarette beetle detections allowed on the glueboard": "Max Cigarette Beetle detecciones permitidas en la placa adhesiva",
    "cigarette beetle sensor": "Sensor de escarabajo de cigarrillos",
    "Cigarette Beetle Detection": "Detección de escarabajos de cigarrillos",
    "cigarette beetles": "Escarabajos de cigarrillos",
    "Cigarette Beetle Detections": "Detecciones de escarabajos de cigarrillos",
    "Cigarette Beetle Trend": "Tendencia del escarabajo del cigarrillo",
    "Cigarette Beetle Critical Control Limit": "Límite crítico de control del escarabajo del cigarrillo",
    "Cigarette Beetle Glueboard Expiry": "Caducidad de la placa adhesiva del escarabajo del cigarrillo",
    "Cigarette Beetle Glueboard Detections": "Escarabajo del cigarrillo Detección de placas adhesivas",
    "Zone Cigarette Beetle Critical Control Limit": "Zona Cigarrillo escarabajo Límite crítico de control",
    "missing mandatory zoneName field": "Falta el campo Nombre de la zona obligatoria",
    "Cigarette Beetle Glueboard Expiry Changed from": "Caducidad de la placa adhesiva del escarabajo del cigarrillo Cambiado de",
    "Cigarette Beetle Glueboard Max Detections Changed from": "Cigarrillo escarabajo Detecciones máximas de placa adhesiva Cambiado de",
    "zone cigarette beetle critical control limit changed from": "Límite de control crítico de escarabajo de cigarrillos de zona cambiado de",
    "cigarette beetle sensors": "Sensores de escarabajo de cigarrillos",
    "cigarette beetle glueboard max detections breached": "Tablero adhesivo de escarabajo de cigarrillo Detecciones máximas incumplidas",
    "cigarette beetle glueboard expiry breached": "Caducidad de la placa adhesiva del escarabajo del cigarrillo violada",
    "cigarette beetle glueboard expiry changed": "Cambio de caducidad de la placa adhesiva del escarabajo del cigarrillo",
    "cigarette beetle glueboard max detections changed": "Las detecciones máximas de la placa adhesiva del escarabajo del cigarrillo cambiaron",
    "cigarette beetle glueboard replaced": "Se reemplazó la placa adhesiva del escarabajo del cigarrillo",
    "all cigarette beetle sensors": "Todos los sensores de escarabajo de cigarrillos",
    "Cigarette Beetle Cumulative Sum": "Suma acumulativa de escarabajo de cigarrillos",
    "detection count": "Recuento de detección",
    "New Cigarette Beetle Detections at Site": "Nuevas detecciones de escarabajos de cigarrillos en el sitio",
    "New Cigarette Beetle Detections": "Nuevas detecciones de escarabajos de cigarrillos",
    "zone cigarette beetle critical control limit edited": "Límite de control crítico del escarabajo del cigarrillo de la zona editado",
    "zone cigarette beetle critical control limit breached": "Límite de control crítico del escarabajo del cigarrillo de la zona incumplido",
    "grouped cigarette beetle detections by site": "Detecciones de escarabajos de cigarrillos agrupados por sitio",
    "max. detections limit on the glueboard": "Máx. límite de detecciones en la placa adhesiva",
    "please enter max. detections limit on the glueboard": "Por favor ingrese máx. límite de detecciones en la placa adhesiva",
    "max. detections limit allowed on the glueboard between 1 to 999999": "Máx. límite de detecciones permitidas en la placa adhesiva entre 1 y 999999",
    "enter a value between 1 to 999999": "Introduzca un valor entre 1 y 999999",
    "New Crawling Insect Detections": "Nuevas detecciones de insectos rastreros",
    "crawling insect sensors": "Sensores de insectos rastreros",
    "Crawling Insect Detections": "Detecciones de insectos rastreros",
    "Crawling Insect Detection": "Detección de insectos rastreros",
    "Crawling Insect Trend": "Tendencia de insectos rastreros",
    "Crawling Insect Activity": "Actividad de insectos rastreros",
    "crawling insect sensor": "Sensor de insectos rastreros",
    "Pest activity trend is not computed for the replaced sensor(s).": "La tendencia de actividad de plagas no se calcula para los sensores reemplazados.",
    "Pest activity trend is displayed for all the sites available.": "La tendencia de actividad de plagas se muestra para todos los sitios disponibles.",
    "Pest activity trend is displayed for all the sites available for the customers.": "La tendencia de actividad de plagas se muestra para todos los sitios disponibles para los clientes.",
    "Search by Sensor ID": "Buscar por ID de sensor",
    "Search by Sensor Name": "Buscar por nombre de sensor",
    "not found floor map, Please upload the floor map in the edit zone screen.": "Mapa de piso no encontrado, cargue el mapa de piso en la pantalla de edición de zona.",
    "No zone map found! Please upload the zone map to map the sensors on the map.": "¡No se encontró ningún mapa de zona! Cargue el mapa de la zona para mapear los sensores en el mapa.",
    "max length 50": "Longitud máxima 50",
    "max length 100": "Longitud máxima 100",
    "max length 30": "Longitud máxima 30",
    "max length 16": "Longitud máxima 16",
    "Default No. of days for Fly Glue Board Expiry": "Número predeterminado de días para el vencimiento de la placa de pegamento para moscas",
    "Default max. Fly detections allowed on the glue board": "Máx. por defecto Detecciones de moscas permitidas en la placa adhesiva",
    "Fly Glue Board Expiring Notification (days)": "Notificación de vencimiento del tablero de pegamento para moscas (días)",
    "Default No. of days for Cigarette Beetle Glue Board Expiry": "Número predeterminado de días para la caducidad de la placa adhesiva del escarabajo del cigarrillo",
    "Default max. Cigarette Beetle detections allowed on the glue board": "Máx. por defecto Detecciones de Cigarette Beetle permitidas en la placa adhesiva",
    "Cigarette Beetle Glue Board Expiring Notification (days)": "Tablón de pegamento del escarabajo del cigarrillo Notificación de vencimiento (días)",
    "The number of days before a glue board expires and a breach is triggered.": "El número de días antes de que caduque una placa adhesiva y se desencadene una infracción.",
    "The maximum detections allowed on a glue board before a breach is triggered for the sensor.": "Las detecciones máximas permitidas en una placa adhesiva antes de que se active una brecha para el sensor.",
    "This setting defines the early notification generation - 'X' number of days before the expiry of the glue board.": "TEsta configuración define la generación de notificaciones tempranas: 'X' número de días antes de la caducidad de la placa adhesiva.",
    "The maximum rodent detection limit for a zone before a breach is triggered.": "El límite máximo de detección de roedores para una zona antes de que se active una infracción.",
    "The maximum crawling insect detection limit for a zone before a breach is triggered.": "El límite máximo de detección de insectos rastreros para una zona antes de que se active una infracción.",
    "The maximum fly detection limit for a zone before a breach is triggered.": "El límite máximo de detección de moscas para una zona antes de que se active una infracción.",
    "The maximum cigarette beetle detection limit for a zone before a breach is triggered.": "El límite máximo de detección de escarabajos de cigarrillos para una zona antes de que se active una infracción.",
    "value": "Valor",
    "edit setting value": "Editar valor de configuración",
    "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?": "Eliminar el mapa de zona restablecerá el mapeo de cualquier sensor existente en el mapa de zona. ¿Está seguro?",
    "mapped sensors": "Sensores mapeados",
    "unmapped sensors": "sensores no mapeados",
    "save": "Ahorrar",
    "Click & drag each sensor onto the zone floor plan to assign it to the desired location.": "Haga clic y arrastre cada sensor al plano de planta de la zona para asignarlo a la ubicación deseada.",
    "Error occupied in Sensor Mapping. Do it again!": "Error ocupado en mapeo de sensores. ¡Hazlo otra vez!",
    "updated successfully!": "¡Actualizado con éxito!",
    "view zone map": "Ver mapa de zonas",
    "upload zone map": "Upload Zone Map",
    "please enter No. of days for the glueboard expiry notification": "Introduzca el número de días para la notificación de caducidad de la placa adhesiva",
    "Default No. of days for the glueboard expiry notification between 1 to 999": "Número predeterminado de días para la notificación de caducidad de la placa adhesiva entre 1 y 999",
    "please enter No. of days for the cigarette beetle glueboard expiry notification": "Ingrese el número de días para la notificación de caducidad de la placa adhesiva del escarabajo del cigarrillo",
    "Default No. of days for the cigarette beetle glueboard expiry notification between 1 to 999": "Número predeterminado de días para la notificación de caducidad de la placa adhesiva del escarabajo del cigarrillo entre 1 y 999",
    "last seen / alerts": "Visto por última vez / Alertas",
    "security": "Seguridad",
    "This setting specifies the number of days for a password to get expired for a user after the user logs into the application for the first time or when it is last changed.": "Esta configuración especifica el número de días para que una contraseña caduque para un usuario después de que el usuario inicie sesión en la aplicación por primera vez o cuando se modifique por última vez.",
    "This setting determines if the user password should expire or not.": "Esta configuración determina si la contraseña de usuario debe caducar o no.",
    "Password expiry (days)": "Caducidad de la contraseña (días)",
    "Enable password expiry": "Habilitar la caducidad de la contraseña",
    "please enter password expiry (days)": "Ingrese el vencimiento de la contraseña (días)",
    "password expiry (days) Min value: 90  to Max: 730": "Caducidad de la contraseña (días) Valor mínimo: 90 a máximo: 730",
    "please change your password to continue.": "Por favor cambie su contraseña para continuar.",
    "already added to the service team": "Ya agregado al equipo de servicio",
    "undo revoke": "Deshacer Revocar",
    "This setting defines the early notification generation - \"X\" number of days before the expiry of the glue board.": "Esta configuración define la generación de notificación anticipada: \"X\" número de días antes de la caducidad de la placa adhesiva.",
    "enabled": "Activada",
    "disabled": "Desactivada",
    "Enable / Disable password expiry": "Habilitar/deshabilitar la caducidad de la contraseña",
    "Please your enter username": "Por favor ingrese su nombre de usuario",
    "When enabled, this setting specifies the number of days for passwords to get expired since it was created or changed. When the password expiry is enabled (if disabled before), it is reset on the next successful login for all users.": "Cuando está habilitada, esta configuración especifica la cantidad de días para que caduquen las contraseñas desde que se crearon o cambiaron. Cuando se habilita la caducidad de la contraseña (si se deshabilitó antes), se restablece en el siguiente inicio de sesión exitoso para todos los usuarios.",
    "No unmapped sensors exist!": "¡No existen sensores no mapeados!",
    "Your password has expired! Please change your password to continue.": "¡Tu contraseña ha expirado! Por favor cambie su contraseña para continuar.",
    "cigarette beetle glueboard expiring": "Tablero de pegamento de escarabajo de cigarrillos",
    "fly glueboard expiring": "Tablero de pegamento para moscas",
    "Fly Glue Board expiring in": "Fly Glue Board caduca en",
    "Cigarette Beetle Glue Board expiring in": "La placa adhesiva del escarabajo del cigarrillo caduca en",
    "Customer cannot be deleted, as it is associated with one or more sites.": "El cliente no se puede eliminar, ya que está asociado con uno o más sitios. ",
    "zone rodent critical control limit breached": "​Límite crítico de control de roedores de zona superado",
    "update site": "Actualizar sitio",
    "do you want to remove": "¿Quieres eliminar",
    "Rodent": "Roedor",
    "Fly": "Volar",
    "Cigarette Beetle": "escarabajo del cigarrillo",
    "no records found" : "No se encontraron registros",
    "Your password has been changed successfully! Please login with your new password.": "¡Tu contraseña ha sido cambiada exitosamente! Inicie sesión con su nueva contraseña.",
    "Invalid username or password. Please enter valid credentials and try again!": "Usuario o contraseña invalido. ¡Ingrese credenciales válidas y vuelva a intentarlo!",
    "Inactive user!": "Usuario inactiva!",
    "The new password cannot be the same as the current password.": "La nueva contraseña no puede ser la misma que la contraseña actual.",
    "The current password entered is incorrect!": "¡La contraseña actual ingresada es incorrecta!",
    "sensors info" : "información de sensores",
    "breached" : "Violada",
    "help": "Ayudar",
    "Internet of Pests Help Center": "Centro de ayuda de Internet de plagas",
    "Go to IoP Dashboard": "Ir al panel de control de IoP",
    "sensors list": "Lista de sensores",
    "The Comm. Status Column": "El Com. Columna de estado",
    "All sensors communicate with the IoP® platform whenever it detects “pests or in each heartbeat”. The heartbeat time for rodent and crawling sensors is 24 hrs. and for fly and cigarette beetle sensors is 30 mins.": "Todos los sensores se comunican con la plataforma IoP® cada vez que detecta “plagas o en cada latido del corazón”. El tiempo de latido del corazón para los sensores de roedores y rastreo es de 24 horas. y para los sensores de escarabajos de cigarrillos y moscas es de 30 minutos.",
    "The Comm. Status or Communication Status lets you know what is the current status of a sensor and whether it’s functional. There are 4 possible values for comm. status:": "El Com. El estado o estado de comunicación le permite saber cuál es el estado actual de un sensor y si funciona. Hay 4 valores posibles para com. estado:",
    "Displays when a sensor is newly added to your account and has not yet been switched “On”.": "Aparece cuando un sensor se agrega recientemente a su cuenta y aún no se ha activado.",
    "Displays when a sensor is online and has detected any pest or has sent a “heartbeat” message in the last 25 hours (for Rodent and Crawling Insect sensors) or in the last 40 minutes (for Fly and Cigarette Beetle sensors)": "Aparece cuando un sensor está en línea y ha detectado alguna plaga o ha enviado un mensaje de “latido” en las últimas 25 horas (para sensores de roedores e insectos que se arrastran) o en los últimos 40 minutos (para sensores de moscas y escarabajos cigarrillos)",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message between last 25 hours and 48 hours (for Rodent and Crawling Insect sensors) or between last 40 minutes and 60 minutes (for Fly and Cigarette Beetle sensors)": "Aparece cuando un sensor no ha detectado ninguna plaga o no ha enviado un mensaje de “latido” entre las últimas 25 y 48 horas (para sensores para roedores e insectos que se arrastran) o entre los últimos 40 y 60 minutos (para sensores para moscas y escarabajos cigarrillos)",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message for more than 48 hours (for Rodent and Crawling Insect sensors) or for more than 60 minutes (for Fly and Cigarette Beetle sensors)": "Aparece cuando un sensor no ha detectado ninguna plaga o NO ha enviado un mensaje de “latido” durante más de 48 horas (para sensores de roedores e insectos que se arrastran) o durante más de 60 minutos (para sensores de moscas y escarabajos cigarrillos)",
    "The Last Seen/Alerts Column": "La columna Última vista/Alertas",
    "The Last Seen/Alerts column lets you know the last time and date an update (communication) was received from the sensor. It also includes three types of alert icons.": "La columna Último visto/Alertas le permite saber la última hora y fecha en que se recibió una actualización (comunicación) del sensor. También incluye tres tipos de iconos de alerta.",
    "The New Detections Column": "La columna Nuevas detecciones",
    "The new detections column displays the number of “triggers” or pests the sensor has detected since the last time they were resolved. These are also displayed on the Detections List screen from where the Pest Management Personnel can resolve them.": "La nueva columna de detecciones muestra la cantidad de “desencadenantes” o plagas que el sensor ha detectado desde la última vez que se resolvieron. Estos también se muestran en la pantalla Lista de detecciones desde donde el personal de control de plagas puede resolverlos.",
    "How often does the data on the sensor’s list page refresh?": "¿Con qué frecuencia se actualizan los datos de la página de lista de sensores?",
    "By default, the page will refresh automatically when the sensor detects pests or changes it's communication status.": "De manera predeterminada, la página se actualizará automáticamente cuando el sensor detecte plagas o cambie su estado de comunicación.",
    "sensors list help": "Ayuda con la lista de sensores",
    "Sensor Communication Channel": "Canal de comunicación de sensores",
    "LPWAN or Wi-Fi - if a sensor is communicating over an LPWAN network or is configured to communicate over a local Wi-Fi network.": "LPWAN o Wi-Fi: si un sensor se comunica a través de una red LPWAN o está configurado para comunicarse a través de una red Wi-Fi local.",
    "Critical Battery Status": "Estado crítico de la batería",
    "Breach Status": "Estado de incumplimiento",
    "when a zone with the specific pest type has exceeded (breached) the detections or Critical Control Limit for a specific pest.": "Cuando una zona con el tipo de plaga específico ha excedido (violado) las detecciones o el Límite Crítico de Control para una plaga específica.",
    "when a Fly or Cigarette Beetle sensor's glue board has expired. The glue board expiry is calculated from the date the sensor was added to the platform.": "Cuando la placa adhesiva de un sensor Fly o Cigarette Beetle ha caducado. La caducidad de la placa adhesiva se calcula a partir de la fecha en que se añadió el sensor a la plataforma.",
    "when a Fly or Cigarette Beetle sensor's max detections limit on the glue board has exceeded.": "Cuando se ha excedido el límite máximo de detecciones de un sensor Fly o Cigarette Beetle en la placa adhesiva.",
    "Rodent and Crawling Insect sensors contains a lithium-ion non rechargeable cell that is permanently pre-installed for the 5 year or 10,000 transmissions expected operational lifetime.": "Los sensores para roedores e insectos que se arrastran contienen una celda no recargable de iones de litio que está preinstalada permanentemente para la vida útil esperada de 5 años o 10,000 transmisiones.",
    "A critical battery status is displayed when a sensor's battery is below 10%. This percentage value is calculated based on the total number of “triggers” generated by the sensor; i.e. (10,000 - Total Triggers) %.": "Se muestra un estado crítico de la batería cuando la batería de un sensor está por debajo del 10 %. Este valor porcentual se calcula en base al número total de “triggers” generados por el sensor; es decir, (10 000 - Activadores totales) %.",
    "The total triggers is maintained from the time the sensor was first turned on.": "El total de disparos se mantiene desde el momento en que se encendió el sensor por primera vez.",
    "co2": "CO2",
    "co": "CO",
    "pm2.5": "PM2.5",
    "voc": "VOC",
    "7 Days" : "7 días",
    "30 Days" : "30 dias",
    "temperature": "La temperatura",
    "humidity": "Humedad",
    "no matches": "No hay coincidencias",
    "valid format JPG/JPEG/PNG": "Formato válido JPG/JPEG/PNG",
    "max file size: 5MB": "Tamaño máximo de archivo: 5 MB",
    "Filter data by selecting the legends": "Filtrar Datos seleccionando las leyendas",
    "day vs day": "dia contra dia",
    "week vs week": "semana contra semana",
    "month vs month": "mes contra mes",
    "year vs year": "año contra año",
    "Aggregated for": "Agregado para",
    "Filters": "filtros",
    "zone map successfully updated.": "Mapa de zonas actualizado con éxito.",
    "English" : "English",
    "Japanese" : "日本",
    "Spanish" : "Español",
    "submit" : "Entregar",
    "Did you forgot your password?" : "¿Olvidaste tu contraseña?",
    "Submit your Username or registered Email ID and you will receive a temporary password to login." : "Envíe su nombre de usuario o ID de correo electrónico registrado y recibirá una contraseña temporal para iniciar sesión.",
    "Back to Sign in" : "Volver a Iniciar sesión",
    "Temporary password has been sent to your email successfully." : "La contraseña temporal ha sido enviada a su correo electrónico con éxito.",
    "Username / Email ID" : "Nombre de usuario / ID de correo electrónico",
    "please enter your username / email id": "Ingrese su nombre de usuario / ID de correo electrónico",
    "site pest detections report" : "Informe de detecciones de plagas del sitio",
    "No unMapped sensor" : "Sin sensor no mapeado",
    "Hourly cumulative detections" : "Detecciones acumuladas horarias",
    "Percentage detections" : "Detecciones porcentuales",
    "Sensor name" : "Nombre del sensor",
    "count" : "Contar",
    "total detections" : "Detecciones totales",
    "Please select the customer, site, date range and time zone to generate the report." : "Seleccione el cliente, el sitio, el rango de fechas y la zona horaria para generar el informe.",
    "system error!" : "¡Error del sistema!",
    "download" : "Descargar",
    "site address" : "Dirección del sitio",
    "site timezone" : "Zona horaria del sitio",
    "Pest Management Company" : "Empresa de control de plagas",
    "Evoscien Main Office" : "Evoscien Main Office",
    "generated on" : "descargado en",
    "generated by" : "descargado por",
    "Date range" : "Rango de fechas",
    "Time zone" : "Zona horaria",
    "Powered by" : "Energizado por",
    "This report was generated" : "Este informe fue generado",
    "Percentage change" : "Cambio porcentual",
    "generate report" : "Generar informe",
    "stakeholder performance analysis" : "Análisis del desempeño de las partes interesadas",
    "Sort by Site" : "Ordenar por sitio",
    "Sort by Zone" : "Ordenar por zona",
    "Week I: Starting Date Of" : "Semana I: Fecha de inicio de",
    "Week II: Starting Date Of" : "Semana II: Fecha de inicio de",
    "Please select the customer, pest and comparison type and time zone to generate the report." : "Seleccione el cliente, la plaga y el tipo de comparación y la zona horaria para generar el informe.",
    "deleted" : "Eliminado",
    "original day" : "Día original",
    "final day" : "Último día",
    "Original Day should be less than Final Day" : "El día original debe ser menor que el día final",
    "Final Day should be greater than Original Day" : "El día final debería ser mayor que el día original",
    "original week" : "Semana original",
    "final week" : "Semana final",
    "Original Week should be less than Final Week" : "La semana original debería ser menor que la semana final",
    "Final Week should be greater than Original Week" : "La semana final debería ser mayor que la semana original",
    "original month" : "Mes original",
    "final month" : "último mes",
    "Original Month should be less than Final Month" : "El mes original debe ser menor que el mes final",
    "Final Month should be greater than Original Month": "El mes final debe ser mayor que el mes original",
    "original year" : "Año original",
    "final year" : "Año final",
    "Original Year should be less than Final Year" : "El año original debe ser menor que el año final",
    "Final Year should be greater than Original Year" : "El año final debe ser mayor que el año original.",
    "Please select the customer and pest to generate the report." : "Seleccione el cliente y la plaga para generar el informe.",
    "progress" : "Progreso",
    "success" : "Éxito",
    "failed" : "fallido",
    "retry" : "Rever",
    "status" : "Estado",
    "percentage detections" : "Detecciones porcentuales",
    "faqs" : "Preguntas frecuentes",
    "What is remote pest monitoring? Why is it important?" : "¿Qué es el monitoreo remoto de plagas? ¿Por qué es importante?",
    "Remote pest monitoring technology allows pest management professionals to track and monitor pest activity from anywhere in real-time via mobile or web-based platforms. It allows pest control technicians to quickly identify and treat hot spots on the premises, potentially reducing labour time and chemical use." : "La tecnología de monitoreo remoto de plagas permite a los profesionales del manejo de plagas rastrear y monitorear la actividad de las plagas desde cualquier lugar en tiempo real a través de plataformas móviles o basadas en la web. Permite a los técnicos de control de plagas identificar y tratar rápidamente los puntos calientes en las instalaciones, lo que potencialmente reduce el tiempo de mano de obra y el uso de productos químicos.",
    "What is the point of using sensors without a trap?" : "¿Cuál es el punto de utilizar sensores sin trampa?",
    "The sensor’s flexible form factor allows it to be placed in usually inaccessible areas, such as roof spaces and ventilation shafts, where they can provide early warning of potential pest breeding sites and threats to infrastructure." : "El factor de forma flexible del sensor permite colocarlo en áreas normalmente inaccesibles, como espacios en techos y conductos de ventilación, donde pueden proporcionar una alerta temprana sobre posibles lugares de reproducción de plagas y amenazas a la infraestructura.",
    "Can a single sensor detect rodents, crawling, and flying insects?" : "¿Puede un solo sensor detectar roedores, insectos rastreros y voladores?",
    "No. While the underlying technology is the same, each sensor is designed for a particular pest type." : "No. Si bien la tecnología subyacente es la misma, cada sensor está diseñado para un tipo de plaga en particular.",
    "Will my sensor battery last 7 years? Are the batteries replaceable?" : "¿La batería de mi sensor durará 7 años? ¿Las baterías son reemplazables?",
    "The expected battery life of the rodent and crawling pest sensors is 7 years or 10,000 transmissions. Actual battery life would vary depending on the frequency of pest activity in the area. Batteries can only be replaced by a trained technician." : "La duración prevista de la batería de los sensores de roedores y plagas rastreras es de 7 años o 10.000 transmisiones. La duración real de la batería variará según la frecuencia de la actividad de plagas en el área. Las baterías sólo pueden ser reemplazadas por un técnico capacitado.",
    "Flying insect sensors are powered by the main power supply, and do not require batteries." : "Los sensores de insectos voladores funcionan con la fuente de alimentación principal y no requieren baterías.",
    "Do I need Wi-Fi for my IoP® setup?" : "¿Necesito Wi-Fi para la configuración de mi IoP®?",
    "A Wi-Fi or ethernet connection is only required for the local gateway to receive signals from the rodent and crawling pest sensors. Wi-Fi is preferred for flying insect sensors, but they can transmit data to rodents and crawling insect gateways." : "Solo se requiere una conexión Wi-Fi o Ethernet para que la puerta de enlace local reciba señales de los sensores de roedores y plagas rastreras. Se prefiere Wi-Fi para los sensores de insectos voladores, pero pueden transmitir datos a roedores y portales de insectos rastreros.",
    "Since data transmission is minimal, low-data pre-paid SIMs can be used if local Wi-Fi or ethernet connections are unavailable." : "Dado que la transmisión de datos es mínima, se pueden utilizar tarjetas SIM prepagas de bajo consumo de datos si las conexiones Wi-Fi o Ethernet locales no están disponibles.",
    "Can I add, relocate, or remove sensors if needed?" : "¿Puedo agregar, reubicar o quitar sensores si es necesario?",
    "Yes, the IoP® system allows seamless sensor addition, removal, replacement, and relocation without data loss. For more information, refer to the Technician’s Handbook." : "Sí, el sistema IoP® permite agregar, retirar, reemplazar y reubicar sensores sin pérdida de datos. Para obtener más información, consulte el Manual del técnico.",
    "What do I do if my sensors stop working?" : "¿Qué hago si mis sensores dejan de funcionar?",
    "In the absence of pest activity, sensors transmit a heartbeat every 24 hours to indicate their functionality. Contact your provider to repair or replace the sensor if no heartbeat signal is received, and the sensor status changes to idle or offline.": "En ausencia de actividad de plagas, los sensores transmiten un latido cada 24 horas para indicar su funcionamiento. Comuníquese con su proveedor para reparar o reemplazar el sensor si no se recibe ninguna señal de latido y el estado del sensor cambia a inactivo o fuera de línea.",
    "How do I sign up for an IoP® account?" : "¿Cómo me registro para obtener una cuenta IoP®?",
    "Your IoP® super-admin account will be generated upon confirmation of your order. Contact our support team for more information, or to request a demo." : "La plataforma IoP® envía alertas en tiempo real para violaciones de los límites de control críticos y actualizaciones del estado de comunicación del sensor, incluidas alertas inactivas/fuera de línea/activas, relacionadas con el sitio, como acceso al sitio concedido, detecciones del sitio resueltas y alertas relacionadas con la zona cuando se resuelven las detecciones de plagas. en una zona.",
    "I’ve received a breach alert. What do I do next?" : "Recibí una alerta de infracción. ¿Que hago después?",
    "Critical control limits refer to the acceptable level of pest activity. The values can be manually adjusted per pest according to customer requirements and/or local regulations. An alert is sent when sensor detections exceed the critical control limit. Breach alerts call for immediate action by your pest control service provider." : "Los límites críticos de control se refieren al nivel aceptable de actividad de plagas. Los valores se pueden ajustar manualmente por plaga según los requisitos del cliente y/o las regulaciones locales. Se envía una alerta cuando las detecciones del sensor exceden el límite de control crítico. Las alertas de infracción exigen una acción inmediata por parte de su proveedor de servicios de control de plagas.",
    "What type of alerts does the IoP® platform provide?" : "¿Qué tipo de alertas proporciona la plataforma IoP®?",
    "The IoP® platform sends real-time alerts for critical control limit breaches and sensor communication status updates, including idle/offline/active, site-related alerts like site access granted, site detections resolved, and zone-related alerts when pest detections are resolved in a zone." : "La plataforma IoP® envía alertas en tiempo real para violaciones de los límites de control críticos y actualizaciones del estado de comunicación del sensor, incluidas alertas inactivas/fuera de línea/activas, relacionadas con el sitio, como acceso al sitio concedido, detecciones de sitio resueltas y alertas relacionadas con la zona cuando se resuelven las detecciones de plagas. en una zona.",
    "How does IoP® help identify pest entry points?" : "¿Cómo ayuda IoP® a identificar los puntos de entrada de plagas?",
    "IoP® sensors provide valuable insight into pest movement patterns by recording which side of the sensor the pest contacted first. Taken together, this data can help PMPs to identify and target potential breeding sites and points of entry." : "Los sensores IoP® brindan información valiosa sobre los patrones de movimiento de las plagas al registrar con qué lado del sensor la plaga entró en contacto primero. En conjunto, estos datos pueden ayudar a las PMP a identificar y apuntar a posibles sitios de reproducción y puntos de entrada.",
    "Does the IoP® platform provide documentation for regulatory compliance?" : "¿La plataforma IoP® proporciona documentación para el cumplimiento normativo?",
    "Yes, all pest activity is securely stored and accessible on the IoP® platform anytime. Users can generate and view historical and current pest activity reports to demonstrate regulatory compliance." : "Sí, toda la actividad de plagas se almacena de forma segura y se puede acceder a ella en la plataforma IoP® en cualquier momento. Los usuarios pueden generar y ver informes históricos y actuales de actividad de plagas para demostrar el cumplimiento normativo.",
    "Where can the sensors be used?" : "¿Dónde se pueden utilizar los sensores?",
    "The sensors can be deployed in areas prone to pest activity, like kitchens, storage areas, waste disposal areas, etc. They can also be used in hard-to-access areas like roof spaces and ventilation ducts." : "Los sensores se pueden implementar en áreas propensas a la actividad de plagas, como cocinas, áreas de almacenamiento, áreas de eliminación de desechos, etc. También se pueden usar en áreas de difícil acceso, como espacios en techos y conductos de ventilación.",
    "Is the sensor waterproof?" : "¿El sensor es resistente al agua?",
    "Yes, the sensor is IP67 rated, but we recommend turning the unit off and drying it thoroughly before reuse to reduce the possibility of false detections." : "Sí, el sensor tiene clasificación IP67, pero recomendamos apagar la unidad y secarla completamente antes de volver a utilizarla para reducir la posibilidad de detecciones falsas.",
    "If you have more queries please click below our official website" : "Si tiene más consultas, haga clic debajo de nuestro sitio web oficial.",
    "Follow us on" : "Siga con nosotros",
    "Download Apps" : "Descargar aplicaciones",
    "User Guides" : "Guías de usuario",
    "IoP® Platform User Manual" : "Manual de usuario de la plataforma IoP®",
    "IoP® Technician’s Handbook" : "Manual del técnico de IoP®",
    "Frequently Asked Questions" : "Preguntas frecuentes",
    "Visit" : "Visita",
    "IoP® website" : "Sitio web de IoP®",
    "for more information" : "para más información",
    "Sensor detected possible pest shelter or entrance point." : "El sensor detectó un posible refugio o punto de entrada de plagas.",
    "Sensors detected possible food source or entrance point." : "Los sensores detectaron una posible fuente de alimento o punto de entrada.",
    "Error: Unable to connect to the backend server." : "Error: No se puede conectar con el servidor backend.",
    "Sensor type not enabled: Crawling Insect" : "Tipo de sensor no habilitado: Insecto rastrero",
    "Sensor type not enabled: Rodent" : "Tipo de sensor no habilitado: Roedor",
    "Sensor type not enabled: Fly" : "Tipo de sensor no habilitado: Volar",
    "Sensor type not enabled: Cigarette Beetle" : "Tipo de sensor no habilitado: Escarabajo de cigarrillos",
    "Zone Events" : "Eventos de zona",
    "zone live monitoring disabled" : "Monitoreo en vivo de zona deshabilitado",
    "zone live monitoring enabled" : "Monitoreo en vivo de zona habilitado",
    "live monitoring toggle" : "Alternar monitoreo en vivo",
    "live monitoring" : "Monitoreo en vivo",
    "live monitoring disabled" : "Monitoreo en vivo deshabilitado",
    "live monitoring enabled" : "Monitoreo en vivo habilitado",
    "enable live monitoring" : "Habilitar monitoreo en vivo",
    "Enabling live monitoring will allow pest detections and alerts in real-time from being updated on the platform." : "Habilitar el monitoreo en vivo permitirá que las detecciones y alertas de plagas en tiempo real se actualicen en la plataforma.",
    "I confirm that I have turned ON all the sensors and they are reporting Active." : "Confirmo que he encendido todos los sensores y están reportando Activo.",
    "successfully enabled the live monitoring" : "Se habilitó exitosamente el monitoreo en vivo",
    "disable live monitoring" : "Deshabilitar el monitoreo en vivo",
    "Disabling live monitoring will ignore any pest detections that would be sent by the sensors and will prevent real-time detections and alerts from being updated on the platform." : "Al desactivar el monitoreo en vivo se ignorarán las detecciones de plagas que enviarían los sensores y evitará que las detecciones y alertas en tiempo real se actualicen en la plataforma.",
    "I confirm to disable live monitoring for this zone." : "Confirmo para desactivar el monitoreo en vivo para esta zona.",
    "Daily heartbeat and power ON messages will NOT be ignored and the application will continue to update the Communication Status and Last Seen Timestamp for all the sensors in this zone." : "Los mensajes diarios de latido y encendido NO se ignorarán y la aplicación continuará actualizando el estado de comunicación y la marca de tiempo de la última vista para todos los sensores en esta zona.",
    "successfully disabled the live monitoring" : "Deshabilitado exitosamente el monitoreo en vivo",
    "sensor event rejected due to disabled zone live monitoring" : "evento de sensor rechazado debido a monitoreo en vivo de zona deshabilitada",
    "Warning! Zone Live monitoring is currently disabled. Last sensor detection was" : "¡Advertencia! La monitorización de Zone Live está actualmente deshabilitada. La última detección del sensor fue",
    "Enable Live Monitoring for the zone to start logging  sensor detections." : "Habilite el monitoreo en vivo para que la zona comience a registrar detecciones de sensores.",
    "The following sensors are not Active. Please cycle the sensor power and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : "Los siguientes sensores no están activos. Encienda y encienda el sensor y asegúrese de que estén reportando Activo en la aplicación antes de habilitar el monitoreo en vivo para esta zona.",
    "I acknowledge the above issues and still want to enable live monitoring." : "Reconozco los problemas anteriores y aún quiero habilitar el monitoreo en vivo.",
    "The following Active sensors were last seen before Live Monitoring for this Zone was Disabled on" : "Los siguientes sensores activos se vieron por última vez antes de que se deshabilitara el monitoreo en vivo para esta zona el",
    ". If you may have switched OFF the following sensors, then please switch them back ON and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : ". Si es posible que haya apagado los siguientes sensores, vuelva a encenderlos y asegúrese de que estén reportados como Activos en la aplicación antes de habilitar el monitoreo en vivo para esta zona.",
    "Warning: Enable Live Monitoring Failed" : "Advertencia: Habilitar monitorización en vivo fallida",
    "remove floor map" : "Eliminar mapa de piso",
    "Live Monitoring" : "Monitoreo en vivo",
    "for the zone where you are adding the sensor is" : "para la zona donde está agregando el sensor es",
    "Disabled" : "Desactivada",
    "Please" : "Por favor",
    "Enable" : "Permitir",
    "after you have added and turned ON all the sensors in this zone to monitoring pest detections" : "después de haber agregado y encendido todos los sensores en esta zona para monitorear las detecciones de plagas",
    "Remove Zone Map" : "Eliminar mapa de zona",
    "Live Monitoring for this zone is" : "El monitoreo en vivo para esta zona es",
    "disabled." : "desactivada.",
    "After you have added and turned ON all the sensors in this zone;" : "Después de haber agregado y encendido todos los sensores en esta zona;",
    "enable " : "permitir ",
    "Live Monitoring to monitor pest detections." : "Monitoreo en Vivo para monitorear las detecciones de plagas.",
    "Disabled Live Monitoring Zones" : "Zonas de monitoreo en vivo deshabilitadas",
    "Zone Map Removed Successfully" : "Mapa de zona eliminado con éxito",
    "Upload zone map" : "Subir mapa de zona",
    "No zone map found!" : "¡No se encontró ningún mapa de zona!",
    "last updated timestamp" : "Marca de tiempo de la última actualización",
    "Zone Map Uploaded Successfully" : "Mapa de zona cargado exitosamente",
    "all roles" : "Todos los roles",
    "no detections reported!" : "¡No se han reportado detecciones!",
    "Removing the zone map will un-map all the currently mapped sensor and delete the zone map. Are you sure you want to remove the zone map?" : "Al eliminar el mapa de zona, se eliminarán todos los sensores actualmente asignados y se eliminará el mapa de zona. ¿Estás seguro de que quieres eliminar el mapa de zona?",
    "Successfully added sensor(s)." : "Sensor(es) agregado(s) exitosamente.",
    "Click here" : "haga clic aquí",
    "to map these sensors on the zone map." : "para mapear estos sensores en el mapa de zona.",
    "has been automatically" : "ha sido automáticamente",
    "for the" : "Para el",
    "click here " : "haga clic aquí ",
    "live monitoring for this zone only after turning" : "monitoreo en vivo para esta zona solo después de girar",
    "zone to prevent any false detections during handling of the sensors." : "zona para evitar falsas detecciones durante el manejo de los sensores.",
    "ON" : "EN",
    "the newly added sensors." : "los sensores recién agregados.",
    "is not mapped on the zone map" : "No está mapeada en el mapa de zona",
    "latest sensor image" : "última imagen del sensor"
 }