import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { apiData } from '../../../common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private _commonDataService: CommonDataService) { }

  getCustomersPaginated(url = apiData.url + apiData.customers.paginationUrl): Observable<customer.CustomerResponse> {   // url = apiData.url + url;
    // url = 'http://dev-uas.iopbeta.com:8080/api/v1/' + url;

    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((userResponse: customer.CustomerResponse) => {
        observer.next(userResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getCustomer(id) {
    let url = apiData.url + apiData.customers.singleCustomerUrl + '/' + id;
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((addEditResponse: any) => {
        observer.next(addEditResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getBoneCustomerList(){
    let url = apiData.url + apiData.customers.boneCustomerList;
    return new Observable(observer => {
      this._commonDataService.getBOneData(url).subscribe((customer: any) => {
        observer.next(customer);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getBreachedCount(finalUrl) {
    let url = apiData.url + apiData.customers.breached + finalUrl;
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((breachedResponse: any) => {
        observer.next(breachedResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  updateCustomer(data, customerId): Observable<any> {
    let url = apiData.url + apiData.customers.singleCustomerUrl + '/image/' + customerId;

    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(data, url).subscribe((editCustomerResponse: any) => {

        observer.next(editCustomerResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });

  }

  addCustomer(data): Observable<any> {
    let url = apiData.url + apiData.customers.singleCustomerUrl + '/image/';

    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(data, url).subscribe((editCustomerResponse: any) => {

        observer.next(editCustomerResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  deleteCustomer(data): Observable<any> {
    let url = apiData.url + apiData.customers.singleCustomerUrl + '/' + data

    return new Observable(observer => {
      this._commonDataService.deleteDataOnlyIOPHeader(url).subscribe((editCustomerResponse: any) => {

        observer.next(editCustomerResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });

  }

  getResourceDetails(customerId): Observable<customerResource.CustomerResourceResponse> {
    let url = apiData.url + apiData.customers.resourceUrl + '/' + customerId

    return new Observable(observer => {
      this._commonDataService.getDataIoPUserID(url).subscribe((customerResourceResponse: customerResource.CustomerResourceResponse) => {

        observer.next(customerResourceResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getCustomerIsExits(customerName) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.customers.customerNameIsExits + '/' + customerName).subscribe((customerName: any) => {
        observer.next(customerName);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getDonutChartSetting(getData, chartTitle, themeValue, xAxisLabel) {

    getData[0]['color'] = themeValue.chartColor;
    let getValue = getData[0]['y'];
    let data = getData;

    const chart = new Chart({
      //colors: themeValue.chartColor,
      chart: {
        renderTo: 'donutChart',
        type: 'pie',
        backgroundColor: themeValue.backgroundColor,
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0
  
      },
      title: {
        text: '<b>'+getValue +'</b>'+'<br/>' +chartTitle,
        style: {
          color: themeValue.textColor,
          fontSize : '15px',
          fontFamily: "Poppins-Regular"
        },
        verticalAlign: 'middle',
      },
      // yAxis: {
      //   title: {
      //     text: 'Total percent market share',
      //     style: {
      //       color: themeValue.textColor,
      //       fontWeight: 'bold'
      //     }
      //   }
      // },
      plotOptions: {
        pie: {
          shadow: false
        }
      },
      credits: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b>: ' + this.y; //+' %';
        }, style: {
          color: themeValue.textColor,
          fontSize : '15px',
          fontFamily: "Poppins-Regular"
        },
      },
      series: [{
        type:'pie',
        name: 'Browsers',
        data: data,
        size: '200px',
        innerSize: '160px',
        showInLegend: false,
        dataLabels: {
          enabled: false
        }
      }]
    });
    return chart
  }

  getTimeZone(body) {
    let url = apiData.url + apiData.timeZoneId;
    return new Observable(observer => {
      this._commonDataService.postData(body, url).subscribe((sensor: sensors.SensorResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getCountriesNameList() {
    const countries = [
      { "text": "Afghanistan", "value": "AF" },
      { "text": "Åland Islands", "value": "AX" },
      { "text": "Albania", "value": "AL" },
      { "text": "Algeria", "value": "DZ" },
      { "text": "American Samoa", "value": "AS" },
      { "text": "Andorra", "value": "AD" },
      { "text": "Angola", "value": "AO" },
      { "text": "Anguilla", "value": "AI" },
      { "text": "Antarctica", "value": "AQ" },
      { "text": "Antigua and Barbuda", "value": "AG" },
      { "text": "Argentina", "value": "AR" },
      { "text": "Armenia", "value": "AM" },
      { "text": "Aruba", "value": "AW" },
      { "text": "Australia", "value": "AU" },
      { "text": "Austria", "value": "AT" },
      { "text": "Azerbaijan", "value": "AZ" },
      { "text": "Bahamas", "value": "BS" },
      { "text": "Bahrain", "value": "BH" },
      { "text": "Bangladesh", "value": "BD" },
      { "text": "Barbados", "value": "BB" },
      { "text": "Belarus", "value": "BY" },
      { "text": "Belgium", "value": "BE" },
      { "text": "Belize", "value": "BZ" },
      { "text": "Benin", "value": "BJ" },
      { "text": "Bermuda", "value": "BM" },
      { "text": "Bhutan", "value": "BT" },
      { "text": "Bolivia", "value": "BO" },
      { "text": "Bosnia and Herzegovina", "value": "BA" },
      { "text": "Botswana", "value": "BW" },
      { "text": "Bouvet Island", "value": "BV" },
      { "text": "Brazil", "value": "BR" },
      { "text": "British Indian Ocean Territory", "value": "IO" },
      { "text": "Brunei Darussalam", "value": "BN" },
      { "text": "Bulgaria", "value": "BG" },
      { "text": "Burkina Faso", "value": "BF" },
      { "text": "Burundi", "value": "BI" },
      { "text": "Cambodia", "value": "KH" },
      { "text": "Cameroon", "value": "CM" },
      { "text": "Canada", "value": "CA" },
      { "text": "Cape Verde", "value": "CV" },
      { "text": "Cayman Islands", "value": "KY" },
      { "text": "Central African Republic", "value": "CF" },
      { "text": "Chad", "value": "TD" },
      { "text": "Chile", "value": "CL" },
      { "text": "China", "value": "CN" },
      { "text": "Christmas Island", "value": "CX" },
      { "text": "Cocos (Keeling) Islands", "value": "CC" },
      { "text": "Colombia", "value": "CO" },
      { "text": "Comoros", "value": "KM" },
      { "text": "Congo", "value": "CG" },
      { "text": "Congo, The Democratic Republic of the", "value": "CD" },
      { "text": "Cook Islands", "value": "CK" },
      { "text": "Costa Rica", "value": "CR" },
      { "text": "Cote D'Ivoire", "value": "CI" },
      { "text": "Croatia", "value": "HR" },
      { "text": "Cuba", "value": "CU" },
      { "text": "Cyprus", "value": "CY" },
      { "text": "Czech Republic", "value": "CZ" },
      { "text": "Denmark", "value": "DK" },
      { "text": "Djibouti", "value": "DJ" },
      { "text": "Dominica", "value": "DM" },
      { "text": "Dominican Republic", "value": "DO" },
      { "text": "Ecuador", "value": "EC" },
      { "text": "Egypt", "value": "EG" },
      { "text": "El Salvador", "value": "SV" },
      { "text": "Equatorial Guinea", "value": "GQ" },
      { "text": "Eritrea", "value": "ER" },
      { "text": "Estonia", "value": "EE" },
      { "text": "Ethiopia", "value": "ET" },
      { "text": "Falkland Islands (Malvinas)", "value": "FK" },
      { "text": "Faroe Islands", "value": "FO" },
      { "text": "Fiji", "value": "FJ" },
      { "text": "Finland", "value": "FI" },
      { "text": "France", "value": "FR" },
      { "text": "French Guiana", "value": "GF" },
      { "text": "French Polynesia", "value": "PF" },
      { "text": "French Southern Territories", "value": "TF" },
      { "text": "Gabon", "value": "GA" },
      { "text": "Gambia", "value": "GM" },
      { "text": "Georgia", "value": "GE" },
      { "text": "Germany", "value": "DE" },
      { "text": "Ghana", "value": "GH" },
      { "text": "Gibraltar", "value": "GI" },
      { "text": "Greece", "value": "GR" },
      { "text": "Greenland", "value": "GL" },
      { "text": "Grenada", "value": "GD" },
      { "text": "Guadeloupe", "value": "GP" },
      { "text": "Guam", "value": "GU" },
      { "text": "Guatemala", "value": "GT" },
      { "text": "Guernsey", "value": "GG" },
      { "text": "Guinea", "value": "GN" },
      { "text": "Guinea-Bissau", "value": "GW" },
      { "text": "Guyana", "value": "GY" },
      { "text": "Haiti", "value": "HT" },
      { "text": "Heard Island and Mcdonald Islands", "value": "HM" },
      { "text": "Holy See (Vatican City State)", "value": "VA" },
      { "text": "Honduras", "value": "HN" },
      { "text": "Hong Kong", "value": "HK" },
      { "text": "Hungary", "value": "HU" },
      { "text": "Iceland", "value": "IS" },
      { "text": "India", "value": "IN" },
      { "text": "Indonesia", "value": "ID" },
      { "text": "Iran", "value": "IR" },
      { "text": "Iraq", "value": "IQ" },
      { "text": "Ireland", "value": "IE" },
      { "text": "Isle of Man", "value": "IM" },
      { "text": "Israel", "value": "IL" },
      { "text": "Italy", "value": "IT" },
      { "text": "Jamaica", "value": "JM" },
      { "text": "Japan", "value": "JP" },
      { "text": "Jersey", "value": "JE" },
      { "text": "Jordan", "value": "JO" },
      { "text": "Kazakhstan", "value": "KZ" },
      { "text": "Kenya", "value": "KE" },
      { "text": "Kiribati", "value": "KI" },
      { "text": "Korea, Democratic People'S Republic of", "value": "KP" },
      { "text": "Korea, Republic of", "value": "KR" },
      { "text": "Kuwait", "value": "KW" },
      { "text": "Kyrgyzstan", "value": "KG" },
      { "text": "Lao People'S Democratic Republic", "value": "LA" },
      { "text": "Latvia", "value": "LV" },
      { "text": "Lebanon", "value": "LB" },
      { "text": "Lesotho", "value": "LS" },
      { "text": "Liberia", "value": "LR" },
      { "text": "Libyan Arab Jamahiriya", "value": "LY" },
      { "text": "Liechtenstein", "value": "LI" },
      { "text": "Lithuania", "value": "LT" },
      { "text": "Luxembourg", "value": "LU" },
      { "text": "Macao", "value": "MO" },
      { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
      { "text": "Madagascar", "value": "MG" },
      { "text": "Malawi", "value": "MW" },
      { "text": "Malaysia", "value": "MY" },
      { "text": "Maldives", "value": "MV" },
      { "text": "Mali", "value": "ML" },
      { "text": "Malta", "value": "MT" },
      { "text": "Marshall Islands", "value": "MH" },
      { "text": "Martinique", "value": "MQ" },
      { "text": "Mauritania", "value": "MR" },
      { "text": "Mauritius", "value": "MU" },
      { "text": "Mayotte", "value": "YT" },
      { "text": "Mexico", "value": "MX" },
      { "text": "Micronesia, Federated States of", "value": "FM" },
      { "text": "Moldova, Republic of", "value": "MD" },
      { "text": "Monaco", "value": "MC" },
      { "text": "Mongolia", "value": "MN" },
      { "text": "Montserrat", "value": "MS" },
      { "text": "Morocco", "value": "MA" },
      { "text": "Mozambique", "value": "MZ" },
      { "text": "Myanmar", "value": "MM" },
      { "text": "Namibia", "value": "NA" },
      { "text": "Nauru", "value": "NR" },
      { "text": "Nepal", "value": "NP" },
      { "text": "Netherlands", "value": "NL" },
      { "text": "Netherlands Antilles", "value": "AN" },
      { "text": "New Caledonia", "value": "NC" },
      { "text": "New Zealand", "value": "NZ" },
      { "text": "Nicaragua", "value": "NI" },
      { "text": "Niger", "value": "NE" },
      { "text": "Nigeria", "value": "NG" },
      { "text": "Niue", "value": "NU" },
      { "text": "Norfolk Island", "value": "NF" },
      { "text": "Northern Mariana Islands", "value": "MP" },
      { "text": "Norway", "value": "NO" },
      { "text": "Oman", "value": "OM" },
      { "text": "Pakistan", "value": "PK" },
      { "text": "Palau", "value": "PW" },
      { "text": "Palestinian Territory, Occupied", "value": "PS" },
      { "text": "Panama", "value": "PA" },
      { "text": "Papua New Guinea", "value": "PG" },
      { "text": "Paraguay", "value": "PY" },
      { "text": "Peru", "value": "PE" },
      { "text": "Philippines", "value": "PH" },
      { "text": "Pitcairn", "value": "PN" },
      { "text": "Poland", "value": "PL" },
      { "text": "Portugal", "value": "PT" },
      { "text": "Puerto Rico", "value": "PR" },
      { "text": "Qatar", "value": "QA" },
      { "text": "Reunion", "value": "RE" },
      { "text": "Romania", "value": "RO" },
      { "text": "Russian Federation", "value": "RU" },
      { "text": "RWANDA", "value": "RW" },
      { "text": "Saint Helena", "value": "SH" },
      { "text": "Saint Kitts and Nevis", "value": "KN" },
      { "text": "Saint Lucia", "value": "LC" },
      { "text": "Saint Pierre and Miquelon", "value": "PM" },
      { "text": "Saint Vincent and the Grenadines", "value": "VC" },
      { "text": "Samoa", "value": "WS" },
      { "text": "San Marino", "value": "SM" },
      { "text": "Sao Tome and Principe", "value": "ST" },
      { "text": "Saudi Arabia", "value": "SA" },
      { "text": "Senegal", "value": "SN" },
      { "text": "Serbia and Montenegro", "value": "CS" },
      { "text": "Seychelles", "value": "SC" },
      { "text": "Sierra Leone", "value": "SL" },
      { "text": "Singapore", "value": "SG" },
      { "text": "Slovakia", "value": "SK" },
      { "text": "Slovenia", "value": "SI" },
      { "text": "Solomon Islands", "value": "SB" },
      { "text": "Somalia", "value": "SO" },
      { "text": "South Africa", "value": "ZA" },
      { "text": "South Georgia and the South Sandwich Islands", "value": "GS" },
      { "text": "Spain", "value": "ES" },
      { "text": "Sri Lanka", "value": "LK" },
      { "text": "Sudan", "value": "SD" },
      { "text": "Suriname", "value": "SR" },
      { "text": "Svalbard and Jan Mayen", "value": "SJ" },
      { "text": "Swaziland", "value": "SZ" },
      { "text": "Sweden", "value": "SE" },
      { "text": "Switzerland", "value": "CH" },
      { "text": "Syrian Arab Republic", "value": "SY" },
      { "text": "Taiwan, Province of China", "value": "TW" },
      { "text": "Tajikistan", "value": "TJ" },
      { "text": "Tanzania, United Republic of", "value": "TZ" },
      { "text": "Thailand", "value": "TH" },
      { "text": "Timor-Leste", "value": "TL" },
      { "text": "Togo", "value": "TG" },
      { "text": "Tokelau", "value": "TK" },
      { "text": "Tonga", "value": "TO" },
      { "text": "Trinidad and Tobago", "value": "TT" },
      { "text": "Tunisia", "value": "TN" },
      { "text": "Turkey", "value": "TR" },
      { "text": "Turkmenistan", "value": "TM" },
      { "text": "Turks and Caicos Islands", "value": "TC" },
      { "text": "Tuvalu", "value": "TV" },
      { "text": "Uganda", "value": "UG" },
      { "text": "Ukraine", "value": "UA" },
      { "text": "United Arab Emirates", "value": "AE" },
      { "text": "United Kingdom", "value": "GB" },
      { "text": "United States", "value": "US" },
      { "text": "United States Minor Outlying Islands", "value": "UM" },
      { "text": "Uruguay", "value": "UY" },
      { "text": "Uzbekistan", "value": "UZ" },
      { "text": "Vanuatu", "value": "VU" },
      { "text": "Venezuela", "value": "VE" },
      { "text": "Viet Nam", "value": "VN" },
      { "text": "Virgin Islands, British", "value": "VG" },
      { "text": "Virgin Islands, U.S.", "value": "VI" },
      { "text": "Wallis and Futuna", "value": "WF" },
      { "text": "Western Sahara", "value": "EH" },
      { "text": "Yemen", "value": "YE" },
      { "text": "Zambia", "value": "ZM" },
      { "text": "Zimbabwe", "value": "ZW" }
    ];
  return countries;
  }

}