export class User {
    firstName: string;
    lastName: string;
    role: string;
    userId: string;
    email: string;
    module: any;
    theme: any;
    image: any;
    username: any;
    language: string;
    languageId: any;
    roleId: any;
    aliasName:string;
    permissions: any;
    lastClearedNotificationsUTC: any;
    acceptPolicyUtc: any;
    pwdExpiryDurationLeft: any;
    lastLogin: any;
    bOneCustomerName: any;
    bOneCustomerLogo: any;
    fullName: string;
}
