import { apiData } from './../../../common';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Chart } from 'angular-highcharts';
import { Observable } from 'rxjs/internal/Observable';
import { PestColor } from './../reports/pestColor';

@Injectable({
  providedIn: 'root'
})
export class SensorService {

  constructor(private _commonDataService: CommonDataService) { }

  getSensorTypes(): Observable<sensorType.sensorTypeResponse> {
    let headers = new HttpHeaders();
    headers = headers.delete('content-type');
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.sensorTypes, headers).subscribe((sensorType: sensorType.sensorTypeResponse) => {
        if (sensorType.status === 'ok') {
          observer.next(sensorType);
          observer.complete();
        }
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // get sensor list
  getSensorsList(url = apiData.url + apiData.sensors.paginationUrl): Observable<sensors.SensorResponse> {
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((sensorResponse: sensors.SensorResponse) => {
        observer.next(sensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // GetMethod sensor list
   getMethodSensorsList(url): Observable<sensors.SensorResponse> {
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((sensorResponse: sensors.SensorResponse) => {
        observer.next(sensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // Get event options
  getSensorEventOptions(payLoad) {
    let url = apiData.url + apiData.sensors.sensorEventOptions;
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((sensorEvntOptResponse: any) => {
        observer.next(sensorEvntOptResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // get filter Sensors List
  getFilterSensorsList(url, payLoad) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((customerSensorResponse: sensors.SensorResponse) => {
        observer.next(customerSensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // get sensor status
  getSensorStatus(url = apiData.url + apiData.sensors.sensorsStatus): any {
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((sensorResponse: any) => {
        observer.next(sensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    }); 
  }

  getSensorsListNoPagination(url = apiData.url + apiData.sensors.defaultUrl + '?pageType=false'): Observable<sensors.SensorResponse> {
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((sensorResponse: sensors.SensorResponse) => {
        observer.next(sensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  saveSensor(sensor) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(sensor, apiData.url + apiData.sensors.sensorImage).subscribe((sensor) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    })
  }

  updateSensor(sensor, sensorId) {
    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(sensor, apiData.url + apiData.sensors.sensorImage + '/' + sensorId).subscribe((sensor) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getSensor(sensorId) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.sensor + '/' + sensorId).subscribe((sensor: sensors.SensorGetResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getCreatedByFullName(sensorId) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.createdFullName + '/' + sensorId).subscribe((fullName: any) => {
        observer.next(fullName);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getSensorIdDetails(sensorId){
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.getSensorInternalId + sensorId).subscribe((sensor: sensors.SensorGetResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  deleteSensor(sensorId) {
    return new Observable(observer => {
      this._commonDataService.deleteData(apiData.url + apiData.sensors.sensor + '/' , sensorId).subscribe((sensor) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  removeSensor(sensorId,sensorFlag) {
    return new Observable(observer => {
      const url = `${apiData.url}${apiData.sensors.sensor}/${sensorId}?faultySensor=${sensorFlag}`;
      this._commonDataService.removeSensor(url).subscribe((sensor) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }
  
  getSensorMetaData(): Observable<metaData.MetaDataResponse> {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.sensorMetaData).subscribe((sensor: metaData.MetaDataResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getErroredSensorList(body): Observable<sensors.SensorResponse> {
    return new Observable(observer => {
      this._commonDataService.postData(body, apiData.url + apiData.sensors.sensorFilter).subscribe((sensor: sensors.SensorResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getSensorNameIsExist(body, url) {
    return new Observable(observer => {
      this._commonDataService.postData(body, url).subscribe((sensorName: any) => {
        observer.next(sensorName);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getSensorMetaDataSingle(sensorId) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.sensorMetaDataSingle + '/' + sensorId).subscribe((sensor: metaData.MetaDataSensorResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getFilteredSensorsList(filter) {
    return new Observable(observer => {
      this._commonDataService.postData(filter, apiData.url + apiData.sensors.sensorFilter).subscribe((sensor: sensors.SensorResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  saveZoneMapping(zoneSensorMap) {
    return new Observable(observer => {
      this._commonDataService.postData(zoneSensorMap, apiData.url + apiData.sensors.mapping).subscribe((sensor: sensors.SensorMapperResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getCallGlueboardEventLog(sensorId) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.sensors.glueboardEventLog + sensorId).subscribe((evntLog: any) => {
        observer.next(evntLog);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getZoneDetailsData(id) {
    return new Observable(observer => {
      this._commonDataService.getData(apiData.url + apiData.zones.singleZoneUrl + '/' + id).subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  //Auto Disable zone live monitoring
  updateZoneDetailsData(data, zoneId) {
    let url = apiData.url + apiData.zones.singleZoneUrl + '/' + zoneId;
    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(data, url).subscribe((editCustomerResponse: any) => {
        observer.next(editCustomerResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getAllSettingRecords(body) {
    return new Observable(observer => {
      this._commonDataService.postData(body, apiData.url + apiData.settingTypeId).subscribe((sensor: sensors.SensorResponse) => {
        observer.next(sensor);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  replaceSensor(sensor) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(sensor, apiData.url + apiData.sensors.replaceSensor).subscribe((replaceSensorResp) => {
        observer.next(replaceSensorResp);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    })
  }

  getDirectionalChart(body) {
    return new Observable(observer => {
      this._commonDataService.postData(body, apiData.url + apiData.report.directional).subscribe((directionalData: directional.GetDirectionResponse) => {
        observer.next(directionalData);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // sensor detail screen
  getDirectionalChartData(body,urlEndpoint) {
    return new Observable(observer => {
      this._commonDataService.postData(body, apiData.url + apiData.sensors.directional + urlEndpoint).subscribe((directionalData: directional.GetDirectionResponse) => {
        observer.next(directionalData);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getDetectionLogEvents(body) {
    return new Observable(observer => {
      this._commonDataService.postData(body, apiData.url + apiData.sensors.detectionlogs).subscribe((detectionlogs: any) => {
        observer.next(detectionlogs);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getAQIChart(body) {
    let url = apiData.url + apiData.report.aqi;
    return new Observable(observer => {
      this._commonDataService.postData(body, url).subscribe((directionalData: directional.GetDirectionResponse) => {
        observer.next(directionalData);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getDailyCount(payload): Observable<any> {
    // payload["fromDate"] = "07/11/2019 00:00:00";
    // payload["toDate"] = "08/11/2019 23:59:59";
    delete payload.siteId;
    delete payload.zoneId;
    let url = apiData.url + apiData.report.flyCountJ
    return new Observable(observer => {
      this._commonDataService.postData(payload, url).subscribe((sensorResponse: any) => {
        observer.next(sensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getDailyCountTableData(payload): Observable<any> {
    // payload["fromDate"] = "07/11/2019 00:00:00";
    // payload["toDate"] = "08/11/2019 23:59:59";
    // delete payload.siteId;
    // delete payload.zoneId;
    let url = apiData.url + apiData.report.flyCountTableData
    return new Observable(observer => {
      this._commonDataService.postData(payload, url).subscribe((sensorResponse: any) => {
        observer.next(sensorResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // directionChart(data, themeValue)
  directionChart(data: directional.GetDirectionResponse, themeValue) {

    const chart = new Chart({
      chart: {
        polar: true,
        type: 'column',
        backgroundColor: themeValue.backgroundColor,
      },
      credits: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      title: {
        text: '',
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontFamily: "Poppins-Regular",
          fontSize: '20px'
        }
      },

      pane: {
        size: '85%'
      },

      legend: {
        reversed: true,
        align: 'right',
        verticalAlign: 'top',
        y: 100,
        layout: 'vertical'
      },

      xAxis: {
        tickmarkPlacement: 'on',
        categories: data.crawlDirectionalChart.categories,
        title: {
          text: '',
          style: {
            color: themeValue.textColor,
            fontFamily: "Poppins-Regular"
          }
        }, labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },

      yAxis: {
        min: 0,
        endOnTick: false,
        showLastLabel: true,
        // title: {
        //   text: 'Count',
        //   style: {
        //     color: themeValue.textColor,
        //     fontFamily: "Poppins-Regular"
        //   }
        // },
        labels: {
          enabled: false,
          // formatter: function () {
          //   return this.value + '';
          // },
          // style: {
          //   color: themeValue.textColor
          // }
        }
      },

      tooltip: {
        valueSuffix: '',
        followPointer: true,
        style: {
          color: themeValue.textColor,
          //fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          shadow: false,
          pointPlacement: 'on'
        }
      },
      series: [{
        type: 'column',
        color:  themeValue.lineColor,
        name: data.crawlDirectionalChart.name,
        groupPadding: 0,
        data: data.crawlDirectionalChart.data,
        showInLegend: false
      }],
      lang: {
        noData: themeValue.noData
      },
      noData: {
        style: {
          color: 'red',
          fontFamily: "Poppins-Regular",
          fontSize: themeValue.textSize
        }
      },
    })
    return chart;
  }

  dailyFlyCountChart(data, themeValue) {
    const chart = new Chart({
      chart: {
        backgroundColor: themeValue.backgroundColor,
        type: "area"
      },
      navigation: {
        buttonOptions: {
          enabled: true
        }
      },
      title: {
        text: themeValue.title,
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      xAxis: {
        categories: data.timestampStrDate,
        lineColor: themeValue.textColor,
        title: {
          text: themeValue.xAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }, labels: {
          // formatter: function () {
          //   return new Date(this.value).toLocaleDateString('en-GB') + '<br/>' + new Date(this.value).toLocaleTimeString('en-GB');
          // },
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      yAxis: {
        lineWidth: 0,
        gridLineColor: themeValue.textColor,
        lineColor: themeValue.textColor,
        title: {
          text: themeValue.yAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        },
        labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let tip = [];
          tip.push('');
          this.points.filter(e => {
            tip.push(e.series.name + ': <b style="color:' + e.color + ';"> ' + e.y +
              '</b><br> <b>' + new Date(e.x).toLocaleDateString('en-GB') + '</b><br>' + new Date(e.x).toLocaleTimeString('en-GB') + '<br>');
          });
          return tip;
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 3,
          states: {
            hover: {
              lineWidth: 4
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      legend:{
        itemStyle : {
          color : themeValue.textColor
        }
      },
      exporting: {
        enabled: true,
        filename: themeValue.title,
        buttons: {
          contextButton: {
            menuItems: ['downloadCSV'], //'downloadPNG', 'downloadPDF', 'separator',
            symbolStroke: themeValue.textColor,
            theme: {
              fill: themeValue.bgColor,
              states: {
                hover: {
                  fill: themeValue.bgColor
                },
                select: {
                  fill: themeValue.bgColor
                }
              }
            }

          }
        }
      },
      lang: {
        downloadPNG: themeValue.pngDownload,
        downloadPDF: themeValue.pdfDownload,
        downloadCSV: themeValue.csvDownload,
        contextButtonTitle: 'Export'
      },
      series: [{
        name: themeValue.labelFirst,
        fillOpacity: 0.9,
        opacity: 0.9,
        type: undefined,
        data: (function () {
          const finalSet = [];
          data.houseFlyData.map((i, index) => {
            finalSet.push([data.timestampStrDate[index], i])
          })
          return finalSet;
        }()),
        color: PestColor['HOUSEFLY'],
        showInLegend: true,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: false,
            color: themeValue.textColor
          }
        }
      },
      {
        name: themeValue.labelSec,
        fillOpacity: 0.7,
        opacity: 0.7,
        type: undefined,
        data: (function () {
          const finalSet = [];
          data.fruitFlyData.map((i, index) => {
            finalSet.push([data.timestampStrDate[index], i])
          })
          return finalSet;
        }()),
        color: PestColor['FLY'],
        showInLegend: true,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: false,
            color: themeValue.textColor
          }
        }
      }]
    });
  
    return chart;
  }

}