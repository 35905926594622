import { map } from 'rxjs/operators';
import { Directive, Input } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatGridList } from '@angular/material';
import { combineLatest } from 'rxjs/internal/operators/combineLatest';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

export interface IResponsiveColumnsMap {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@Directive({
  selector: '[appReponsiveGrid]'
})
export class ReponsiveGridDirective {
  @Input()
  appReponsiveGrid: any;
  private rid: IResponsiveColumnsMap = { xs: 2, sm: 2, md: 4, lg: 6, xl: 8 };
  private defaultGrid: IResponsiveColumnsMap = { xs: 2, sm: 2, md: 4, lg: 6, xl: 8 };

  public get cols(): IResponsiveColumnsMap {
    return this.defaultGrid;
  }

  @Input('responsiveCols')
  public set cols(map: IResponsiveColumnsMap) {
    if (map && ('object' === (typeof map))) {
      this.defaultGrid = map;
    }
  }

  public constructor(
    private grid: MatGridList,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  public ngOnInit(): void {
    this.initializeColsCount();
    // Small Screens
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         // console.log("Setting - "+ this.defaultGrid.xs)
          this.grid.cols = this.defaultGrid.xs
        }
      });
    // Tab Screens
    this.breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.HandsetLandscape])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //console.log("Setting - " + this.defaultGrid.sm)
          this.grid.cols = this.defaultGrid.sm
        }
      });
    // Medium Screens
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.TabletLandscape])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         // console.log("Setting - " + this.defaultGrid.md)
          this.grid.cols = this.defaultGrid.md
        }
      });
    // Large Screens
    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.WebPortrait])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         // console.log("Setting - " + this.defaultGrid.lg)
          this.grid.cols = this.defaultGrid.lg
        }
      });
    //Extra Large Screens
    this.breakpointObserver
      .observe([Breakpoints.XLarge, Breakpoints.WebLandscape])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //console.log("Setting - " + this.defaultGrid.xl)
          this.grid.cols = this.defaultGrid.xl
        }
      });
  }

  private initializeColsCount(): void {
    //   Object.keys(this.defaultGrid).some(
    //     (mqAlias: string): boolean => {
    //       const isActive = true;

    //       if (isActive) {
    //         this.grid.cols = this.defaultGrid[0];
    //       }
    //       return isActive;
    //     });
    // }
    if (this.appReponsiveGrid) {
      this.defaultGrid = this.appReponsiveGrid;
    }
  }
}