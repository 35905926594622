import { UserService } from './../../../users/user.service';
import { apiData } from './../../../../../common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/helpers/authentication.service';

@Component({
  selector: 'app-assign-activity-modal',
  templateUrl: './assign-activity-modal.component.html',
  styleUrls: ['./assign-activity-modal.component.scss']
})
export class AssignActivityModalComponent implements OnInit {
  fieldTechs: viewUser.Content[];
  constructor(private _authService: AuthenticationService, public dialogRef: MatDialogRef<AssignActivityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _userService: UserService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.getData();
  }

  getData() {

    this._userService.getFielTechData(apiData.url + apiData.user.defaultUrl, apiData.user.getFieldTechId).subscribe((res: viewUser.UserResponse) => {
      if (res.status = "success") {
        this.fieldTechs = res.data.content;
      } else {
        console.log("Field Tech Data Failed in assign activity modal component...");
      }
    }, error => {
      throw new Error(JSON.stringify(error));
    })

    // this._userService.getUserPaginated(apiData.url + apiData.user.defaultUrl + '?', apiData.user.getFieldTechId).subscribe((res: viewUser.UserResponse) => {
    //   if (res.status = "success") {
    //     this.fieldTechs = res.data.content;
    //   } else {

    //   }
    // }, error => {
    //   throw new Error(JSON.stringify(error));
    // })
  }
  changeFieldtech(event) {
    this.fieldTechs.filter(e => {
      if (e.userName == event.value) {
        this.data.id = e;
      }
    })
  }
  assignSelf() {
    this.data.id = this._authService.getUserName();
    this.dialogRef.close(this.data.id);
  }
}
