export const mapVal: mapSite.SiteOverView[] = [
    {
        "siteId": 16,
        "siteName": "Nippon Site",
        "siteType": null,
        "siteImageUrl": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/site/1567492972490-second_image.jpg",
        "customerId": 14,
        "customerName": "Nippon Customer",
        "customerLogo": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/customer/1567492671494-download.jpg",
        "locationLatitude": 34.669529,
        "locationLongitude": 135.497009,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 1
    },
    {
        "siteId": 346,
        "siteName": "名古屋港水族館",
        "siteType": null,
        "siteImageUrl": null,
        "customerId": 26,
        "customerName": "Calmic India Kolkata",
        "customerLogo": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/customer/1567495530479-magazine-unlock-05-2.3.1532-_0ce68b9dfedd4d7985a6b02f173797f4.jpg",
        "locationLatitude": 35.254311,
        "locationLongitude": 137.191321,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 1
    },
    {
        "siteId": 21,
        "siteName": "Allied Nippon",
        "siteType": null,
        "siteImageUrl": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/site/1568206468045-file.png",
        "customerId": 228,
        "customerName": "test",
        "customerLogo": null,
        "locationLatitude": 32.764233,
        "locationLongitude": 129.872696,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 1
    },
    {
        "siteId": 46,
        "siteName": "Test Site Android",
        "siteType": null,
        "siteImageUrl": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/site/1568206535201-file.png",
        "customerId": 228,
        "customerName": "test",
        "customerLogo": null,
        "locationLatitude": 12.8697073,
        "locationLongitude": 77.61754400000001,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 0
    },
    {
        "siteId": 351,
        "siteName": "Brandenburg Site Test 99 名古屋港水族館",
        "siteType": null,
        "siteImageUrl": null,
        "customerId": 353,
        "customerName": "gk",
        "customerLogo": null,
        "locationLatitude": 35.254311,
        "locationLongitude": 137.191321,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 0
    },
    {
        "siteId": 358,
        "siteName": "site 1",
        "siteType": null,
        "siteImageUrl": null,
        "customerId": 26,
        "customerName": "Calmic India Kolkata",
        "customerLogo": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/customer/1567495530479-magazine-unlock-05-2.3.1532-_0ce68b9dfedd4d7985a6b02f173797f4.jpg",
        "locationLatitude": 14.791882381211826,
        "locationLongitude": 19.775390625,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 0
    },
    {
        "siteId": 28,
        "siteName": "India Site Calmic 1",
        "siteType": null,
        "siteImageUrl": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/site/1568206965933-file.png",
        "customerId": 228,
        "customerName": "test",
        "customerLogo": null,
        "locationLatitude": 12.978174049864228,
        "locationLongitude": 77.58700398406984,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 0
    },
    {
        "siteId": 144,
        "siteName": "SIte",
        "siteType": null,
        "siteImageUrl": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/site/1567671446488-file.png",
        "customerId": 142,
        "customerName": "Customer",
        "customerLogo": "https://iop-uas.s3.ap-south-1.amazonaws.com/dev/customer/1567671416789-file.png",
        "locationLatitude": 13.198153609367349,
        "locationLongitude": 77.68994131792311,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 0
    },
    {
        "siteId": 368,
        "siteName": "Android Out Let 1",
        "siteType": null,
        "siteImageUrl": null,
        "customerId": 56,
        "customerName": "Android Customer",
        "customerLogo": null,
        "locationLatitude": 7.449624260197816,
        "locationLongitude": 16.962890625,
        "isSiteHasActiveEvent": false,
        "totalActiveEvent": 0,
        "totalNoOfSensor": 0
    }
];