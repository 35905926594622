import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTrim'
})
export class StringTrimPipe implements PipeTransform {

  transform(value: any) {
    if (!value) {
        return '';
    }
    return value.trim();
  }
}
