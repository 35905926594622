import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSign'
})
export class SignFilterPipe implements PipeTransform {

  transform(value: any) {
    const num = Math.round(value);
    return Math.abs(num);//null;
  }

}
