import { Component, Input, Renderer2, HostListener,  Directive, ElementRef, 
    TemplateRef, ViewContainerRef,  ContentChild, ComponentRef, QueryList } from 
    '@angular/core';
    
  @Directive({ selector: '[tooltipDirective]' })
  export class TooltipDirective {
    private tooltipId: string;
    
    constructor(private renderer: Renderer2, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) { }
  
    @Input() parametroPlantilla: TemplateRef<any>;
  
    @ContentChild( "tooltipTemplate", {static: false} ) private tooltipTemplateRef: TemplateRef<Object>;
  
    @HostListener('mouseenter') onMouseEnter(): void {    
      const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
      view.rootNodes.forEach(node => 
        this.renderer.appendChild(this.elementRef.nativeElement, node));
    }
    
    // @HostListener('click') onClick(): void {
     
    // }

    @HostListener('mouseleave') onMouseLeave(): void {        
      this.closeToolTip();
    }  

    ngOnDestroy(): void {
      this.closeToolTip();
    }
  
    private closeToolTip() {
      if (this.viewContainerRef) {
        this.viewContainerRef.clear();
      }  
    }

  }
    