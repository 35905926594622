import { Router, NavigationEnd, ActivatedRoute, Event, PRIMARY_OUTLET, Params } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { BreadCrumb } from './bread-crumb';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { SESSION_STORAGE, StorageService, LOCAL_STORAGE } from 'angular-webstorage-service';

export interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

const BREADCRUMB = 'breadcrumb';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {
  public breadcrumbs: IBreadcrumb[];
  storage: any
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _authService: AuthenticationService,
    @Inject(SESSION_STORAGE) private _storage: StorageService,
  ) {
    this.breadcrumbs = [];
    // this.breadcrumbs.push({
    //   label: 'Home',
    //   url: '/'
    // });
  }

  ngOnInit() {
    if (this._storage.get(BREADCRUMB)) {
      this.breadcrumbs = this._storage.get(BREADCRUMB)
    }
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root)

        for (const key in this.breadcrumbs) {
          this.breadcrumbs[key]['label'] = this.breadcrumbs[key]['label'].toLowerCase()
        }

        this._storage.set(BREADCRUMB, this.breadcrumbs)

      }
    });
    // subscribe to the NavigationEnd event
    // this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
    //   
    //   // set breadcrumbs
    //   const root: ActivatedRoute = this.activatedRoute.root;
    //   this.breadcrumbs = this.getBreadcrumbs(root);
    // });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property 'breadcrumb' is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url
      };
      if (breadcrumb.label !== '') {
        breadcrumbs.push(breadcrumb);
      }

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    // we should never get here, but just in case
    return breadcrumbs;
  }
}
