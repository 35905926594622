import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MapService } from '../map.service';
import { Status } from 'src/app/helpers/config/status';
import { Chart } from 'angular-highcharts';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { ProfileService } from 'src/app/modules/iop-module/profile/profile.service';
import { Router } from '@angular/router';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';

@Component({
  selector: 'app-map-slider',
  templateUrl: './map-slider.component.html',
  styleUrls: ['./map-slider.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        right: 'calc(0%)',
        opacity: 1
      })),
      state('closed', style({
        right: 'calc(-40% - -15px)',
        opacity: 1
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
  ]
})

export class MapSliderComponent implements OnInit, OnChanges {

  @Input() facilityId: any;
  @Input() getSiteId: number;
  @Input() getCustomerId: number;
  @Output() mapResizeEvent = new EventEmitter<boolean>();

  chart: Chart;
  storage: any;
  userName: string;
  themeId: string;
  themeValue = { 'bgColor': 'red', 'textColor': 'red', 'gridLineColor': 'red' };
  sensorTypeSpecies: string;
  sensorStatus: string;
  sliderPushClass: boolean = true;
  sensorsList: any;
  sliderState: string = 'closed';
  currentSpecies: number = 0;
  sensors = [{ name: 'fly', total: 8, current: 4, icon: 'Fly' }];
  sendPayLoadObj: any;
  sendFlyUrl: string;
  sendCrawlUrl: string;
  sensorStatusArray = [];
  customerName: string;
  siteName: string;
  siteAddress: string;
  siteImageUrl: string;
  moreDetailsLink: string;
  unSubscribeEvent: any;
  unSubscribeCount: any;
  unSubscribeStatus: any;
  unSubscribeInstallation: any;
  unSubscribeInstall: any;
  unSubscribeSite: any;
  unSubscribeFly: any;
  unSubscribeCockraoch: any;
  unSubscribeRodent: any;
  sentRandom : number;
  unSubscribeSensorStatusInit: any;

  constructor(private _mapService: MapService, private _authService: AuthenticationService, private _profileService: ProfileService, private _router: Router, private _commonDataService: CommonDataService) {

    this.unSubscribeEvent = this._commonDataService.eventFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.ngOnInit();
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeCount = this._commonDataService.countFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.ngOnInit();
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeInstall = this._commonDataService.sensorInstalledFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.ngOnInit();
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeSensorStatusInit = this._commonDataService.sensorStatusInitFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.ngOnInit();
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeSite = this._commonDataService.siteFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.siteid == this.getSiteId) {
        this.ngOnInit();
      } else if (!this.getSiteId) {
        this.ngOnInit();
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeStatus = this._commonDataService.statusFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.siteid == this.getSiteId) {
        this.getSensorList(this.getSiteId);
        this.sentRandom = Math.random();
      } else if (!this.getSiteId) {
        this.getSensorList(this.getSiteId);
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeInstallation = this._commonDataService.installationFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.siteid == this.getSiteId) {
        this.sensorStatusArray = [];
        this.sendFlyUrl = 'map/fly/alert/site/' + this.getSiteId;
        this.sendCrawlUrl = 'map/crawl/alert/site/' + this.getSiteId;
        this.sendPayLoadObj = {};
        this.sendPayLoadObj['siteId'] = this.getSiteId;
        this.sensorStatusArray.push('site/' + this.getSiteId + '?activityCategory=sensor activity&status=open');
        this.sensorStatusArray.push('site/' + this.getSiteId + '?activityCategory=New Sensor Installation&status=open');
        this.sentRandom = Math.random();
      } else if (!this.getSiteId) {
        this.sensorStatusArray = [];
        this.sendFlyUrl = 'map/fly/alert/sites';
        this.sendCrawlUrl = 'map/crawl/alert/sites';
        this.sendPayLoadObj = null;
        this.sensorStatusArray.push('sites?activityCategory=sensor activity&status=open');
        this.sensorStatusArray.push('sites?activityCategory=New Sensor Installation&status=open');
        this.sentRandom = Math.random();
      }

    })

    this.unSubscribeFly = this._commonDataService.flyFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.siteid == this.getSiteId) {
        this.sendPayLoadObj = {};
        this.sendFlyUrl = 'map/fly/alert/site/' + this.getSiteId;
        this.sendCrawlUrl = 'map/crawl/alert/site/' + this.getSiteId;
        this.sendPayLoadObj['siteId'] = this.getSiteId;
        this.sentRandom = Math.random();
      } else if (!this.getSiteId) {
        this.sendFlyUrl = 'map/fly/alert/sites';
        this.sendCrawlUrl = 'map/crawl/alert/sites';
        this.sendPayLoadObj = null;
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeCockraoch = this._commonDataService.cockroachFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.siteid == this.getSiteId) {
        this.sendFlyUrl = 'map/fly/alert/site/' + this.getSiteId;
        this.sendCrawlUrl = 'map/crawl/alert/site/' + this.getSiteId;
        this.sendPayLoadObj = {};
        this.sendPayLoadObj['siteId'] = this.getSiteId;
        this.sentRandom = Math.random();
      } else if (!this.getSiteId) {
        this.sendFlyUrl = 'map/fly/alert/sites';
        this.sendCrawlUrl = 'map/crawl/alert/sites';
        this.sendPayLoadObj = null;
        this.sentRandom = Math.random();
      }
    })

    this.unSubscribeRodent = this._commonDataService.rodentFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.siteid == this.getSiteId) {
        this.sendFlyUrl = 'map/fly/alert/site/' + this.getSiteId;
        this.sendCrawlUrl = 'map/crawl/alert/site/' + this.getSiteId;
        this.sendPayLoadObj = {};
        this.sendPayLoadObj['siteId'] = this.getSiteId;
        this.sentRandom = Math.random();
      } else if (!this.getSiteId) {
        this.sendFlyUrl = 'map/fly/alert/sites';
        this.sendCrawlUrl = 'map/crawl/alert/sites';
        this.sendPayLoadObj = null;
        this.sentRandom = Math.random();
      }
    })

  }

  ngOnInit() {

    this.getSiteId = this.getSiteId ? this.getSiteId : null;
    this.getSensorList(this.getSiteId);
    this.sensorStatusArray = [];
    if (this.getSiteId) {
      this.sendPayLoadObj = {};
      this.sendPayLoadObj['siteId'] = this.getSiteId;
      this.sendFlyUrl = 'map/fly/alert/site/' + this.getSiteId;
      this.sendCrawlUrl = 'map/crawl/alert/site/' + this.getSiteId;
      this.sensorStatusArray.push('site/' + this.getSiteId + '?activityCategory=sensor activity&status=open');
      this.sensorStatusArray.push('site/' + this.getSiteId + '?activityCategory=New Sensor Installation&status=open');
      this.sliderPushClass = true;
      this.mapResizeEvent.emit(true);
      this.getSiteDetails();
    } else {
      this.sendPayLoadObj = null;
      this.sendFlyUrl = 'map/fly/alert/sites';
      this.sendCrawlUrl = 'map/crawl/alert/sites';
      this.sensorStatusArray.push('sites?activityCategory=sensor activity&status=open');
      this.sensorStatusArray.push('sites?activityCategory=New Sensor Installation&status=open');
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.facilityId && changes.facilityId.currentValue) {
      this.getSiteId = this.getSiteId ? this.getSiteId : null;
      this.getSensorList(this.getSiteId);
      this.sensorStatusArray = [];
      if (this.getSiteId) {
        this.sendPayLoadObj = {};
        this.sendPayLoadObj['siteId'] = this.getSiteId;
        this.sendFlyUrl = 'map/fly/alert/site/' + this.getSiteId;
        this.sendCrawlUrl = 'map/crawl/alert/site/' + this.getSiteId;
        this.sensorStatusArray.push('site/' + this.getSiteId + '?activityCategory=sensor activity&status=open');
        this.sensorStatusArray.push('site/' + this.getSiteId + '?activityCategory=New Sensor Installation&status=open');
        this.sliderPushClass = true;
        this.mapResizeEvent.emit(true);
        this.getSiteDetails();
      } else {
        this.sendPayLoadObj = null;
        this.sendFlyUrl = 'map/fly/alert/sites';
        this.sendCrawlUrl = 'map/crawl/alert/sites';
        this.sensorStatusArray.push('sites?activityCategory=sensor activity&status=open');
        this.sensorStatusArray.push('sites?activityCategory=New Sensor Installation&status=open');
      }
    }
  }

  getRandNum(count){
    this.sentRandom = count;
  }

  // get site details
  getSiteDetails() {

    this._mapService.getSiteDetailData(this.getSiteId).subscribe(getResponse => {
      if (getResponse['status'] == Status.OK) {

        const siteObj = getResponse['site'];
        const customerIdObj = siteObj['customerId'];
        const customerId = customerIdObj['customerId'];
        this.customerName = customerIdObj['customerName'];
        this.siteImageUrl = siteObj['siteImageUrl'];
        this.siteName = siteObj['siteName'];
        this.siteAddress = siteObj['siteAddress'];
        // this.siteCity = siteObj['siteCity'];
        // this.siteState = siteObj['siteState'];
        // this.siteCountry = siteObj['siteCountry'];
        this.moreDetailsLink = '/home/customers/list/' + customerId + '/site/' + customerId + '/' + this.getSiteId + '/overView/' + customerId + '/' + this.getSiteId;

      } else {
        console.log("site id details error in status.Ok");
      }
    }, error => {
      console.log("site id details > ", error);
    });
  }

  // nav to more details
  onMoreDetails() {
    this._router.navigate([this.moreDetailsLink]);
  }

  // Silder Toggle
  onPushToggleSilder() {
    this.sliderPushClass = !this.sliderPushClass;
    this.mapResizeEvent.emit(this.sliderPushClass);
  }

  // getSensor List
  getSensorList(siteID) {

    this._mapService.getSensorLists(siteID).subscribe(getResponse => {

      if (getResponse['status'] == Status.OK) {

        const sensorData = getResponse['sensorStatusCounts'];
        const sensorListArray = [];
        for (const key in sensorData) {
          const keytoLowerCase = key.toLowerCase();
          let sensorStatus = '';
          let sensorAccess = '';
          switch (keytoLowerCase) {
            case "online":
              sensorStatus = "Normal";
              sensorAccess = "['SENSOR BAR','ONLINE SENSOR','VIEW']";
              break;
            case "offline":
              sensorStatus = "Error";
              sensorAccess = "['SENSOR BAR','SENSOR BAR - OFFLINE SENSOR','VIEW']";
              break;
            case "inactive":
              sensorStatus = "Inactive";
              sensorAccess =  "['SENSOR BAR',''SENSOR BAR - INACTIVE SENSOR'','VIEW']";
              break;
            default:
              sensorStatus = keytoLowerCase;
              break;
          }

          let queryObj = {};
          queryObj['sensorStatus'] = sensorStatus;
          if (this.getSiteId) {
            queryObj['customerId'] = this.getCustomerId;
            queryObj['siteId'] = this.getSiteId;
          }

          let createUrl = ""
          if (this.getSiteId) {
            createUrl = '/home/customers/list/' + this.getCustomerId + '/site/' + this.getCustomerId + '/' + this.getSiteId + '/sensors/' + this.getCustomerId + '/' + this.getSiteId;
          } else {
            createUrl = "/home/sensors";
          }

          sensorListArray.push({
            displayName: key.toLowerCase(),
            displayValue: sensorData[key],
            url: createUrl,
            queryUrl: queryObj,
            sensorAccess: sensorAccess
          });

        }

        this.sensorsList = sensorListArray;

      } else {
        console.log("=> status is failed on sensor list");
      }

    }, error => {
      console.log("error", error);
    });
  }

  // mouseHoverIn(item) {
  //   for (const key in this.chartFliterIcons) {
  //     if (this.chartFliterIcons[key]['name'] === item) {
  //       this.chartFliterIcons[key]['is_hover'] = true;
  //       this.sensorTypeSpecies = this.chartFliterIcons[key]['name'];
  //     } else {
  //       this.chartFliterIcons[key]['is_hover'] = false;
  //     }
  //   }
  // }
  // mouseHoverOut(item) {
  //   for (const key in this.chartFliterIcons) {
  //     if (this.chartFliterIcons[key]['name'] === item) {
  //       this.chartFliterIcons[key]['is_hover'] = false;
  //     } else {
  //       this.chartFliterIcons[key]['is_hover'] = false;
  //     }
  //   }
  // }

  // on sensor type clicked nav
  OnSensortype(url, queryUrl) {
    this._router.navigate([url], { queryParams: queryUrl });
  }

  ngOnDestroy() {
    if (this.unSubscribeEvent) {
      this.unSubscribeEvent.unsubscribe();
    }
    if (this.unSubscribeCount) {
      this.unSubscribeCount.unsubscribe();
    }
    if (this.unSubscribeStatus) {
      this.unSubscribeStatus.unsubscribe();
    }
    if (this.unSubscribeInstallation) {
      this.unSubscribeInstallation.unsubscribe();
    }
    if (this.unSubscribeSite) {
      this.unSubscribeSite.unsubscribe();
    }
    if (this.unSubscribeFly) {
      this.unSubscribeFly.unsubscribe();
    }
    if (this.unSubscribeCockraoch) {
      this.unSubscribeCockraoch.unsubscribe();
    }
    if (this.unSubscribeRodent) {
      this.unSubscribeRodent.unsubscribe();
    }
    if(this.unSubscribeSensorStatusInit){
      this.unSubscribeSensorStatusInit.unsubscribe();
    }
    if(this.unSubscribeInstall){
      this.unSubscribeInstall.unsubscribe();
    }
  }

}