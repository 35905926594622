import { ThemeService } from './helpers/services/theme.service';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SESSION_STORAGE, StorageService, LOCAL_STORAGE } from 'angular-webstorage-service';
import { ProfileService } from './modules/iop-module/profile/profile.service';
import { AuthenticationService } from './helpers/authentication.service';
import { englishTranslate } from './classModel/enTranslate';
import { japaneseTranslate } from './classModel/jpTranslate';
import { spanishTranslate } from './classModel/esTranslate';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
import {  Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { environment } from './../environments/environment';
// import * as S3 from 'aws-sdk/clients/s3';
// import * as AWS from "aws-sdk";
import { apiData } from './common';
import { CommonDataService } from './helpers/services/common-data.service';
import { LoginService } from './modules/login/login.service';
// import { HttpClient } from '@angular/common/http';

const USER = 'user';
const ACCESS_TOKEN = 'ACCESS_TOKEN';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'iop-v2';
  themes = ['dark', 'calmic', 'light', 'calmicRed', 'default'];
  userName: string;
  language: string;
  storage: any;
  private currentBuild = apiData.currentBuild;
  // private themeWrapper = document.querySelector('body');

  // getAccessKey : any;
  // getSecretAccessKey: any;
  // getSessionToken: any;

  constructor(private _commonDataService: CommonDataService,private _loginService: LoginService, private _router: Router,private _themeService: ThemeService, private _translationService: TranslateService, private _profileService: ProfileService, private _authService: AuthenticationService, @Inject(SESSION_STORAGE) private _storage: StorageService, @Inject(LOCAL_STORAGE) private _localStorage: StorageService) {

    this.storage = this._authService.toggleStorage();
    let getCValue = this._authService.getCookie("iop.rememberme");

    if (getCValue) {
      let accessToken = this.storage.get(ACCESS_TOKEN);
      if(accessToken == null) {

        this._router.navigate(['./']);

      } else {

        let obj = {};
        let userName = localStorage.getItem('userName');
        let encryptPassword = localStorage.getItem('password');
        let decryptPassword = window.atob(encryptPassword);
        obj['username'] = userName;
        obj['password'] = decryptPassword;

        this._loginService.getOauth(obj).subscribe((res: any) => {

          if (res.access_token) { 
            this._authService.startSession(res);
            this._router.navigate(['/home']);
          } else {
            this._router.navigate(['./']);
          }

        }, error => {
          this._router.navigate(['./']);
        })

      }
    } 

    // Set Default Theme
    if (this._authService.isLoggedIn()) {

      this._translationService.setTranslation('en', englishTranslate);
      this._translationService.setTranslation('jp', japaneseTranslate);
      this._translationService.setTranslation('es', spanishTranslate);
      this._translationService.setTranslation('en-US', englishTranslate);
      this._translationService.setTranslation('ja-JP', japaneseTranslate);
      this._translationService.setTranslation('es-SP', spanishTranslate);
      this._translationService.use(JSON.parse(this.storage.get(USER)).language);
      this._themeService.changeTheme(JSON.parse(this.storage.get(USER)).theme);
      
    } 
  

    this._translationService.onLangChange.subscribe((event: LangChangeEvent) => {
      //console.log("Onchange" + event.lang)
      //   if (event.lang === 'en') {
      //     //Call Api and set Object
      //     console.log(event.lang)
      //     // this._translationService.setTranslation('en',{})
      //     this._translationService.setTranslation('en', englishTranslate);
      //     this._translationService.use('en');
      //     this._translationService.use('jp');
      //     this._translationService.use('en');
      //   } else if(event.lang === 'jp') {
      //     console.log(event.lang)
      //     // this._translationService.setTranslation('jp',{})
      //     this._translationService.setTranslation('jp', japaneseTranslate);
      //     this._translationService.use('en');
      //     this._translationService.use('jp');
      //     this._translationService.use('en');
      //     this._translationService.use('jp');
      //   }
    })

  }

  ngOnInit() { 

    this._router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {

        const hostURL = window.location.href;

        if(hostURL.indexOf("localhost") < 0) {

          // this.callcerdential();
          this.callS3();
        
        }

      }

    });
    
  }

  // async callcerdential() {
  //   var myCredentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: environment.url.identityPoolId});
  //   await AWS.config.update({region: environment.url.region, credentials: myCredentials});
  //   AWS.config.getCredentials((err) => {
  //     if (err) {
  //       // credentials not loaded
  //       console.log(err.stack);
  //     } else {

  //       const getCredential = AWS.config.credentials;
  //       this.getAccessKey = AWS.config.credentials.accessKeyId;
  //       this.getSecretAccessKey = AWS.config.credentials.secretAccessKey;
  //       this.getSessionToken = AWS.config.credentials.sessionToken;
  //       // console.log("Access key: >> ", this.getAccessKey, this.getSecretAccessKey);
  //       this.buildVersionNo(getCredential);

  //     }
  //   });
  // }
  
  // async buildVersionNo(getCredential) {

  //   const params = {
  //     Bucket: environment.url.bucketName,
  //     Key: environment.url.fileName
  //   };

  //   const bucket = new S3({
  //     accessKeyId :  this.getAccessKey,
  //     secretAccessKey: this.getSecretAccessKey,
  //     sessionToken: this.getSessionToken,
  //     region: environment.url.region
  //     // apiVersion: '2006-03-01'
  //   });

  //  await bucket.getObject(params, (err, data)=> {
  //     if (err) {
  //       console.error("s3 err >>", err); // an error occurred
  //     } 
  //     if(data) {
        
  //       let getData = new Uint8Array(<ArrayBuffer>data['Body']).subarray(0);
  //       let string = new TextDecoder('utf-8').decode(getData);
  //       let response = JSON.parse(string);
  //       // console.log("response >>",  response['jenkins_build'])

  //       const respBuild = response['jenkins_build'] ? response['jenkins_build'] : null;
  //       const flag = response['jenkins_build'] ? true : false;
  //       const versionChanged = this.hasHashChanged(this.currentBuild, respBuild);
  //       if (versionChanged && flag) {
  //         location.reload();
  //       }

  //     }
  //   });
  // }

  async callS3() {
    this._commonDataService.getBuildVersion(environment.url.jenkinsBuildNumUrl).subscribe(
      (response: any) => {

        const respBuild = response['jenkins_build'] ? response['jenkins_build'] : null;
        const flag = response['jenkins_build'] ? true : false;
        const versionChanged = this.hasHashChanged(this.currentBuild, respBuild);
        if (versionChanged && flag) {
          location.reload();
        }
      },
      (error) => {
        console.error('Error fetching file:', error);
      }
    );
  }

  private hasHashChanged(currentBuild, respBuild) {
    // console.log("currentBuild, newHash >>> ", currentBuild, respBuild)
    if (!currentBuild || currentBuild === respBuild || currentBuild > respBuild) {
      return false;
    } else {
      return currentBuild !== respBuild;
    }
    
  }

  // callLoginAPIs() {

  //   this._loginService.login(this.finalLoginForm).subscribe((loginRes: any) => {
  //     if (loginRes.status === 'ok') {

  //       this._authService.saveLoginDetails(loginRes);
  //       this._loginService.getAuth(this.loginForm).subscribe((res: any) => {
  //         if (res.access_token) {

  //           sessionStorage.removeItem('siteTabNo');
  //           sessionStorage.removeItem('sensorTabNo');
  //           sessionStorage.removeItem('detectionFilter');
  //           sessionStorage.removeItem('profileTabNo');
  //           sessionStorage.removeItem('breachTabNo');
  //           localStorage.removeItem(`iop_notification_${this.loginForm.get('userName').value}`);
  //           this._authService.startSession(res, this.loginForm.get('rememberMe').value);
        
  //           this._commonDataService._sendUserDetails(loginRes);

  //           // Password Expiry
  //           let localTime = new Date();
  //           let localTimestamp = localTime.getTime();
  //           let difference = loginRes.user.pwdExpiryDurationLeft - localTimestamp;
  //           let checkNagtiveValue = Math.sign(difference);
        
  //           console.log("pwdExpiryDurationLeft >> ", loginRes.user.pwdExpiryDurationLeft);
  //           console.log("checkNagtiveValue >> ", checkNagtiveValue);
  //           if(loginRes.user.lastLogin == null) {

  //             this._router.navigate(['/changePassword']);

  //           } else if(checkNagtiveValue >= 0 || loginRes.user.pwdExpiryDurationLeft == null) {
 
  //             setTimeout(()=>{   
  //               this._router.navigate(['/home']);
  //             }, 600);

  //           } else {

  //             this._router.navigate(['/changePassword']);

  //           }

  //         } 
  //       }, error => {

  //         console.log(loginRes.message);
  //         this._authService.loggedOut();
  //         throw new Error(JSON.stringify(error));
          
  //       });

  //     } else {
  //       console.log(loginRes.message);
  //       this._authService.loggedOut();
  //     }
  //   }, error => {
  //     console.log(error);
  //     this._authService.loggedOut();
  //   });

   
  // }

}