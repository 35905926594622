import { PermissionFinderService } from './../services/permission-finder.service';
import { Directive, OnInit, OnDestroy, ViewContainerRef, TemplateRef, Input } from '@angular/core';
// import { WorkflowEvents } from './workflow-events.service'
@Directive({
  selector: '[appCanAccess]'
})
export class CanAccessDirective {
  @Input('appCanAccess') appCanAccess: string | string[];
  private permission$: any;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private workflowEvents: PermissionFinderService) { }

  ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    this.permission$ = this.workflowEvents
      .checkAuthorization(this.appCanAccess)
      .subscribe(authorized => {
        if (authorized) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
          // this.viewContainer.remove();
          // this.viewContainer.detach();
        }
      });
  }

  ngOnDestroy(): void {
    if(this.permission$){
      this.permission$.unsubscribe();
    }
  }

}