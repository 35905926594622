import { PermissionFinderService } from './../../../helpers/services/permission-finder.service';
import { PaginatorComponent } from './../../component/paginator/paginator.component';
import { MessageBoxService } from './../../component/message-box/message-box.service';
import { AssignActivityModalComponent } from './../../../modules/iop-module/activities/perform-activity/assign-activity-modal/assign-activity-modal.component';
import { PerformModalComponent } from './../../../modules/iop-module/activities/perform-activity/perform-modal/perform-modal.component';
import { MapFacilityViewComponent } from './../../component/map/map-facility-view/map-facility-view.component';
import { ReponsiveGridDirective } from './../../../helpers/directives/reponsive-grid.directive';
import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as heatmap from 'highcharts/modules/heatmap';
import * as dumbbell from 'highcharts/modules/dumbbell';
import * as HichartsExporting from 'highcharts/modules/exporting';
import * as HichartsExportingData from 'highcharts/modules/export-data';
import * as More from 'highcharts/highcharts-more';
import * as NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { Daterangepicker } from 'ng2-daterangepicker';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { UseSharedComponentModule } from '../use-shared-component/use-shared-component.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializerQ } from 'src/app/helpers/customUrlSerializer';
import { SignFilterPipe } from '../../pipe/sign-filter.pipe';
import { StringTrimPipe } from '../../pipe/string-trim.pipe';
import { MAT_TABS_CONFIG } from '@angular/material';
// import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'MM/YYYY',
//   },
//   display: {
//     dateInput: 'MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@NgModule({
  declarations: [
    ReponsiveGridDirective,
    // MapComponent,
    // MapSliderComponent,
    PaginatorComponent,
    // NavigateItemsComponent,
    MapFacilityViewComponent,
    // MessageBoxComponent,
    AssignActivityModalComponent,
    PerformModalComponent,
    SignFilterPipe,
    StringTrimPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    // MaterialModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule,
    ChartModule,
    ZXingScannerModule,
    Daterangepicker,
    DragulaModule,
    UseSharedComponentModule,
    FullCalendarModule,
    TranslateModule
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // MaterialModule,
    TranslateModule,
    ReponsiveGridDirective,
    // MapComponent,
    PaginatorComponent,
    // NavigateItemsComponent,
    NgMultiSelectDropDownModule,
    ChartModule,
    ZXingScannerModule,
    Daterangepicker,
    DragulaModule,
    // SensorDonutChartComponent,
    UseSharedComponentModule,
    FullCalendarModule,
    SignFilterPipe,
    StringTrimPipe
   ],
  providers: [
    MessageBoxService,
    TitleCasePipe,
    DragulaService,
    PermissionFinderService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [heatmap, HichartsExporting, HichartsExportingData, More, dumbbell,NoDataToDisplay] },
    { provide: UrlSerializer, useClass: CustomUrlSerializerQ },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }
    
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  entryComponents: [
    // MessageBoxComponent,
    AssignActivityModalComponent,
    PerformModalComponent,
    ]
})
export class SharedModule { }