import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { Chart } from 'angular-highcharts';
import { apiData } from './../../../common';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _commonDataService: CommonDataService) { }

  // Acc Overview
  getAccountOverview(url, payLoad): Observable<any> {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((sensorOverview: any) => {
        observer.next(sensorOverview);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // Sensors Overview
  getSensorsOverview(url, payLoad) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((sensorOverview: any) => {
        observer.next(sensorOverview);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // pestDetection Charts
  getpestDetectionCharts(url, payLoad) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((sensorOverview: any) => {
        observer.next(sensorOverview);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

   // Jobs Charts
   getJobsCharts(url, payLoad) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((sensorOverview: any) => {
        observer.next(sensorOverview);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  sensorDonutChart(data, themeValue, colorCode) {
    const hightChart = new Chart({
      chart: {
        renderTo: 'container',
        type: 'pie'
      },
    /* title: {
        text: ''
    },  */           
    plotOptions: {
      pie: {
        innerSize: '98%',
        borderWidth: 4,
        borderColor: null,
        slicedOffset: 20,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          connectorWidth: 0,
          //color: '#000000',
          //connectorColor: '#000000',
          formatter: function() {
            return '<br><span>'+ this.point.name +'</span></br> <br><b>'+ this.point.x + '</b><br/>';
          },
          style: {
            //fontWeight: 'normal',
            fontFamily: "Poppins-Regular",
            color: themeValue.textColor,
            fontSize: themeValue.textSize
          }
        }
      }
    },
    exporting: { enabled: false },
    credits: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      useHTML: true,
      itemStyle: {
        color: themeValue.textColor,
        fontSize: themeValue.textSize,
        fontFamily: "Poppins-Medium",
      }
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: themeValue.centerText,
      align: 'center',
      style: {
        color: themeValue.textColor,
        //fontWeight: 'bold',
        fontSize: themeValue.textSize,
        fontFamily: "Poppins-Medium"
      }
    },
    tooltip: {
      //enabled: false
      formatter: function() {
        return `${this.point.name} : <b>${this.point.x}</b> <br><b>${this.y}%</b></br>`;
        // return '<br>'+ this.point.name +'</br>: <b>'+ this.y+' % </b>'; // + '<br> Count </br>: '+ this.point.x;
      },
      style: {
        color: themeValue.textColor,
        fontSize: themeValue.textSize,
        fontFamily: "Poppins-Regular"
      }
    },
      series: [{
          type: undefined,
          name: 'Item',
          data: data,
          // size: '50%',
          // innerSize: '70%',
          size: '70%',
          innerSize: '80%',
          showInLegend: false,
          colors: colorCode,
          dataLabels: {
            enabled: false,
            softConnector: false,
            style: {
              fontWeight: 'normal',
              fontSize: themeValue.textSize,
              fontFamily: "Poppins-Medium",
              color: themeValue.textColor
            }
          }
      }]
    });
    return hightChart;
  }

  donutChart(data, themeValue, colorCode) {
    const hightChart = new Chart({

      chart: {
        renderTo: 'container',
        type: 'pie'
      },
    /* title: {
        text: ''
    },  */           
    plotOptions: {
      pie: {
        innerSize: '98%',
        borderWidth: 4,
        borderColor: null,
        slicedOffset: 20,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          //color: '#000000',
          //connectorColor: '#000000',
          formatter: function() {
            return '<br><span>'+ this.point.name +'</span></br> <br><b>'+ this.point.x + '</b><br/>';
          },
          style: {
            //fontWeight: 'normal',
            fontFamily: "Poppins-Regular",
            color: themeValue.textColor,
            fontSize: themeValue.textSize
          }
        }
      }
    },
    exporting: { enabled: false },
    credits: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      useHTML: true,
      itemStyle: {
        color: themeValue.textColor,
        fontSize: themeValue.textSize,
        fontFamily: "Poppins-Medium",
      }
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: themeValue.centerText,
      align: 'center',
      style: {
        color: themeValue.textColor,
        fontSize: themeValue.textSize,
        fontFamily: "Poppins-Medium"
      }
    },
    tooltip: {
      //enabled: false
      formatter: function() {
        return `${this.point.name} : <b>${this.point.x}</b> <br><b>${this.y}%</b></br>`;
        // return '<br>'+ this.point.name+'</br>: <b>'+ this.y +' % </b>'; //+ '<br> Count </br>: '+ this.point.x;
      },
      style: {
        color: themeValue.textColor,
        fontSize: themeValue.textSize,
        fontFamily: "Poppins-Regular",
        // backgroundColor : "#525E61DD"
      }
    },
      series: [{
          type: undefined,
          name: 'Item',
          data: data,
          size: '70%',
          innerSize: '80%',
          showInLegend:false,
          colors: colorCode,
          dataLabels: {
            enabled: false,
            softConnector: true,
            style: {
              fontWeight: 'normal',
              fontSize: themeValue.textSize,
              fontFamily: "Poppins-Medium",
              color: themeValue.textColor
            }
          }
      }]
    });
    return hightChart;
  }

  // notification list
  gettingNotificationList(body, url): Observable<any> {
    return new Observable(observer => {
      this._commonDataService.postData(body, url).subscribe((notificationRes: any) => {
        observer.next(notificationRes);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
    // return new Observable(observer => {
    //   this._commonDataService.getData(url).subscribe((notificationRes: any) => {
    //     observer.next(notificationRes);
    //     observer.complete();
    //   }, error => {
    //     throw new Error(JSON.stringify(error));
    //   });
    // });
  }

  // pestDetection Charts
  notificationClearAll(url, payLoad) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((clearAll: any) => {
        observer.next(clearAll);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // notification count
  getNotificationCount(url): Observable<any> {
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((notificationRes: any) => {
        observer.next(notificationRes);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getNotificationEventOptions(url,payload){
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payload, url).subscribe((clearAll: any) => {
        observer.next(clearAll);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }


  // Live monitoring
  getZoneLiveMonitoring(url, payLoad) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payLoad, url).subscribe((zoneLiveMonitoring: any) => {
        observer.next(zoneLiveMonitoring);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

}