import { Component, OnInit } from '@angular/core';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { Router } from '@angular/router';
import { Status } from 'src/app/helpers/config/status';
import { apiData } from 'src/app/common';
import { DashboardService } from '../../dashboard.service';
import { MessageBoxService } from 'src/app/shared/component/message-box/message-box.service';

const CLEARUTC = 'clearUTC';

@Component({
  selector: 'app-notification-slider',
  templateUrl: './notification-slider.component.html',
  styleUrls: ['./notification-slider.component.scss']
})

export class NotificationSliderComponent implements OnInit {

  notificationArray : any;
  notificationFlag : boolean = false;
  paginatorQuery = '?page=0&size=25';
  notificationList: any;
  totalCounts: number = 0;
  clearPopup: boolean = false;
  successMsgFlag: boolean = false;
  storage: any;

  //base_url = './../../../../../../assets/images/iconSet/';
  unSubscribeNotification: any;
  detectiosBoxFlag: boolean = false;
  siteBoxFlag: boolean = false;
  sensorBoxFlag: boolean = false;

  constructor(private _messageBoxService: MessageBoxService,private _dashboardService: DashboardService,private _commonDataService: CommonDataService,private _router: Router, private _authService: AuthenticationService) {
    
   }

  ngOnInit() {

  //   const notificationList = JSON.parse(localStorage.getItem(`iop_notification_${this._authService.getUserName()}`));
  //   const notifictaionArray = notificationList ? notificationList['list'] : null;
  //   this.notificationArray = notifictaionArray ? notifictaionArray : [];
  //   this.notificationFlag = notifictaionArray ? true : false;

  //  // notification count
  //   this.unSubscribeNotification = this._commonDataService.notificationCount.subscribe(responseCount=>{
     
  //     const notificationList = JSON.parse(localStorage.getItem(`iop_notification_${this._authService.getUserName()}`));
  //     const notifictaionArray = notificationList ? notificationList['list'] : null;
  //     this.notificationArray = notifictaionArray ? notifictaionArray : [];
  //     this.notificationFlag = notifictaionArray ? true : false;

  //   },error =>{
  //     console.log("Error Notification count >>>",error);
  //   });

    this.storage = this._authService.toggleStorage();
    this.unSubscribeNotification = this._commonDataService.silentNotificationFlag.subscribe(responseCount=>{
      
      this.getNotifCount();
      this.getNotificationlist();

    },error =>{
      console.log("Error Silent Notification >>>",error);
    });

    this.detectiosBoxFlag = this._authService.getPermission(['Detections', '2', 'View']);
    this.siteBoxFlag = this._authService.getPermission(['Sites', '17', 'View']);
    this.sensorBoxFlag = this._authService.getPermission(['Sensors', '4', 'View']);

    this.getNotifCount();
    this.getNotificationlist();

  }

  getNotifCount() {
    let url = apiData.url + apiData.home.notificationCount;
    this._dashboardService.getNotificationCount(url).subscribe((res: any) => {
      this._commonDataService._sendNotificationCount(res);
      if (res.status = Status.OK) {
        this.totalCounts = res.PopupNotificationsCount;
      }
    });
  }

  getNotificationlist() {

    let sendUrl  = apiData.url + apiData.home.notificationCenter + this.paginatorQuery + '&sort=createUTC,desc';
    let obj = {popupFlag: true};

    this._dashboardService.gettingNotificationList(obj,sendUrl).subscribe((notificationResponse: any) => {
      if (notificationResponse.status === Status.OK) {

        const notificationlist = notificationResponse.events.content;
        notificationlist.map(e => {
          return e['createUTC'] = this._commonDataService.getCurrentTimeDiff(e.createUTC);
        });

        this.notificationList = notificationlist;
        // this.notificationList = notificationResponse.data.content;
        // if (Object.keys(this.notificationList).length > 0) {

        //   this.showNotificationList = true;
        //   this.notificationData = notificationResponse.events;
         
        // }else {

        //   this.showNotificationList = false;
        //   this.notificationData = null;

        // }
      } else {
        console.log("Error Message Notification List >>", notificationResponse.message);
      }
    }, error => {
      console.log("Error Notification List >>", error);
    })

  }

  // onSumbitClearAll() {

  //   let url = apiData.url + apiData.home.notificationClearAll;
  //   let payload = {};
  //   this._dashboardService.notificationClearAll(url,payload).subscribe((res: any) => { 
  //     if (res.status === Status.OK) { 

  //       this.successMsgFlag = true;
  //       localStorage.removeItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`);
  //       this.storage.set(CLEARUTC, res.lastClearedNotificationsUTC);
  //       this._commonDataService.clearedAllRemoveRedDotFlag.emit(true);
  //       this._commonDataService.reloadNotificationCenterFlag.emit(true);
  //       this._commonDataService._sendNotificationFlag(false);
  //       setTimeout(()=>{ 
  //         this.onCloseCancel();
  //       }, 3000);
        
  //     } else {
  //       this._messageBoxService.openMessageBox('alert', res.message).subscribe(() => {
          
  //       });
  //     }

  //   },error => {
  //     console.log("Error Notification Clear All >>", error);
  //   })
    
  // }

  // onOpenClear() {
  //   this.successMsgFlag = false;
  //   this.clearPopup = true;
  // }

  // onCloseCancel() {
  //   this.clearPopup = false;
  // }

  onNotificationNav(getUrl) {
    this._router.navigate([getUrl]);
  }

  onAllNotificationNav(){
    this._commonDataService._sendNotificationFlag(false);
  }

  onNavDetectionResolved(activityId) {
    if(this.detectiosBoxFlag) {
      this._router.navigate(['/home/detections/detail/'+activityId]);
    }
  }

  onNavSiteDetectionTab(siteId, eventTypeId) {
    if(this.siteBoxFlag) {
      sessionStorage.setItem('detectionFilter', eventTypeId);
      sessionStorage.setItem('siteTabNo', 'Detections');
      this._router.navigate(['/home/sites/detail/'+siteId]);
    }
  }

  onNavSiteEventTab(siteId) {
    if(this.siteBoxFlag) {
      sessionStorage.setItem('siteTabNo', 'Site Events Log');
      this._router.navigate(['/home/sites/detail/'+siteId]);
    }
  }

  onNavSensorDetails(sensorId,displaySensorAlphaId) {
    if(this.sensorBoxFlag && displaySensorAlphaId != 'Unavailable') {
      const url = `/home/sensors/detail/${sensorId}`;
      sessionStorage.setItem('sensorTabNo', '0');
      this._router.navigateByUrl(url);
    }
  }

  onNavZoneCcl(siteId) {
    if(this.siteBoxFlag) {
      sessionStorage.setItem('siteTabNo', 'Zones');
      this._router.navigate(['/home/sites/detail/'+siteId]);
    }
  }

  ngOnDestroy() { 
    if (this.unSubscribeNotification) {
      this.unSubscribeNotification.unsubscribe();
    }
  }

}