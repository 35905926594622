import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MessageBoxComponent } from './message-box.component';
import { TitleCasePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(public dialog: MatDialog, private _titlecasePipe: TitleCasePipe) { }
  openMessageBox(messageType, message?, type = ''): Observable<any> {
    let messageEncoded;

    if (type = 'Calendar') {
      messageEncoded = message;
    } else if (type = 'aqi') {
      messageEncoded = message;
    } else if(type ='Sensor'){
      messageEncoded = message;
    }else {
      messageEncoded = this._titlecasePipe.transform(message) + type;
    }

    //console.log(messageEncoded);

    const self = this;
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      width: '450px',
      data: {
        type: messageType,
        message: messageEncoded
      },
      disableClose: true
    });
    dialogRef.updatePosition({ top: '10%' });
    return dialogRef.afterClosed();
  }
  decodeMessage() {

  }
}
export enum MessageDefinition {
  DEFAULT = '',
  DELETE = 'deleted successfully',
  CREATE = 'created successfully',
  UPDATE = 'updated successfully',
  SENSORINSTALL = 'successfully sensor installed',
  COMPINSTALL = 'sensor(s) in the list were added successfully',
  SENSOREDIT = 'sensor updated successfully',
  CUSTOMROLECREAT = 'custom role created successfully',
  CUSTOMROLEEDIT = 'custom role edited successfully',
  EDIT = 'edited successfully',
  REASSIGN = 're assigned successfully',
  PASSWORDRESET = 'Your password has been changed successfully! Please login with your new password.',
  USERCREATED = 'user successfully created.',
  USERUPDATED = 'user successfully updated.',
  ZONECREATED = 'zone successfully created.',
  ZONEUPDATED = 'zone successfully updated.',
  SITECREATED = 'site successfully created.',
  SITEUPDATED = 'site successfully updated.',
  CUSTOMERUPDATED = 'customer successfully updated.',
  CUSTOMERCREATED = 'customer successfully created.',
  DELETEMESSAGE = 'do you want to remove ',
  CANCELSENSORMESSAGE = 'are you sure want to cancel it ?',
  REMOVEZONEMAP = "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?",
  ZONEMAPUPDATED = 'zone map successfully updated.'
}