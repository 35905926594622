import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { englishTranslate } from 'src/app/classModel/enTranslate';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { LoginService } from 'src/app/modules/login/login.service';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  message: any;
  messageType: any;
  getCustomerId: any;
  storage: any;
  userName: string;
  staticMsg: any;
  dynamicMsg: any;
  messageFlag: boolean = false;

  constructor(public dialogRef: MatDialogRef<MessageBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _authService: AuthenticationService,private _loginService: LoginService) { }

  ngOnInit() {

    const message = this.data.message;
    this.messageType = this.data.type;

    if(this.messageType === 'confirm' && message.indexOf("#") > -1){

      this.messageFlag = true;
      const splitArray = message.split("#");
      const staticMsg = splitArray[0];
      this.staticMsg = staticMsg.trim();
      this.dynamicMsg = splitArray[1];

    }else {

      this.messageFlag = false;
      this.message = this.data.message ? this.data.message : 'Are You Sure?';

      if(this.messageType === 'alert') { 
        setTimeout(()=>{                           
          this.dialogRef.close();
        }, 2900);
      }

      if(this.messageType === 'changePassword') {
        setTimeout(()=> {   
          this.dialogRef.close(); 
          this._loginService.logOut(this._authService.getUserName()).subscribe((res: any) => {
            this._authService.loggedOut();
          }, error => {
            this._authService.loggedOut();
            throw new Error(error);
          })
        }, 4000);
      }
    }

  }

  onYesClick(): void {
    this.data = true;
    this.dialogRef.close();
    // this._messageService.output.emit(true);
  }

  onNoClick(): void {
    this.data = false;
    this.dialogRef.close();
    // this._messageService.output.emit(false);
  }
  
  onInfoClick(): void {
    this.dialogRef.close();
  }

  createSite() {
    this.data = 'add site';
    this.dialogRef.close(this.data);
  }

  showCustomerList() {
    this.data = 'show customer list';
    this.dialogRef.close(this.data);
  }

  createZone() {
    this.data = 'add zone';
    this.dialogRef.close(this.data);
  }

  showSiteList() {
    this.data = 'show site list';
    this.dialogRef.close(this.data);
  }

  showZoneList() {
    this.data = 'show zone list';
    this.dialogRef.close(this.data);
  }

  yes() {
    this.data = 'yes';
    this.dialogRef.close(this.data);
  }

  no() {
    this.data = 'no';
    this.dialogRef.close(this.data);
  }

  cancel(){
    this.data = 'no';
    this.dialogRef.close(this.data);
  }

  changePassword() {
    this.data = 'loggedOut';
    this.dialogRef.close(this.data);
  }

  CloseInfo(){
    this.dialogRef.close(this.data);
  }

  onChangeCustomer(event){
    this.getCustomerId = event.value;
  }

  yesRemove() {
    this.data = { status: 'yes', bOneCustomerId : this.getCustomerId };
    this.dialogRef.close(this.data);
  }

}