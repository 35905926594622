import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-map-facility-view',
  templateUrl: './map-facility-view.component.html',
  styleUrls: ['./map-facility-view.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        // right: 'calc(0%)',
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        // right: 'calc(100%)',
        opacity: 0,
        display: 'none'
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ]
})
export class MapFacilityViewComponent implements OnInit, OnChanges {
  @Input() facilityId: any;
  sliderState: string = 'closed';
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.facilityId && changes.facilityId.currentValue) {
      this.openSlider();
    }
  }
  openSlider() {
    this.sliderState = 'open';
  }
  closeSlider() {
    this.sliderState = 'closed';
  }

}