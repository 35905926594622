import { CommonDataService } from './../services/common-data.service';

import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { apiData } from './../../common';
import { Injectable, EventEmitter } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpUserEvent } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;
    notificationCount = new EventEmitter<boolean>();
    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
    counter = [];
    // apiData.url + apiData.mosquitoCount, apiData.url + apiData.oviSensor, apiData.url + apiData.spectraCount,
    urlList = [apiData.url + apiData.sensors.glueboardEventLog,apiData.url + apiData.sensors.directional,apiData.url + apiData.user.userDetails, apiData.url + apiData.home.activityTrendBarGraph, apiData.url + apiData.home.activityTrendLineGraph, apiData.url +  apiData.sites.detectionTableList, apiData.url + apiData.sites.sensorFilter, apiData.url + apiData.sites.sensorTypes, apiData.url + apiData.sites.categoriesFilter, apiData.url + apiData.sites.categories, apiData.url + apiData.resourceHierarchy, apiData.url + apiData.sensors.sensorsStatus, apiData.url + apiData.zones.defaultUrl, apiData.url + apiData.sites.serviceList,apiData.url + apiData.sites.zoneTooltipBreach,apiData.url + apiData.sites.siteTooltipBreach,apiData.url + apiData.home.notificationCount, apiData.url + apiData.home.sensorSummary, apiData.url + apiData.home.pestDetections, apiData.url + apiData.sensors.sensorEventLogUrl, apiData.url + apiData.sensors.sensorEventOptions, apiData.url + apiData.sites.eventsLog, apiData.url + apiData.sensors.findbatterylife, apiData.url + apiData.home.liveMonitoring ];
    constructor(private status: HTTPStatus,
        private _commonDataService: CommonDataService) {
        this.counter = [];
        this.status.notificationCount.subscribe(e => {
            //console.log("Reset Counter Done")
            this.counter = [];
        })
    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {
        if (!this.noAUthRequired(req.url)) {
            // console.log('PUSH', req.url)
            // console.log('Entry', req.url)
            this.counter.push(req.url);
            this.status.setHttpStatus(true);

        }
        return next.handle(req).pipe(
            map(event => {
                return event;
            }),
            catchError(error => {
                return observableThrowError(error);
            }),
            finalize(() => {
                // console.log('Final', req.url)
                
                if (!this.noAUthRequired(req.url)) {
                    // console.log('POP', req.url)
                    this.counter.pop();
                }
                if (this.counter.length === 0) {
                    this.status.setHttpStatus(false);
                    // console.log(false);
                }
            })
        );
    }
    noAUthRequired(url): boolean {
        let flag = false;
        this.urlList.map(function (e) {
            if (url.indexOf(e) !== -1) {
                flag = true;
            }
        });
        // console.log(url + 'flag-' + !flag);
        return flag;
    }
}