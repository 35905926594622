/// <reference types="@types/googlemaps" />

import { UserService } from './../../../modules/iop-module/users/user.service';
import { mapVal } from './deleteMap';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import * as d3 from 'd3';
import * as MarkerClusterer from '@google/markerclusterer';
import { map, apiData } from './../../../common';
import { MapService } from 'src/app/shared/component/map/map.service';
import { Status } from 'src/app/helpers/config/status';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
declare var google: any;
declare const require: any;
const imagePin = require('./../../../../assets/images/iconSet/LocationPin.svg');
import * as _ from 'lodash';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('mapView', { static: true }) gmapElement: ElementRef;
  @Input('mapData') mapData: mapSite.SiteOverView[];
  unSubscribeEvent: any;
  unSubscribeCount: any;
  mapResizeFlag: boolean = false;
  map: google.maps.Map;
  locations: map.Location[];
  markerCluster: any;
  facilityId: number;
  siteId: number;
  customerId: number;
  showMapFilter: boolean = false;
  bounds = new google.maps.LatLngBounds();
  fieldTechMarkers: any;
  siteMarkers: any;
  mapFilter: any;
  usgsOverlay = class extends google.maps.OverlayView {
    bounds: any;
    image: any;
    maps: any;
    div: any;
    markerIndex: any;
    facilityId: any;
    constructor(bounds, outerMap) {
      super();
      // Initialize all properties.
      this.bounds = bounds;
      this.maps = outerMap;
      // Define a property to hold the image's div. We'll
      // actually create this div upon receipt of the onAdd()
      // method so we'll leave it null for now.
      this.div = null;
      // Explicitly call setMap on this overlay.
      this.setMap(this.maps);
    }
    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
      this.onRemove();
      const div = document.createElement('div');
      // div.style.borderStyle = 'none';
      div.style.width = '50px';
      div.style.height = '50px';
      div.style.position = 'absolute';
      div.style.zIndex = '2000';
      div.id = 'personel' + this.markerIndex;
      div.className = 'personel';
      // Create the img element and attach it to the div.
      this.div = div;
      // Add the element to the "overlayLayer" pane.
      const panes = this.getPanes();
      panes.overlayLayer.appendChild(div);
      panes.overlayMouseTarget.appendChild(div);
      google.maps.event.addDomListener(div, 'click', () => {

      });
    }
    draw() {
      // We use the south-west and north-east
      // coordinates of the overlay to peg it to the correct position and size.
      // To do this, we need to retrieve the projection from the overlay.
      const overlayProjection = this.getProjection();
      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
      const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());
      // Resize the image's div to fit the indicated dimensions.
      if (this.div) {
        const div = this.div;
        div.style.left = sw.x - 26 + 'px';
        div.style.top = ne.y - 58 + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
        div.style.height = (sw.y - ne.y) + 'px';
      }
    }
    // The onRemove() method will be called automatically from the API if
    // we ever set the overlay's map property to 'null'.
    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  };
  constructor(private _mapService: MapService, private _commonDataService: CommonDataService,
    private _userService: UserService
  ) {
    this.unSubscribeEvent = this._commonDataService.eventFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.setMapFilter(this.mapFilter);
      }
    })

    this.unSubscribeCount = this._commonDataService.countFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.setMapFilter(this.mapFilter);
      }
    })
    this.getFieldTech();
  }

  ngOnInit() {
    this.mapResizeFlag = false;
    this.getMapSites();
    this.mapData = mapVal;
    const mapProp = {
      center: new google.maps.LatLng(0, 0),
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      scaleControl: true,
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }
  getMapSites() {
    this._mapService.getMapSites().subscribe((sites: mapSite.MapSiteResponse) => {
      if (sites.status === Status.OK) {
        this.mapData = sites.siteOverView;
        this.initApp();
      }
    });
  }
  initApp() {
    // const styledMapType = new google.maps.StyledMapType(theme[this._authService.getTheme()].map);
    // this.map.mapTypes.set('styled-map', styledMapType);
    // this.map.setMapTypeId('styled-map');
    //  End Map
    const self = this;
    const data = [];
    this.locations = [];
    this.mapData.filter((e, x) => {
      this.locations.push({
        'latlng': { 'lat': e.locationLatitude, 'lng': e.locationLongitude },
        'name': e.siteName,
        'status': e.isSiteHasActiveEvent,
        'siteId': e.siteId,
        'customerId': e.customerId
      })
    });
    const active = {
      url: map.active,
      scaledSize: new google.maps.Size(35, 35),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(19, 36)
    };
    const inActive = {
      url: map.inActive,
      scaledSize: new google.maps.Size(35, 35),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(19, 36)
    };
    this.siteMarkers = this.locations.map((location, i) => {
      const self = this;
      const marker = new google.maps.Marker({
        position: location.latlng,
        icon: {
          url: map.active,
          scaledSize: new google.maps.Size(35, 35),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(19, 36)
        },
        // map: this.map
      });
      location.status === true ? marker.setAnimation(google.maps.Animation.BOUNCE) : '';
      this.bounds.extend(marker.getPosition());
      marker.set('facilityStatus', location.status);
      marker.set('facilityName', location.name);
      marker.set('siteId', location.siteId);
      marker.set('customerId', location.customerId);
      const infowindow = new google.maps.InfoWindow({
        content: '<span style="font-weight:bold;color:black">' + location.name + '</span>'
      });
      marker.addListener('mouseover', () => {
        infowindow.open(self.map, marker);
      });
      marker.addListener('mouseout', function () {
        infowindow.close();
      });
      marker.addListener('click', () => {
        self.facilityClick(location.siteId, location.customerId)
      });
      return marker;
    });

    this.map.fitBounds(this.bounds);
    this.markerCluster = new MarkerClusterer(this.map, this.siteMarkers

      // ,{ imagePath: './assets/images/marker/m' }
    );
    this.map.setOptions({ minZoom: 1, maxZoom: 18 });

    this.map.addListener('idle', (() => {
      const removeElements = (elms) => elms.forEach(el => el.remove());
      removeElements(document.querySelectorAll('.personel'));
      this.markerCluster.clusters_.filter((cluster, i) => {
        let flag = 0;
        if (cluster.markers_.length > 1) {
          cluster.markers_.filter(function (marker, j) {
            if (marker.facilityStatus === 1) {
              flag++;
            }
          });
          if (flag !== 0) {
            // cluster.clusterIcon_.url_ = './../../assets/images/markers/m2.png'
          }
          flag = 0;
        } else {
          const bound = new google.maps.LatLngBounds(
            new google.maps.LatLng(cluster.markers_[0].position.lat(), cluster.markers_[0].position.lng()));
          // this.individualFacility(clusters[i].clusterIcon_.div_, i);
          // Over Lay Code
          // const overlay = new this.usgsOverlay(bound, this.map);
          // overlay.draw();
          // overlay.markerIndex = i;
          // overlay.onAdd();
          // overlay.facilityId = cluster.markers_[0].facilityName;
          // this.individualFacility(i, cluster.markers_[0].facilityStatus, cluster.markers_[0].siteId, cluster.markers_[0].customerId);

        }
      });
      setTimeout((function () {
        const holder = document.createElement('div');
        holder.className = 'map-marker';
        holder.style.position = 'absolute';

        // create dot
        const dot = document.createElement('div');
        dot.className = 'ripple-effect';
        holder.appendChild(dot);
        this.createClusterer(this.markerCluster.clusters_);
        this.markerCluster.clusters_.filter((function (cluster, i) {
          let flag = 0;
          if (cluster.markers_.length > 1) {
            cluster.markers_.filter(function (marker, i) {
              if (marker.facilityStatus === 1) {
                flag++;
              }
            });
            if (flag !== 0) {
              if (this.markerCluster.clusters_[i].clusterIcon_.div_ != null) {
                if (!this.markerCluster.clusters_[i].clusterIcon_.div_.firstElementChild) {
                  this.markerCluster.clusters_[i].clusterIcon_.div_.appendChild(holder);
                }
              }
            }
            flag = 0;
          }
        }).bind(this));
      }).bind(this), 1000);
    }).bind(this));

    google.maps.event.addListenerOnce(this.map, 'tilesloaded', (function () {
      //  console.info('Once Event');
      const selfthis = this;
      setTimeout(function () {
        // selfthis.map.setZoom(selfthis.map.getZoom() - 1);
        // selfthis.map.setZoom(selfthis.map.getZoom() + 1);
        // selfthis.map.setOptions({ minZoom: selfthis.map.getZoom() - 1, maxZoom: 18 });
        // selfthis.google.maps.event.trigger(selfthis.map, 'resize');
      }, 5);

    }).bind(this));
  }
  createClusterer(clusters) {

    clusters.filter((marker, i) => {
      let stausClass = "map-marker-donut";
      const donut = document.createElement('div');
      const holderForDonut = document.createElement('div');
      holderForDonut.className = 'map-marker-donut';
      holderForDonut.style.position = 'absolute';
      holderForDonut.style.top = '-14px';
      holderForDonut.style.left = '-14px';
      donut.id = 'donut' + i;
      // console.log('donut' + i);
      if (clusters[i].clusterIcon_.div_ != null && clusters[i].markers_.length > 1) {
        // if (!this.markerCluster.clusters_[i].clusterIcon_.div_.firstElementChild) {
        clusters[i].clusterIcon_.div_.innerText = '';
        if (_.groupBy(marker.markers_, (e) => e.facilityStatus).true) {
          stausClass = "map-marker-donut ripple";
          const ripple = document.createElement('div');
          ripple.className = 'map-marker-donut ripple';
          donut.appendChild(ripple);
        }
        holderForDonut.appendChild(donut);
        clusters[i].clusterIcon_.div_.appendChild(holderForDonut);
        // console.log(clusters[i]);
        this.createPin('donut' + i, clusters[i].clusterIcon_.div_ != null && clusters[i].markers_);
        // }

      }
      else {
        const bound = new google.maps.LatLngBounds(
          new google.maps.LatLng(clusters[i].markers_[0].position.lat(), clusters[i].markers_[0].position.lng()));
        // this.individualFacility(clusters[i].clusterIcon_.div_, i);

        // const overlay = new this.usgsOverlay(bound, this.map);
        // overlay.draw();
        // overlay.markerIndex = i;
        // overlay.onAdd();
        // this.individualFacility(i, clusters[i].markers_[0].facilityStatus, clusters[i].markers_[0].siteId, clusters[i].markers_[0].customerId);

      }
    });
  }
  createDonut(id, marker) {
    let active = 0;
    let inActive = 0;
    marker.filter((e) => {
      if (e.facilityStatus) {
        active++;
      }
      inActive = marker.length - active;
    });
    active = marker.length;
    const data = [
      { name: 'Active Facility', value: active },
      { name: 'InActive Facility', value: inActive }
    ];
    const text = '';

    const width = 80;
    const height = 80;
    const thickness = 8;
    const duration = 750;
    const padding = 10;

    const radius = Math.min(width - padding, height - padding) / 2;
    const color = d3.scaleOrdinal(['#E32227', '#008000']);

    const surroundingColor = d3.scaleLinear()
      .domain([0, marker.length])
      .range(['#008000', '#E32227']);

    const svg = d3.select('#' + id)
      .append('svg')
      .attr('class', id)
      .attr('width', width)
      .attr('height', height);

    const g = svg.append('g')
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')');
    g.append('ellipse')
      .attr('rx', '35')
      .attr('ry', '35')
      .attr('cx', '35')
      .attr('cy', '35')
      .attr('fill', d => 'white')
      .attr('stroke', d => surroundingColor(active))
      .attr('stroke-width', ' 4px')
      .attr('stroke-linejoin', ' miter')
      .attr('stroke-linecap', ' butt')
      .attr('stroke-miterlimit', ' 4')
      .attr('shape-rendering', ' auto')
      .attr('transform', 'translate(-35,-35)');

    const arc = d3.arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    const pie = d3.pie()
      .value(function (d) { return d.value; })
      .sort(null);

    const path = g.selectAll('path')
      .data(pie(data))
      .enter()
      .append('g')
      // .on('mouseover', function(d) {
      //       let g = d3.select(this)
      //         .style('cursor', 'pointer')
      //         .style('fill', 'black')
      //         .append('g')
      //         .attr('class', 'text-group');

      //       g.append('text')
      //         .attr('class', 'name-text')
      //         .text(`${d.data.name}`)
      //         .attr('text-anchor', 'middle')
      //         .attr('dy', '-1.2em');

      //       g.append('text')
      //         .attr('class', 'value-text')
      //         .text(`${d.data.value}`)
      //         .attr('text-anchor', 'middle')
      //         .attr('dy', '.6em');
      //     })
      //   .on('mouseout', function(d) {
      //       d3.select(this)
      //         .style('cursor', 'none')
      //         .style('fill', color(this._current))
      //         .select('.text-group').remove();
      //     })
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => color(i))
      // .on('mouseover', function(d) {
      //     d3.select(this)
      //       .style('cursor', 'pointer')
      //       .style('fill', 'black');
      //   })
      // .on('mouseout', function(d) {
      //     d3.select(this)
      //       .style('cursor', 'none')  
      //       .style('fill', color(this._current));
      //   })
      .each(function (d, i) { this._current = i; });


    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '24px')
      .attr('fill', '#E32227')
      .text(active);
  }
  individualFacility(i, status, siteId, customerId) {

    const svg = d3.select('#personel' + i).append("svg")
      .style('width', '50px')
      .style('height', '50px')
      .on('click', () => {
        this.facilityClick(siteId, customerId);
      }),
      defs = svg.append("defs")
    const size = 50
    const rects = [1];
    const circles = [];

    var color = d3.scaleOrdinal(d3.schemeCategory10);

    var img_id = function (d) { return "img_" + d; }
    var img_url = function (d) { return "url(#img_" + d + ")"; }

    // create an svg element
    var imgPattern = defs.selectAll("pattern").data(d3.merge([rects, circles]))
      .enter()
      .append("pattern")
      .attr("id", img_id)
      .attr("width", 1)
      .attr("height", 1)
      .attr("patternUnits", "objectBoundingBox")
      .append("image")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", size)
      .attr("height", size)
      .attr("xlink:href", function (d) {
        return './assets/images/iconSet/LocationPin.svg';
      })

    svg.selectAll("rect").data(rects)
      .enter().append("rect")
      .attr("x", function (d, i) { 350 })
      .attr("y", function (d, i) { 350 })
      .attr("height", size)
      .attr("width", size)
      .style("fill", img_url)
    // .style("stroke", color)

    var circleSVG = svg.selectAll("circle").data(rects)
      .enter().append("circle")
      .attr("cx", function (d, i) { return 25 })
      .attr("cy", function (d, i) { return 25 })
      .attr("r", 14)
      .style("stroke", "transparent")
      .attr("fill", 'red').
      style("transform", "translate(0px, -6px)");

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '10px')
      .attr('fill', 'black')
      .style("transform", "translate3d(26px, 12px, 0px)")
      .style('font-family', 'Lato-Black')
      .text('Site');
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '8px')
      .attr('fill', 'black').
      style('font-family', 'Poppins-Regular')
      .style("transform", "translate3d(26px, 24px, 0px)")
      .text('Sites');


    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '10px')
      .attr('fill', 'black')
      .style("transform", "translate3d(26px, 12px, 0px)")
      .style('font-family', 'Lato-Black')
      .text('Site');
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '8px')
      .attr('fill', 'black').
      style('font-family', 'Poppins-Regular')
      .style("transform", "translate3d(26px, 24px, 0px)")
      .text('Sites');
  }

  facilityClick(getSiteID, getCustomerId) {
    this.facilityId = Math.random();
    this.siteId = getSiteID;
    this.customerId = getCustomerId;

  }

  createPin(id, marker) {
    const svg = d3.select('#' + id)
      .append("svg").attr('width', 100)
      .attr('height', 100),
      defs = svg.append("defs")
    const size = 100
    const rects = [1];
    const circles = [];

    var color = d3.scaleOrdinal(d3.schemeCategory10);

    var img_id = function (d) { return "img_" + d; }
    var img_url = function (d) { return "url(#img_" + d + ")"; }

    // create an svg element
    var imgPattern = defs.selectAll("pattern").data(d3.merge([rects, circles]))
      .enter()
      .append("pattern")
      .attr("id", img_id)
      .attr("width", 1)
      .attr("height", 1)
      .attr("patternUnits", "objectBoundingBox")
      .append("image")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", size)
      .attr("height", size)
      .attr("xlink:href", function (d) {
        return imagePin;
      })

    svg.selectAll("rect").data(rects)
      .enter().append("rect")
      .attr("x", function (d, i) { 350 })
      .attr("y", function (d, i) { 350 })
      .attr("height", size)
      .attr("width", size)
      .style("fill", img_url)
    // .style("stroke", color)

    var circleSVG = svg.selectAll("circle").data(rects)
      .enter().append("circle")
      .attr("cx", function (d, i) { return 75 })
      .attr("cy", function (d, i) { return 75 })
      .attr("r", 27)
      .style("stroke", "transparent")
      .attr("fill", 'white').
      style("transform", "translate(-25px, -38px)");

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '20px')
      .attr('fill', 'black')
      .style("transform", "translate3d(50px, 28px, 7px)")
      .style('font-family', 'Lato-Black')
      .text(marker.length);
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('font-size', '16px')
      .attr('fill', 'black').
      style('font-family', 'Poppins-Regular')
      .style("transform", "translate3d(50px, 46px, 7px)")
      .text('Sites');
  }

  // receive Map Resize
  receiveMapResize(event) {
    this.mapResizeFlag = event;
  }


  setFiedTech(filedTech: viewUser.Content[]) {
    // Filed TEch Trial
    let locations: any = [];
    filedTech.filter((e, x) => {
      if (e.userLocationId) {
        locations.push({
          'latlng': { 'lat': e.userLocationId.locationLattitude, 'lng': e.userLocationId.locationLongitude },
          'name': e.firstName + ' ' + e.lastName,
          'userName': e.userName
        })
      }
    });
    console.log("location >> ",locations);
    const active = {
      url: './assets/images/iconSet/ft.png',
      scaledSize: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(19, 36)
    };
    this.fieldTechMarkers = locations.map((location, i) => {
      const self = this;
      const marker = new google.maps.Marker({
        position: location.latlng,
        icon: active,
        map: this.map
      });
      this.bounds.extend(marker.getPosition());
      marker.set('facilityStatus', location.status);
      marker.set('facilityName', location.name);
      const infowindow = new google.maps.InfoWindow({
        content: '<span style="font-weight:bold;color:black">' + location.name + '</span>'
      });
      marker.addListener('mouseover', () => {
        infowindow.open(self.map, marker);
      });
      marker.addListener('mouseout', function () {
        infowindow.close();
      });
      marker.addListener('click', () => {
        // self.facilityClick(location.siteId, location.customerId)
      });
      return marker;
    });
    //Ends
  }
  getFieldTech() {
    this._userService.getFielTechData(apiData.url + apiData.user.defaultUrl, apiData.user.getFieldTechId).subscribe((user: viewUser.UserResponse) => {
      if (user.status === Status.OK) {
        this.setFiedTech(user.data.content);
      }
    })
    // this._userService.getUserPaginated(apiData.url + apiData.user.defaultUrl, '4').subscribe((user: viewUser.UserResponse) => {
    //   if (user.status === Status.OK) {
    //     this.setFiedTech(user.data.content);
    //   }
    // })
  }

  clearFieldTechMarkers() {
    
    this.fieldTechMarkers.map(marker => {
      marker.setMap(null);
    });
    this.fieldTechMarkers = [];
    this.ngOnInit();
  }
  clearSiteMarkers() {
    this.markerCluster.clearMarkers()
    this.getFieldTech();

  }
  toggleMapFilter() {
    this.showMapFilter = !this.showMapFilter
  }
  
  setAllMarker() {
    this.fieldTechMarkers.map(marker => {
      marker.setMap(null);
    });
    this.markerCluster.clearMarkers()
    this.ngOnInit();
    this.getFieldTech();
  }
  setMapFilter(event) {
    this.mapFilter = event.value;
    switch (parseInt(event.value)) {
      case 1:
        this.setAllMarker();
        break;
      case 2:
        this.clearFieldTechMarkers();
        break;
      case 3:
        this.clearSiteMarkers();
        break;
    }

  }

  ngOnDestroy() {
    if (this.unSubscribeEvent) {
      this.unSubscribeEvent.unsubscribe();
    }
    if (this.unSubscribeCount) {
      this.unSubscribeCount.unsubscribe();
    }
  }
}
