import { Observable, of } from 'rxjs';
import { AuthenticationService } from './../authentication.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionFinderService {
  // private readonly WORKFLOW_EVENTS = {
  //   'opportunity': {
  //     'addOpportunity': { 'permittedRoles': ['pmo'] } },
  //     'created': {
  //       'addRequirement': {'permittedRoles': ['PMO', 'CLIENT']}
  //     },
  //     'submitted': {
  //       'addSolution': { 'permittedRoles': ['FieldTech']}
  //     },
  //     'solved': {
  //       'approveSolution': { 'permittedRoles': ['FieldTech']}
  //     }
  // };
  private readonly WORKFLOW_EVENTS;

  private userRoles: Set<string>;
  // do your remember the step 1 ? it is used here
  constructor(private _authService: AuthenticationService) {
    //this.WORKFLOW_EVENTS = this._authService.getRoleModule();
    this.WORKFLOW_EVENTS = this._authService.getRolePermissions(); 
  }

  // returns a boolean observable
  public checkAuthorization(path: any): Observable<boolean> {
    // we are loading the roles only once
    if (!this.userRoles) {
      this.userRoles = new Set([this._authService.getRole()]);

      this.doCheckAuthorization(path);
    }
    return of(this.doCheckAuthorization(path));
  }

  private doCheckAuthorization(path: string[]): boolean {
    if (path.length) {
      const entry = this.findEntry(this.WORKFLOW_EVENTS, path);
      if (entry && this.userRoles.size) {
        // entry.permittedRoles
        //   .some(permittedRole => this.userRoles.has(permittedRole));
        return true;
      }
      return false;
    }
    return false;
  }

  /**
 * Recursively find workflow-map entry based on the path strings
 */
  private findEntry(currentObject: any, keys: string[], index = 0) { // Permission mapping from HTML element *appCanAccess
    let isAllow: boolean = false;
    currentObject.filter(rules => {
      // if(rules.resourceName.toLowerCase() === keys[0].toLowerCase()) { // Resource Name
        if(parseInt(rules.permissionId) === parseInt(keys[1])) { // permission ID
          // if(rules.actionName.toLowerCase() === keys[2].toLowerCase()) { // Action Name
            isAllow = true;
          // }
        }
      // }
    })
    // console.log(keys, isAllow);
    return isAllow;//true;
  }
}