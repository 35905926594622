class SocketClass {
    userName: string;
    password: string;
}

//const hostName =  window.location.host.replace(".app.", ".api.");

// OPEN VPC CONFIG - OPEN VPC
/* ====================================*/
const openVpc = 'http://192.168.11.146:8080/';
const openVpcSocket: SocketClass = { userName: 'admin', password: 'ad85702' };
// const openVpc = 'http://52.66.18.36/brandenburg-iop-anana-oauth/';
// const openVpc = 'http://ec2-13-127-52-122.ap-south-1.compute.amazonaws.com/brandenburg-iop-anana/';

// IOP BETA UAE CONFIG - PRODUCTION
/* ====================================*/
const iopUaeUrl = 'https://uas.iopbeta.com/brandenburg-iop-anana/';
const iopUaeSocket: SocketClass = { userName: 'admin', password: 'admin#@e' };

// IOP BETA SA CONFIG - PRODUCTION
/*====================================*/
const iopSaUrl = 'https://uas.iopbeta.com/brandenburg-iop-anana/';
const iopSaSocket: SocketClass = { userName: 'admin', password: 'admin#@e' };

// IOP BETA US CONFIG
/*====================================*/
const iopUsUrl = 'https://uas.iopbeta.com/brandenburg-iop-anana/';
const iopUsSocket: SocketClass = { userName: 'admin', password: 'admin#@e' };

// IOP BETA US CONFIG
/* ====================================*/
const iopBetaUrl = 'https://uas.iopbeta.com/brandenburg-iop-anana/';
const iopBetaSocket: SocketClass = { userName: 'admin', password: 'admin#@e' };

// JAPAN CONFIG
/*====================================*/
const iopJpUrl = 'https://test-uas.iopbeta.com/uasv2/';
const iopJpSocket: SocketClass = { userName: 'admin', password: 'admin#@!' };

// TEST CONFIG
/*====================================*/
const iopTestUrl = 'https://test-uas.iopbeta.com/uasv2/';
const iopTestSocket: SocketClass = { userName: 'admin', password: 'admin#@!' };

// PRODUCTION CONFIG
/*====================================*/
const prodUrl = 'https://prod-uas.internetofpests.com/uasv2/';
const prodSocket: SocketClass = { userName: 'iopapp', password: 'admin#@e' };

// DEVELOPMENT CONFIG
/* ====================================*/
const devUrl = 'https://dev-uas.internetofpests.com/uasv2/';
const devSocket: SocketClass = { userName: 'admin', password: 'admin#@!' };

// Stage CONFIG
/*====================================*/
const stageUrl = 'https://stage-uas.internetofpests.com/uasv2/';
const stageSocket: SocketClass = { userName: 'admin', password: 'admin#@!' };

export const UrlList = {
    prod: { // Production
        url: prodUrl + 'api/v2/',
        notificationUrl: 'wss://prod-wssrabbitmq.internetofpests.com/ws',
        oAuth: prodUrl + 'oauth/token',
        username: prodSocket.userName,
        password: prodSocket.password,
        jenkinsBuildNumUrl: 'https://iop-prod-ui-build-versions.s3.amazonaws.com/iop-prod-ui-version.json'
    },
    stageIop: { // Stage
        url: stageUrl + 'api/v2/',
        notificationUrl: 'wss://stage-wssrabbitmq.internetofpests.com/ws',
        oAuth: stageUrl + 'oauth/token',
        username: stageSocket.userName,
        password: stageSocket.password,
        jenkinsBuildNumUrl: 'https://ui-build-versions.s3.ap-south-1.amazonaws.com/iop-stage-ui-version.json'
    },
    dev: { // Development
        url: devUrl + 'api/v2/',
        notificationUrl: 'wss://development-wssrabbitmq.internetofpests.com/ws',
        oAuth: devUrl + 'oauth/token',
        username: devSocket.userName,
        password: devSocket.password,
        jenkinsBuildNumUrl: 'https://ui-build-versions.s3.ap-south-1.amazonaws.com/iop-development-ui-version.json'
    },
    iopTest: { // Test
        url: iopTestUrl + 'api/v2/',
        notificationUrl: 'wss://test-wssrabbitmq.iopbeta.com/ws',
        oAuth: iopTestUrl + 'oauth/token',
        username: iopTestSocket.userName,
        password: iopTestSocket.password,
        jenkinsBuildNumUrl: ''
    },
    openVpc: { // Open VPC
        url: openVpc + 'api/v2/',
        notificationUrl: 'wss://dev-wssrabbitmq.iopbeta.com/ws',
        oAuth: openVpc + 'oauth/token',
        username: openVpcSocket.userName,
        password: openVpcSocket.password
    },
    iopBeta: { // Beta
        url: iopBetaUrl + 'api/v2/',
        notificationUrl: 'wss://beta-wssrabbitmq.iopbeta.com/ws',
        oAuth: iopBetaUrl + 'oauth/token',
        username: iopBetaSocket.userName,
        password: iopBetaSocket.password
    },
    iopJp: { // Japan Beta
        url: iopJpUrl + 'api/v2/',
        notificationUrl: 'wss://test-wssrabbitmq.iopbeta.com/ws',
        oAuth: iopJpUrl + 'oauth/token',
        username: iopJpSocket.userName,
        password: iopJpSocket.password
    },
    iopUae: { // UAE Dubai Beta
        url: iopUaeUrl + 'api/v2/',
        notificationUrl: 'wss://beta-wssrabbitmq.iopbeta.com/ws',
        oAuth: iopUaeUrl + 'oauth/token',
        username: iopUaeSocket.userName,
        password: iopUaeSocket.password
    },
    iopUs: { // US Beta
        url: iopUsUrl + 'api/v2/',
        notificationUrl: 'wss://beta-wssrabbitmq.iopbeta.com/ws',
        oAuth: iopUsUrl + 'oauth/token',
        username: iopUsSocket.userName,
        password: iopUsSocket.password
    },
    iopSa: { // SA Beta
        url: iopSaUrl + 'api/v2/',
        notificationUrl: 'wss://beta-wssrabbitmq.iopbeta.com/ws',
        oAuth: iopSaUrl + 'oauth/token',
        username: iopSaSocket.userName,
        password: iopSaSocket.password
    }
};