import { Observable } from 'rxjs';
import { CommonDataService } from './../../../helpers/services/common-data.service';
import { apiData } from './../../../common';
import { Chart } from 'angular-highcharts';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private _commonDataService: CommonDataService,private _router: Router) { }

  getMapSites() {
    let url = apiData.url + apiData.map.sites;
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((addEditResponse: mapSite.MapSiteResponse) => {
        observer.next(addEditResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // alerts crawl count api 
  getCrawlAlerts(getUrl) {
    if (getUrl) {
      let url = apiData.url + getUrl;
      return new Observable(observer => {
        this._commonDataService.getData(url).subscribe((alertsResponse: crawlAlert.CrawlEventCounts) => {
          observer.next(alertsResponse);
          observer.complete();
        }, error => {
          throw new Error(JSON.stringify(error));
        });
      });
    }

  }

  // Donut chart
  getDonutChartData(getFinalUrl) {
    let url = apiData.url + apiData.map.donutChart + getFinalUrl;
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((donutResponse: any) => {
        observer.next(donutResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // alerts fly count api 
  getFryAlerts(getUrl) {
    if (getUrl) {
      let url = apiData.url + getUrl;
      return new Observable(observer => {
        this._commonDataService.getData(url).subscribe((flyAlertsResponse: crawlAlert.FlyEventCounts) => {
          observer.next(flyAlertsResponse);
          observer.complete();
        }, error => {
          throw new Error(JSON.stringify(error));
        });
      });
    }
  }

  // Overview all alerts Tab
  getALLAlertsTab(getUrl){
    if (getUrl) {
      let url = apiData.url + getUrl;
      return new Observable(observer => {
        this._commonDataService.getData(url).subscribe((flyAlertsResponse: crawlAlert.FlyEventCounts) => {
          observer.next(flyAlertsResponse);
          observer.complete();
        }, error => {
          throw new Error(JSON.stringify(error));
        });
      });
    }
  }

  // sensor list api
  getSensorLists(getSiteID) {
    let url = getSiteID ? apiData.url + apiData.map.sensorList + '/' + getSiteID : apiData.url + apiData.map.sensorList + 's';
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((activitiesResponse: mapSite.sensorResponse) => {
        observer.next(activitiesResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // get site detail
  getSiteDetailData(siteId) {
    let url = apiData.url + apiData.map.siteDetail + siteId;
    return new Observable(observer => {
      this._commonDataService.getData(url).subscribe((activitiesResponse: mapSite.sensorResponse) => {
        observer.next(activitiesResponse);
        observer.complete();
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  // chart
  // getChartData(data) {
  //   let url = apiData.url + apiData.map.chart
  //   return new Observable(observer => {
  //     this._commonDataService.postDataOnlyIOPHeader(data, url).subscribe((ChartResponse: any) => {

  //       observer.next(ChartResponse);
  //       observer.complete();

  //     }, error => {
  //       throw new Error(JSON.stringify(error));
  //     });
  //   });
  // }

  // get data for pest bar & trend chart
  getBarTrendChartData(payload, url) {
    return new Observable(observer => {
      this._commonDataService.postDataOnlyIOPHeader(payload, url).subscribe((ChartResponse: any) => {

        observer.next(ChartResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

// all species
  // clonePestTrendChart_allSpecies(data, themeValue, navAcitivity,seriesTitle) {
  //   const rounter = this._router; 
  //   const chart = new Chart({
  //     chart: {
  //       backgroundColor: themeValue.backgroundColor,
  //       type: 'column'
  //     },

  //     exporting: { enabled: false },
  //     credits: {
  //       enabled: false
  //     },
  //     legend: {
  //       symbolRadius: 0,
  //       verticalAlign: 'top',
  //       useHTML: true,
  //       itemStyle: {
  //         color: themeValue.textColor,
  //         fontWeight: 'bold',
  //         fontSize: themeValue.textSize,
  //         fontFamily: "Poppins-Regular"
  //       }
  //     },
  //     title: {
  //       text: '',
  //       style: {
  //         color: themeValue.textColor,
  //         fontWeight: 'bold',
  //         fontSize: themeValue.textSize,
  //         fontFamily: "Poppins-Regular"
  //       }
  //     },
  //     tooltip: {
  //       //enabled: false
  //       formatter: function() {
  //         return '<br>'+ this.series.name +'</br> : '+ this.y;
  //       },
  //       style: {
  //         color: themeValue.textColor,
  //         fontSize: themeValue.textSize,
  //         fontFamily: "Poppins-Regular"
  //       }
  //     },
      
  //     xAxis: {
  //       categories: data['category'],
  //       crosshair: true,
  //       lineColor: themeValue.textColor,
  //       title: {
  //         text: themeValue.xAxisLabel,
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Regular"
  //         }
  //       }, labels: {
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Regular"
  //         }
  //       }
  //     },

  //     yAxis: {
  //       min: 0,
  //       endOnTick: false,
  //       showLastLabel: true,
  //       lineWidth: 0,
  //       gridLineColor: themeValue.textColor,
  //       lineColor: themeValue.textColor,
  //       title: {
  //         text: themeValue.yAxisLabel,
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Regular"
  //         }
  //       },
  //       labels: {
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Regular"
  //         }
  //       }
  //     },
  //     series: [{
  //       cursor: 'pointer',
  //       point: {
  //         events: {
  //           click: function() {
  //               //alert ('Category: '+ this.category +', value: '+ this.y);
  //               //alert(navAcitivity)
  //               rounter.navigate([navAcitivity]);
  //               //window.location.assign(navAcitivity);
  //           }
  //         }
  //       },  
  //       color: themeValue.cockroachColor,
  //       type: 'column',
  //       name: seriesTitle['Cockroach Detections'],
  //       data: data['cockroachCount']
  //       }, 
  //       {
  //       cursor: 'pointer',
  //       point: {
  //         events: {
  //           click: function() {
  //             rounter.navigate([navAcitivity]);
  //           }
  //         }
  //       },  
  //       color: themeValue.flyColor,  
  //       type: 'column',
  //       name: seriesTitle['Fly Detections'],
  //       data: data['flyCount']
  //       }, 
  //       {
  //         cursor: 'pointer',
  //         point: {
  //           events: {
  //             click: function() {
  //               rounter.navigate([navAcitivity]);
  //             }
  //           }
  //         },  
  //         color: themeValue.rodentColor,
  //         type: 'column',
  //         name: seriesTitle['Rodent Detections'],
  //         data: data['rodentCount']
  //       },
  //       {
  //         cursor: 'pointer',
  //         point: {
  //           events: {
  //             click: function() {
  //               rounter.navigate([navAcitivity]);
  //             }
  //           }
  //         },  
  //         color: themeValue.cockroachColor,
  //         type: 'spline',
  //         name: seriesTitle['Cockroach Trend'],
  //         data: data['cockroachTrend'],
  //         marker: {
  //           enabled: false
  //         }
  //       },{
  //         cursor: 'pointer',
  //         point: {
  //           events: {
  //             click: function() {
  //               rounter.navigate([navAcitivity]);
  //             }
  //           }
  //         },  
  //         color: themeValue.flyColor,
  //         type: 'spline',
  //         name: seriesTitle['Fly Trend'],
  //         data: data['flyTrend'],
  //         marker: {
  //           enabled: false
  //         }
  //       },{
  //         cursor: 'pointer',
  //         point: {
  //           events: {
  //             click: function() {
  //               rounter.navigate([navAcitivity]);
  //             }
  //           }
  //         },  
  //         color: themeValue.rodentColor,
  //         type: 'spline',
  //         name: seriesTitle['Rodent Trend'],
  //         data: data['rodentTrend'],
  //         marker: {
  //           enabled: false
  //         }
  //       }
  //     ],
  //     responsive: {
  //         rules: [{
  //             condition: {
  //                 maxWidth: 500
  //             },
  //             chartOptions: {
  //                 legend: {
  //                     align: 'center',
  //                     verticalAlign: 'bottom',
  //                     layout: 'horizontal'
  //                 },
  //                 subtitle: {
  //                     text: null
  //                 },
                  
  //             }
  //         }]
  //     }
  //   });

  //   return chart;
    
  // }

  // set data for pest bar & trend chart
  pestTrendChart_allSpecies(data,themeValue,seriesDataArray,noRecordsFoud) {
    
    const chart = new Chart({
      chart: {
        backgroundColor: themeValue.backgroundColor,
        type: 'column'
      },

      exporting: { enabled: false },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        verticalAlign: 'top',
        useHTML: true,
        itemMarginBottom: 10,
        itemWidth: 250,
        itemStyle: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        },
        itemHoverStyle: {
          color: themeValue.hoverColor
        }
      },
      title: {
        text: '',
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      tooltip: {
        // enabled: true,
        // followPointer: true,
        formatter: function() {
          return `<span style="color:${this.point.color}; font-size: 12px">●</span> ${this.series.name} : ${this.y}`;
          // return '<br>'+ this.series.name +'</br> : '+ this.y; //■
        },
        style: {
          color: themeValue.textColor,
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      xAxis: {
        categories: data['category'], //['week1', 'week2', 'week3', 'week4', 'week5'],
       
        crosshair: true,  
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.xAxisLabel,
          margin: 20,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        }, labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      yAxis: {
        min: 0,
        // tickInterval : 1,
        minRange : 0,
        endOnTick: false,
        showLastLabel: true,
        lineWidth: 0,
        gridLineColor: themeValue.girdLineColor,
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.yAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        },
        labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      lang: {
        noData: noRecordsFoud
      },
      series: seriesDataArray,
      responsive: {
          rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  layout: 'horizontal'
                },
                subtitle: {
                  text: null
                },
              }
          }]
      }
    });

    return chart;
  }

  // specific Species with nav activity screen
  // clonePestTrendChart_specificSpecies(data, themeValue, navAcitivity, seriesTitle) {
  //   let trend_key  = seriesTitle['trend'];
  //   let count_key = seriesTitle['count'];
  //   const rounter = this._router; 
  //   const chart = new Chart({
  //     chart: {
  //       backgroundColor: themeValue.backgroundColor,
  //       type: 'column'
  //     },

  //     exporting: { enabled: false },
  //     credits: {
  //       enabled: false
  //     },
  //     legend: {
  //       symbolRadius: 0,
  //       verticalAlign: 'top',
  //       useHTML: true,
  //       itemStyle: {
  //         color: themeValue.textColor,
  //         fontWeight: 'bold',
  //         fontSize: themeValue.textSize,
  //         fontFamily: "Poppins-Regular"
  //       }
  //     },
  //     title: {
  //       text: '',
  //       style: {
  //         color: themeValue.textColor,
  //         fontWeight: 'bold',
  //         fontSize: themeValue.textSize,
  //         fontFamily: "Poppins-Regular"
  //       }
  //     },
  //     tooltip: {
  //       //enabled: false
  //       formatter: function() {
  //         return '<br>'+ this.series.name +'</br> : '+ this.y;
  //       },
  //       style: {
  //         color: themeValue.textColor,
  //         fontSize: themeValue.textSize,
  //         fontFamily: "Poppins-Regular"
  //       }
  //     },
      
  //     xAxis: {
  //       categories: data['category'],
  //       crosshair: true,
  //       lineColor: themeValue.textColor,
  //       title: {
  //         text: themeValue.xAxisLabel,
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Medium"
  //         }
  //       }, labels: {
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Regular"
  //         }
  //       }
  //     },

  //     yAxis: {
  //       min: 0,
  //       endOnTick: false,
  //       showLastLabel: true,
  //       lineWidth: 0,
  //       gridLineColor: themeValue.textColor,
  //       lineColor: themeValue.textColor,
  //       title: {
  //         text: themeValue.yAxisLabel,
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Medium"
  //         }
  //       },
  //       labels: {
  //         style: {
  //           color: themeValue.textColor,
  //           fontSize: themeValue.textSize,
  //           fontFamily: "Poppins-Regular"
  //         }
  //       }
  //     },
  //     series: [{
  //       cursor: 'pointer',
  //       point: {
  //         events: {
  //           click: function() {
  //             rounter.navigate([navAcitivity]);
  //           }
  //         }
  //       },  
  //       color: themeValue.speciesColor,
  //       type: 'column',
  //       name: seriesTitle['barSpecies'],
  //       data: data[count_key],
  //       },
  //       {
  //         cursor: 'pointer',
  //         point: {
  //           events: {
  //             click: function() {
  //               rounter.navigate([navAcitivity]);
  //             }
  //           }
  //         },  
  //         color: themeValue.speciesColor,
  //         type: 'spline',
  //         name: seriesTitle['trendSpecies'],
  //         data: data[trend_key],
  //         marker: {
  //           enabled: false
  //         }
  //       }
  //     ],
  //     responsive: {
  //         rules: [{
  //             condition: {
  //                 maxWidth: 500
  //             },
  //             chartOptions: {
  //                 legend: {
  //                     align: 'center',
  //                     verticalAlign: 'bottom',
  //                     layout: 'horizontal'
  //                 },
  //                 subtitle: {
  //                     text: null
  //                 },
                  
  //             }
  //         }]
  //     }
  //   });

  //   return chart;
    
  // }

  // specific Species without nav activity screen
  pestTrendChart_specificSpecies(data,themeValue,seriesDataArray,noRecordsFoud) {

    const chart = new Chart({
      chart: {
        backgroundColor: themeValue.backgroundColor,
        type: 'column'
      },

      exporting: { enabled: false },
      credits: {
        enabled: false
      },
      legend: {
        symbolRadius: 0,
        verticalAlign: 'top',
        useHTML: true,
        itemStyle: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        },
        itemHoverStyle: {
          color: themeValue.hoverColor
        }
      },
      title: {
        text: '',
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      tooltip: {
        //enabled: false
        formatter: function() {
          return `<span style="color:${this.point.color}; font-size: 12px">●</span> ${this.series.name} : ${this.y}`;
        },
        style: {
          color: themeValue.textColor,
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      xAxis: {
        categories: data['category'],
       
        crosshair: true,  
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.xAxisLabel,
          margin: 20,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        }, labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      yAxis: {
        min: 0,
        // tickInterval : 1,
        minRange : 0,
        endOnTick: false,
        showLastLabel: true,
        lineWidth: 0,
        gridLineColor: themeValue.girdLineColor,
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.yAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        },
        labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      lang: {
        noData: noRecordsFoud
      },
      series: seriesDataArray,
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                subtitle: {
                    text: null
                }
              }
          }]
      }
    });

    return chart;
  }

  // fly Species without nav activity screen
  pestTrendChart_flySpecies(data,themeValue,flyDataArray,noRecordsFoud) {

    const chart = new Chart({
      chart: {
        backgroundColor: themeValue.backgroundColor,
        type: 'column'
      },

      exporting: { enabled: false },
      credits: {
        enabled: false
      },
      legend: {
        symbolRadius: 0,
        verticalAlign: 'top',
        useHTML: true,
        itemStyle: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        },
        itemHoverStyle: {
          color: themeValue.hoverColor
        }
      },
      title: {
        text: '',
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      tooltip: {
        //enabled: false
        formatter: function() {
          return `<span style="color:${this.point.color}; font-size: 12px">●</span> ${this.series.name} : ${this.y}`;
        },
        style: {
          color: themeValue.textColor,
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      xAxis: {
        categories: data['category'],
       
        crosshair: true,  
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.xAxisLabel,
          margin: 20,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium",
          }
        }, labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      yAxis: {
        min: 0,
        // tickInterval : 1,
        minRange : 0,
        endOnTick: false,
        showLastLabel: true,
        lineWidth: 0,
        gridLineColor: themeValue.girdLineColor,
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.yAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        },
        labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      series: flyDataArray,
      responsive: {
          rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              },
              subtitle: {
                text: null
              }
            }
          }]
      }
    });

    return chart;
  }

  // Fly Species with nav activity screen
    // clonePestTrendChart_flySpecies(data, themeValue, navAcitivity, seriesTitle) {

    //   let trend_key  = seriesTitle['trend'];
    //   let fruitFlyCount_key = seriesTitle['fruitFlyCount'];
    //   let houseFlyCount_key = seriesTitle['houseFlyCount'];
    //   let fruitFlyTrend_key = seriesTitle['fruitFlyTrend'];
    //   let houseFlyTrend_key = seriesTitle['houseFlyTrend'];

    //   const rounter = this._router; 
    //   const chart = new Chart({
    //     chart: {
    //       backgroundColor: themeValue.backgroundColor,
    //       type: 'column'
    //     },
  
    //     exporting: { enabled: false },
    //     credits: {
    //       enabled: false
    //     },
    //     legend: {
    //       symbolRadius: 0,
    //       verticalAlign: 'top',
    //       useHTML: true,
    //       itemStyle: {
    //         color: themeValue.textColor,
    //         fontWeight: 'bold',
    //         fontSize: themeValue.textSize,
    //         fontFamily: "Poppins-Regular"
    //       }
    //     },
    //     title: {
    //       text: '',
    //       style: {
    //         color: themeValue.textColor,
    //         fontWeight: 'bold',
    //         fontSize: themeValue.textSize,
    //         fontFamily: "Poppins-Regular"
    //       }
    //     },
    //     tooltip: {
    //       //enabled: false
    //       formatter: function() {
    //         return '<br>'+ this.series.name +'</br> : '+ this.y;
    //       },
    //       style: {
    //         color: themeValue.textColor,
    //         fontSize: themeValue.textSize,
    //         fontFamily: "Poppins-Regular"
    //       }
    //     },
        
    //     xAxis: {
    //       categories: data['category'],
    //       crosshair: true,
    //       lineColor: themeValue.textColor,
    //       title: {
    //         text: themeValue.xAxisLabel,
    //         style: {
    //           color: themeValue.textColor,
    //           fontSize: themeValue.textSize,
    //           fontFamily: "Poppins-Medium"
    //         }
    //       }, labels: {
    //         style: {
    //           color: themeValue.textColor,
    //           fontSize: themeValue.textSize,
    //           fontFamily: "Poppins-Regular"
    //         }
    //       }
    //     },
  
    //     yAxis: {
    //       min: 0,
    //       endOnTick: false,
    //       showLastLabel: true,
    //       lineWidth: 0,
    //       gridLineColor: themeValue.textColor,
    //       lineColor: themeValue.textColor,
    //       title: {
    //         text: themeValue.yAxisLabel,
    //         style: {
    //           color: themeValue.textColor,
    //           fontSize: themeValue.textSize,
    //           fontFamily: "Poppins-Medium"
    //         }
    //       },
    //       labels: {
    //         style: {
    //           color: themeValue.textColor,
    //           fontSize: themeValue.textSize,
    //           fontFamily: "Poppins-Regular"
    //         }
    //       }
    //     },
    //     series: [
    //       {
    //         cursor: 'pointer',
    //         point: {
    //           events: {
    //             click: function() {
    //               rounter.navigate([navAcitivity]);
    //             }
    //           }
    //         },  
    //         color: themeValue.houseFlyspeciesColor,
    //         type: 'column',
    //         name: 'House Fly',
    //         data: data[houseFlyCount_key]
    //       },
    //       {
    //         cursor: 'pointer',
    //         point: {
    //           events: {
    //             click: function() {
    //               rounter.navigate([navAcitivity]);
    //             }
    //           }
    //         }, 
    //         color: themeValue.fruitFlyspeciesColor,
    //         type: 'column',
    //         name: 'Fruit Fly',
    //         data: data[fruitFlyCount_key]
    //       },
    //       {
    //         cursor: 'pointer',
    //         point: {
    //           events: {
    //             click: function() {
    //               rounter.navigate([navAcitivity]);
    //             }
    //           }
    //         },  
    //         color: themeValue.houseFlyspeciesColor,
    //         type: 'spline',
    //         name: 'House Fly',
    //         data: data[houseFlyTrend_key],
    //         marker: {
    //           enabled: false
    //         }
    //       },
    //       {
    //         cursor: 'pointer',
    //         point: {
    //           events: {
    //             click: function() {
    //               rounter.navigate([navAcitivity]);
    //             }
    //           }
    //         },  
    //         color: themeValue.fruitFlyspeciesColor,
    //         type: 'spline',
    //         name: 'Fruit Fly',
    //         data: data[fruitFlyTrend_key],
    //         marker: {
    //           enabled: false
    //         }
    //       }
    //     ],
    //     responsive: {
    //         rules: [{
    //             condition: {
    //                 maxWidth: 500
    //             },
    //             chartOptions: {
    //                 legend: {
    //                     align: 'center',
    //                     verticalAlign: 'bottom',
    //                     layout: 'horizontal'
    //                 },
    //                 subtitle: {
    //                     text: null
    //                 },
                    
    //             }
    //         }]
    //     }
    //   });
  
    //   return chart;
      
    // }

  // silder map chart
  sliderMapChart(data, sensorTypeSpecies, themeValue) {
    const chart = new Chart({
      chart: {
        backgroundColor: themeValue.backgroundColor,
        type: 'spline'
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      title: {
        text: '',
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      xAxis: {
        categories: data.categories,
       
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.xAxisLabel,
          margin: 20,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium",
          }
        }, labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      yAxis: {
        // tickInterval : 1,
        minRange : 0,
        lineWidth: 0,
        gridLineColor: themeValue.girdLineColor,
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.yAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        },
        labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return '<b>' + sensorTypeSpecies + '</b>' + ' count on ' + this.x +' is <b>' + this.y + '</b>';
        },
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      plotOptions: {
        spline: {
          lineWidth: 3,
          states: {
            hover: {
              lineWidth: 4
            }
          },
          marker: {
            enabled: false
          }
        },
        // line: {
        //   dataLabels: {
        //       enabled: true
        //   }
        // }
      },
      series: [{
        type: undefined,
        data: data.data,
        color: themeValue.lineColor,
        showInLegend: false,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: false,
            color: themeValue.textColor
          }
        }
      }]
    });

    return chart;
  }

   // clone silder map chart
   cloneSliderMapChart(data, sensorTypeSpecies, themeValue, navAcitivity) {
    const rounter = this._router; 
    const chart = new Chart({
      chart: {
        backgroundColor: themeValue.backgroundColor,
        type: 'spline'
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      title: {
        text: '',
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      xAxis: {
        categories: data.categories,
       
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.xAxisLabel,
          margin: 20,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium",
          }
        }, labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      yAxis: {
        lineWidth: 0,
        gridLineColor: themeValue.girdLineColor,
        lineColor: themeValue.girdLineColor,
        title: {
          text: themeValue.yAxisLabel,
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Medium"
          }
        },
        labels: {
          style: {
            color: themeValue.textColor,
            fontSize: themeValue.textSize,
            fontFamily: "Poppins-Regular"
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return '<b>' + sensorTypeSpecies + '</b>' + ' count on ' + this.x + ' is <b>' + this.y + '</b>';
        },
        style: {
          color: themeValue.textColor,
          fontWeight: 'bold',
          fontSize: themeValue.textSize,
          fontFamily: "Poppins-Regular"
        }
      },
      plotOptions: {
        spline: {
          lineWidth: 3,
          states: {
            hover: {
              lineWidth: 4
            }
          },
          marker: {
            enabled: false
          }
        }
      },
      series: [{
        type: undefined,
        data: data.data,
        color: themeValue.lineColor,
        showInLegend: false,
        cursor: 'pointer',
        point: {
          events: {
            click: function() {
                //alert ('Category: '+ this.category +', value: '+ this.y);
                //alert(navAcitivity)
                rounter.navigate([navAcitivity]);
                //window.location.assign(navAcitivity);
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            textOutline: false,
            color: themeValue.textColor
          }
        }
      }]
    });

    return chart;
  }

}