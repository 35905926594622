import { AuthenticationService } from './../authentication.service';
import { Injectable, EventEmitter, Inject } from '@angular/core';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

declare let moment: any;

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  notificationCount = new EventEmitter<any>();
  
  eventEmitterData = new EventEmitter<any>();
  sensorEvent = new EventEmitter<any>();
  interactionEvents = new EventEmitter<any>();

  eventFlag =  new EventEmitter<any>();
  countFlag =  new EventEmitter<any>();
  statusFlag = new EventEmitter<any>();
  flyFlag = new EventEmitter<any>();
  cockroachFlag = new EventEmitter<any>();
  rodentFlag = new EventEmitter<any>();
  customerFlag = new EventEmitter<any>();
  siteFlag = new EventEmitter<any>();
  zoneFlag = new EventEmitter<any>();
  installationFlag = new EventEmitter<any>();
  troubleShootFlag = new EventEmitter<any>();
  aqiFlag = new EventEmitter<any>();
  sensorActiveFlag = new EventEmitter<any>();

  sensorInstalledFlag = new EventEmitter<any>();
  silentNotificationFlag = new EventEmitter<any>();
  clearedAllRemoveRedDotFlag = new EventEmitter<any>();
  reloadNotificationCenterFlag = new EventEmitter<any>();

  enableRedDotFlag = new EventEmitter<any>();
  sensorStatusInitFlag = new EventEmitter<any>();
  sensorGlueboardChangedFlag = new EventEmitter<any>();
  sensorStatusActiveFlag = new EventEmitter<any>();
  singleSensorAddedFlag = new EventEmitter<any>();
  multipleSensorsAddedFlag = new EventEmitter<any>();
  sensorUpdatedFlag = new EventEmitter<any>();
  sensorRemovedFlag = new EventEmitter<any>();
  sensorReplacedFlag = new EventEmitter<any>();
  customerCreateFlag = new EventEmitter<any>();
  customerUpdateFlag = new EventEmitter<any>();
  customerAssignFlag = new EventEmitter<any>();
  customerDeleteFlag = new EventEmitter<any>();
  siteCreateFlag = new EventEmitter<any>();
  siteUpdateFlag = new EventEmitter<any>();
  siteDeleteFlag = new EventEmitter<any>();
  siteAssignFlag = new EventEmitter<any>();
  flyGlueboardExpiryFlag = new EventEmitter<any>();
  flyMaxDetectionsFlag = new EventEmitter<any>();
  criticalControlLimitFlag = new EventEmitter<any>();
  AQISensorEventFlag = new EventEmitter<any>();
  silentNotificationPestAcitivtyFlag = new EventEmitter<any>();
  sensorStatusInitWiFiFlag = new EventEmitter<any>();
  sensorStatusSuccessWiFiFlag = new EventEmitter<any>();
  sensorStatusOfflineFlag = new EventEmitter<any>();
  sensorStatusIdleFlag = new EventEmitter<any>();
  siteAccessGrantedFlag = new EventEmitter<any>();
  siteAccessRevokedFlag = new EventEmitter<any>();
  sensorDetectionsResolvedFlag = new EventEmitter<any>();
  zoneDetectionsResolvedFlag = new EventEmitter<any>();
  siteDetectionsResolvedFlag = new EventEmitter<any>();

  // profileUpdatedFlag = new EventEmitter<any>();
  // notificationSilderToggelFlag = new EventEmitter<any>();
  // allnotificationFlag = new EventEmitter<any>();
  // notifyEvent = new EventEmitter<any>();
  // filterEvent = new EventEmitter<any>();
  // aqiEvent = new EventEmitter<any>();
  // radarEvent = new EventEmitter<any>();
  // flyEvent = new EventEmitter<any>();
  // sensorListEvent = new EventEmitter<any>();
  // sensorStatusEvent = new EventEmitter<any>();

  private userDetails = new ReplaySubject<any>();
  _getUserDetails = this.userDetails.asObservable();
  // User Details
  _sendUserDetails(data){
    this.userDetails.next(data);
  }

  private notificationCounts = new ReplaySubject<any>();
  _getNotificationCount = this.notificationCounts.asObservable();
  // Notification Count
  _sendNotificationCount(data){
    this.notificationCounts.next(data);
  }

  private notificationSlider = new ReplaySubject<any>();
  _getNotificationFlag = this.notificationSlider.asObservable();
  // Notification Slider toggle
  _sendNotificationFlag(data){
    this.notificationSlider.next(data);
  }

  private calendarFilter = new ReplaySubject<any>();
  _getCalendarFilter = this.calendarFilter.asObservable();
  // calendar filter Obj
  _sendCalendarFilter(data){
    this.calendarFilter.next(data);
  }

  private sensorNameFlag = new ReplaySubject<any>();
  _getSensorNameFlag = this.sensorNameFlag.asObservable();
  // sensor NameFlag
  _sendSensorNameFlag(data){
    this.sensorNameFlag.next(data);
  }

  private customerNameFlag = new ReplaySubject<any>();
  _getCustomerNameFlag = this.customerNameFlag.asObservable();
  // customer NameFlag
  _sendCustomerNameFlag(data){
    this.customerNameFlag.next(data);
  }

  private siteNameFlag = new ReplaySubject<any>();
  _getSiteNameFlag = this.siteNameFlag.asObservable();
  // site NameFlag
  _sendSiteNameFlag(data){
    this.siteNameFlag.next(data);
  }

  private zoneNameFlag = new ReplaySubject<any>();
  _getZoneNameFlag = this.zoneNameFlag.asObservable();
  // Zone NameFlag
  _sendZoneNameFlag(data){
    this.zoneNameFlag.next(data);
  }

  constructor(private _http: HttpClient, private _authService: AuthenticationService) { }
  postData(body: any, url: string, headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.post(url, body, { headers });
  }
  
  upadteData(body: any, url: string, headers?: HttpHeaders) {
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.put(url, body, { headers });
  }

  postDataNoHeader(body: any, url: string, headers: HttpHeaders) {
    return this._http.post(url, body, { headers });
  }

  getData(url: string, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    // let newHeaders:HttpHeaders;
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    // console.log(headers);
    return this._http.get(url, { headers });
  }

  getBOneData(url: string, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    headers = headers.append('username', 'bonesuperadmin');
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.get(url, { headers });
  }

  getDataIoPUserID(url: string, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    // let newHeaders:HttpHeaders;
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    // console.log(headers);
    return this._http.get(url, { headers });
  }

  deleteData(url, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.delete(url + id, { headers });
  }

  removeSensor(url) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.delete(url, { headers });
  }

  getNormalHttpData(url) {
    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append("Access-Control-Allow-Origin", "*");
    // , { headers }
    return this._http.get(url);
  }

  getBuildVersion(url) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('content-type', 'application/json');
    return this._http.get(url, { headers });
  }

  postDataOnlyIOPHeader(body: any, url: string, headers: HttpHeaders = new HttpHeaders()) {
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.post(url, body, { headers });
  }

  putDataOnlyIOPHeader(body: any, url: string, headers: HttpHeaders = new HttpHeaders()) {
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.put(url, body, { headers });
  }

  deleteDataOnlyIOPHeader(url, headers: HttpHeaders = new HttpHeaders()) {
    headers = headers.append('username', this._authService.getUserName());
    headers = headers.append('tz-id', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this._http.delete(url, { headers });
  }

  // get sensor image for display
  getSensorImage(type) {
    switch (type.toLowerCase().trim()) {
      case 'Rodent Activity' .toLowerCase():
      case 'Rodent Detection' .toLowerCase():  
        return 'icon-rodentdetections';
      case 'Rodent'.toLowerCase():
        return 'icon-rodent';
      case 'Rodent Sensor'.toLowerCase():
        return 'icon-rodent';
      case 'Mosquito'.toLowerCase():
        return 'icon-Mosquito';
      case 'Cockroach Activity' .toLowerCase():
      case 'Cockroach Detection' .toLowerCase():    
        return 'icon-cockroachdetections';
      case 'Cockroach'.toLowerCase():
        return 'icon-cockroach';
      case 'Cockroach Sensor'.toLowerCase():
        return 'icon-cockroach';
      case 'FLY_HOUSEFLY'.toLowerCase():
        return 'icon-fly';
      case 'FLY_OPTICAL'.toLowerCase():
        return 'icon-fly';
      case 'House Fly'.toLowerCase():
        return 'icon-fly';
      case 'House-Fruit'.toLowerCase():
        return 'icon-fly';
      case 'Fly Counter'.toLowerCase():
        return 'icon-fly';
      case 'Fly Activity'.toLowerCase():
      case 'Fly Detection' .toLowerCase():     
        return 'icon-flydetections';      
      case 'Fly'.toLowerCase():
        return 'icon-fly';   
      case 'aqi'.toLowerCase():
        return 'icon-AirQuality'
      case 'Cigarette Beetle'.toLowerCase():
      case 'Cigarette Beetle Detection'.toLowerCase():  
        return 'icon-cigarettebeetledetections'
      default:
        return 'icon-Mosquito';
    }
  }

  // set time diff
  getCurrentTimeDiff(DBTimestamp){
    if(DBTimestamp){

      let localTime = new Date();
      let localTimestamp = localTime.getTime();
      let difference = localTimestamp - DBTimestamp;

      let checkNagtiveValue = Math.sign(difference);

      if(checkNagtiveValue > 0) {
        let day = 1000 * 60 * 60 * 24;
        let days = Math.floor(difference/day);
        let weeks = Math.floor(days/7) // week different
        let years = Math.floor(days/365); // year different
        weeks = Math.abs(weeks);
        years = Math.abs(years);
        let daysDifference = Math.floor(difference/1000/60/60/24);
        difference -= daysDifference*1000*60*60*24;
    
        let hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60;
    
        let minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60;
    
        let secondsDifference = Math.floor(difference/1000) ? Math.floor(difference/1000) : 1;
    
        const timeObj = { yearsDifference : years, weeksDifference : weeks, daysDifference : daysDifference, hoursDifference : hoursDifference, minutesDifference : minutesDifference, secondsDifference : secondsDifference };
    
        // console.log('difference = ' + 
        //   daysDifference + ' day/s ' + 
        //   hoursDifference + ' hour/s ' + 
        //   minutesDifference + ' minute/s ' + 
        //   secondsDifference + ' second/s ');
        return timeObj;

      }else {

        const timeObj = { yearsDifference : null, weeksDifference : null, daysDifference : null, hoursDifference : null, minutesDifference : null, secondsDifference : 1 };
        return timeObj;

      }

    }else {

      const timeObj = { yearsDifference : 'N/A', weeksDifference : 'N/A', daysDifference : 'N/A', hoursDifference : 'N/A', minutesDifference : 'N/A', secondsDifference : 'N/A'};
      return timeObj;

    }
   
  }


// get permissions deatils  
  getPermissionDetails(){
    const permissionsDetail = [ 
      {
          permissionId : 1,
          resourceName : "Alerts",
          actionName : "View"
      },
      {
          permissionId : 2,
          resourceName : "Detections",
          actionName : "View"
      },
      {
          permissionId : 3,
          resourceName : "Detections",
          actionName : "Edit"
      },{
          permissionId : 4,
          resourceName : "Sensors",
          actionName : "View"
      },{
          permissionId : 5,
          resourceName : "Sensors",
          actionName : "Edit"
      },{
          permissionId : 6,
          resourceName : "Sensors",
          actionName : "Create"
      },{
          permissionId : 7,
          resourceName : "Customers",
          actionName : "View"
      },{
          permissionId : 8,
          resourceName : "Customers",
          actionName : "Edit"
      },{
          permissionId : 9,
          resourceName : "Customers",
          actionName : "Create"
      },{
          permissionId : 10,
          resourceName : "Reports",
          actionName : "View"
      },{
          permissionId : 11,
          resourceName : "Users",
          actionName : "View"
      },{
          permissionId : 12,
          resourceName : "Users",
          actionName : "Edit"
      },{
          permissionId : 13,
          resourceName : "Users",
          actionName : "Create"
      },{
        permissionId : 14,
        resourceName : "Roles",
        actionName : "View"
      },{
          permissionId : 15,
          resourceName : "Roles",
          actionName : "Edit"
      },{
          permissionId : 16,
          resourceName : "Roles",
          actionName : "Create"
      },{
        permissionId: 17, 
        resourceName: "Sites", 
        actionName: "View"
      },{
        permissionId: 18, 
        resourceName: "Sites", 
        actionName: "Edit"
      },{
        permissionId: 19, 
        resourceName: "Sites", 
        actionName: "Create"
      },{
        permissionId: 20, 
        resourceName: "Settings", 
        actionName: "View"
      },{
        permissionId: 21, 
        resourceName: "Settings", 
        actionName: "Edit"
      },{
        permissionId: 22, 
        resourceName: "Live Monitoring Toggle", 
        actionName: "Edit"
      }
    ];
    return permissionsDetail;
  }
  
}