export const japaneseTranslate = {
    "dashboard": "ダッシュボード",
    "sensors": "センサー",
    "users": "ユーザー",
    "customers": "お客さま",
    "reports": "報告書",
    "total": "合計",
    "settings": "設定",
    "all": "すべて",
    "sensor id": "センサーID",
    "customer": "お客様",
    "site": "サイト",
    "site details": "サイト詳細",
    "zone": "ゾーン",
    "zone details": "ゾーンの詳細",
    "closed": "閉まっている",
    "scanner": "スキャナー",
    "scan": "スキャン",
    "sensor type": "センサータイプ",
    "add sensor": "センサーを追加",
    "sensor name": "センサー名",
    "zone map": "ゾーンマップ",
    "add user": "ユーザーを追加する",
    "all users": "すべてのユーザー",
    "superadmin": "スーパー管理者",
    "regional manager": "地域担当マネージャー",
    "branch manager": "支店長",
    "servicetechnician": "サービス技術者",
    "service technician": "サービス技術者",
    "custom users": "カスタムユーザー",
    "username": "ユーザー名",
    "email": "Eメール",
    "first name": "ファーストネーム",
    "last name": "苗字",
    "role": "役割",
    "actions": "行動",
    "resource allocation": "資源配分",
    "my profile": "私のプロフィール",
    "edit user": "ユーザーを編集",
    "update": "更新",
    "please enter your username": "ユーザー名を入力してください",
    "please enter site name": "サイト名を入力してください",
    "please enter your email": "あなたのメールアドレスを入力してください",
    "please enter valid email": "有効なメールIDを入力してください",
    "please enter your password": "パスワードを入力してください",
    "please confirm your password": "パスワードを確認してください",
    "passwords do not match": "パスワードが一致していません",
    "please enter your first name": "名を入力してください",
    "please enter your last name": "あなたの姓を入力",
    "please select language": "言語を選択してください",
    "please enter your timezone": "タイムゾーンを入力してください",
    "please enter your phone no.": "電話番号を入力してください",
    "please enter valid phone no.": "有効な電話番号を入力してください",
    "please enter your city": "あなたの街を入力してください",
    "please enter your state": "州を入力してください",
    "please enter your country": "国を入力してください",
    "please enter your address": "住所を入力してください",
    "please enter your zip code/postal code": "郵便番号を入力してください",
    "role name": "ロール名",
    "last updated at": "最終更新日",
    "create customer": "顧客を作成する",
    "resources": "資源",
    "sites": "サイト",
    "zones": "ゾーン",
    "address": "住所",
    "country": "国",
    "state": "状態",
    "city": "シティ",
    "zip code/postal code": "郵便番号/郵便番号",
    "contact person": "接触",
    "upload photo": "写真をアップする",
    "details": "詳細",
    "user details": "ユーザーの詳細",
    "enter user details": "ユーザーの詳細を入力",
    "offline": "オフライン",
    "rodent": "齧歯類",
    "glue board": "接着剤",
    "fly": "飛ぶ",
    "cockroach": "ゴキブリ",
    "Cockroach": "ゴキブリ",
    "Crawling Insect": "這う昆虫",
    "crawling insect": "這う昆虫",
    "add site": "サイトを追加",
    "site name": "サイト名",
    "select site type": "サイトタイプを選択",
    "air quality": "空気",
    "zone list": "ゾーン",
    "zone name": "ゾーン名",
    "select site": "サイトを選択",
    "edit profile": "編集",
    "edit theme": "テーマ",
    "change password": "パスワードを変更する",
    "new password": "古い",
    "confirm new password": "新着",
    "sign out": "ログ",
    "sign in": "ログイン",
    "enter username": "ユーザーネームを入力してください",
    "enter password": "パスワード",
    "password": "パスワード",
    "remember me": "覚えている",
    "forgot password?": "忘れた",
    "download csv file": "CSVファイルをダウンロード",
    "download pdf file": "PDFファイルをダウンロード",
    "download png file": "PNGファイルをダウンロード",
    "pest infestation": "害虫の侵入",
    "notification": "通知",
    "timeseries": "時系列",
    "add action": "アクションを追加",
    "online": "オンライン",
    "select customer": "顧客を選択",
    "select zone": "ゾーンを選択",
    "please choose a excel file": "Excelファイルを選択してください",
    "ok": "わかった",
    "clear": "晴れ",
    "delete": "削除する",
    "re-assign sensor": "センサーの再割り当て",
    "edit sensor": "センサーを編集",
    "update sensor": "センサーを更新",
    "sensor details": "センサーの詳細",
    "sensor unavailable": "センサーを利用できません",
    "sensor id unavailable": "センサーIDを使用できません",
    "sensor installed": "センサー搭載",
    "re assign sensor": "センサーの再割り当て",
    "re assigned successfully": "正常に再割り当てされました",
    "directional chart": "方向チャート",
    "no sensor available": "利用可能なセンサーがありません",
    "to add a sensor.": "センサーを追加します。",
    "select pest type": "害虫の種類を選択",
    "customer-site time series comparison": "顧客サイトの時系列比較",
    "hour": "時",
    "week": "週間",
    "month": "月",
    "year": "年",
    "notifications": "通知",
    "select sensor": "センサーを選択",
    "enable": "有効にする",
    "disable": "無効にする",
    "user successfully created.": "ユーザーが正常に作成されました。",
    "user successfully updated.": "ユーザーが正常に更新されました。",
    "customer successfully created.": "顧客が正常に作成されました。",
    "show customer list": "顧客リストを表示",
    "individual customer": "個人のお客様",
    "site successfully created.": "サイトが正常に作成されました。",
    "show site list": "サイトリストを表示",
    "show zone list": "ゾーンリストを表示",
    "choose  a activity category": "アクティビティカテゴリを選択してください",
    "processing steps": "処理手順",
    "assigned successfully": "正常に割り当てられました",
    "yes": "はい",
    "no": "番号",
    "updated successfully": "更新成功",
    "please choose a image file": "画像ファイルを選択してください",
    "no user available": "利用可能なユーザーはありません",
    "click here": "ここをクリック",
    "copy create role": "コピー作成ロール",
    "edit customer": "顧客を編集",
    "edit site": "サイトを編集",
    "day": "日",
    "today": "今日",
    "deleted successfully": "正常に削除されました",
    "created successfully": "うまく作成されました",
    "edited successfully": "正常に編集されました",
    "password changed successfully.": "パスワードは正常に変更されました。",
    "site successfully updated.": "サイトが正常に更新されました。",
    "customer successfully updated.": "顧客は正常に更新されました。",
    "select all": "すべて選択",
    "collapse": "崩壊",
    "expand": "展開する",
    "select language": "言語を選択する",
    "direction": "方向",
    "sensor": "センサー",
    "hour of the day": "一日の時間",
    "select timezone": "タイムゾーンを選択",
    "roles": "役割",
    "total count": "総数",
    "from": "から",
    "to": "に",
    "no.": "いや",
    "stop scan": "スキャンを停止",
    "aqi": "AQI",
    "at": "で",
    "edit role": "役割を編集",
    "updated by": "更新者",
    "updated at": "に更新",
    "created by": "によって作成された",
    "successfully sensor installed": "センサーが正常にインストールされました",
    "sensor updated successfully": "センサーは正常に更新されました",
    "custom role created successfully": "カスタムロールが正常に作成されました",
    "custom role edited successfully": "カスタムロールが正常に編集されました",
    "sensor already added": "センサーはすでに追加されています",
    "enter your pincode": "PINコードを入力してください",
    "enter your country": "あなたの国を入力してください",
    "file formats should be JPG/JPEG/PNG": "ファイル形式は JPG/JPEG/PNG である必要があります",
    "sensor(s) in the list were added successfully": "リスト内のセンサーが正常に追加されました",
    "enter your state": "州を入力してください",
    "enter your city": "あなたの街に入る",
    "enter your street": "通りに入る",
    "please select your timezone": "タイムゾーンを選択してください",
    "please enter zone name": "ゾーン名を入力してください",
    "please select role": "役割を選択してください",
    "please enter your customer name": "お客様名を入力してください",
    "year of establishment should lie between 1900 and ": "設立年は1900年から",
    "please enter your contact person": "担当者を入力してください",
    "pest type": "害虫の種類",
    "last updated": "最終更新",
    "please enter your current password": "現在のパスワードを入力してください",
    "Passwords must be between 6 and 12 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.": "パスワードは 6 ～ 12 文字で、大文字、小文字、数字、および記号のうち少なくとも 2 つが含まれている必要があります。",
    "please select customer (optional)": "顧客を選択してください（オプション",
    "sensor error": "センサーエラー",
    "please choose customer": "顧客を選択してください",
    "please choose site": "サイトを選択してください",
    "please choose zone": "ゾーンを選択してください",
    "please enter sensor name": "センサー名を入力してください",
    "please choose sensor type": "センサータイプを選択してください",
    "detail sensor": "詳細センサー",
    "no user avilable.": "利用可能なユーザーがいません",
    "AQI (last 30 days)": "AQI（過去30日間）",
    "rodent sensor": "齧歯類センサー",
    "AQI": "AQI",
    "please enter sensor id": "センサーIDを入力してください",
    "sensor already installed": "センサーはすでにインストールされています",
    "deployment type": "展開タイプ",
    "max length 200": "最大長200",
    "max length 20": "最大長20",
    "max length 8": "最大長8",
    "sensor name must be a minimum of 6 characters": "センサー名は6文字以上にする必要があります",
    "sensor name cannot be blank": "センサー名は空白にできません",
    "cancel": "キャンセル",
    "qr code scan": "QRコードスキャン",
    "file size should be less than 5 MB": "ファイルサイズは5 MB未満である必要があります",
    "successfully uninstalled the sensor": "センサーを正常にアンインストールしました",
    "successfully reassigned to selected customer": "選択した顧客に正常に再割り当てされました",
    "complete installation": "完全なインストール",
    "This Sensor is not currently active on the IoP system and cannot be used.": "このセンサーは現在IoPシステムでアクティブではなく、使用できません",
    "Invalid sensor ID! This sensor is already assigned elsewhere.": "無効なセンサーID！このセンサーはすでに別の場所に割り当てられています。",
    "This sensor is already installed on your account.": "このセンサーは既にアカウントにインストールされています。",
    "This sensor is already added to your account.": "このセンサーはすでにアカウントに追加されています。",
    "This sensor is has already been installed on the IoP system.": "このセンサーはすでにIoPシステムにインストールされています。",
    "Invalid sensor ID specified! Please enter a valid sensor ID for installation.": "無効なセンサーIDが指定されました！インストールに有効なセンサーIDを入力してください。",
    "error during get sensor metadata details : sensorId not available": "センサーメタデータの詳細の取得中にエラーが発生しました：sensorIdを使用できません",
    "no whitespace at the beginning and end": "先頭と末尾に空白がない",
    "date & time": "日付時刻",
    "manage roles": "役割を管理する",
    "reset": "リセット",
    "choose a reporting manager": "レポートマネージャーを選択する",
    "organisation": "組織",
    "create new custom role": "新しいカスタムの役割を作成する",
    "edit custom role": "カスタムの役割を編集",
    "parent role": "親の役割",
    "enter new custom role name": "新しいカスタムロール名を入力してください",
    "please enter custom role name": "カスタムの役割名を入力してください",
    "please enter different the custom role name": "別のカスタムロール名を入力してください",
    "enter the role description": "役割の説明を入力してください",
    "no role available": "使用可能な役割はありません",
    "permission": "許可",
    "create": "つくる",
    "view": "見る",
    "please enter a valid mobile number": "有効な電話番号を入力して下さい",
    "clone": "クローン",
    "description": "説明",
    "delete role": "役割を削除する",
    "max length 225": "最大長225",
    "re-enter Password": "パスワードを再入力してください",
    "please enter re-enter password": "パスワードを再入力してください",
    "name": "名前",
    "reporting manager": "レポートマネージャー",
    "update user": "ユーザーの更新",
    "timezone": "タイムゾーン",
    "click to enable": "クリックして有効にする",
    "click to disable": "クリックして無効にします",
    "account overview": "口座[アカウント]状況",
    "reporting users": "ユーザーの報告",
    "detections": "検出",
    "sensors overview": "センサーの概要",
    "view all": "すべて表示",
    "pest detections": "害虫の検出",
    "all sensors": "すべてのセンサー",
    "new": "新着",
    "active": "アクティブ",
    "idle": "アイドル",
    "home": "家",
    "glue board details": "接着剤ボードの詳細",
    "added by": "によって追加",
    "added on": "追加された",
    "location description": "位置/説明",
    "sensor pictures": "センサー写真",
    "glue board id": "接着剤ボードID",
    "24 hrs": "24時間",
    "12 hrs": "12時間",
    "6 hrs": "6時間",
    "1 hr": "1時間",
    "1 week": "1週間",
    "1 month": "1ヶ月",
    "lifetime": "一生",
    "remove sensor": "センサーを取り外します",
    "What is the reason to remove this sensor?": "このセンサーを取り外す理由は何ですか？",
    "sensor damaged": "センサーが破損している",
    "other reason": "他の理由",
    "Yes, I confirm this sensor to be permanently removed": "はい、このセンサーが完全に取り外されていることを確認しました",
    "Enter Sensor ID or Scan QR code": "センサーIDを入力するかQRコードをスキャンします",
    "Enter Sensor name": "センサー名を入力してください",
    "select deployment type": "導入タイプの選択",
    "enter sensor location description": "センサーの場所の説明を入力してください",
    "sensor location description": "センサーの場所の説明",
    "Describe the placement of the sensor in words.": "センサーの配置を言葉で説明してください。",
    "add sensors": "センサーを追加する",
    "basic details": "基本的な詳細",
    "select sensors": "センサーを選択",
    "review & complete": "レビューと完了",
    "number of sensors to be added": "追加するセンサーの数",
    "please choose number of sensors": "センサーの数を選択してください",
    "back": "バック",
    "save & continue": "保存して続行",
    "I confirm above sensors to be added to my account.": "上記のセンサーがアカウントに追加されることを確認しました。",
    "max length 255": "最大長255",
    "please choose pest type": "害虫の種類を選択してください",
    "last seen": "最後に見たのは",
    "Unavailable": "利用できません",
    "comm status": "状態",
    "Comm Status": "状態",
    "sensor images": "センサー画像",
    "proceed": "続行します",
    "are you sure want to cancel it ?": "キャンセルしてもよろしいですか？",
    "weeks": "週",
    "days": "日々",
    "hours": "時間",
    "customer information": "顧客情報",
    "Enter Customer Name": "顧客名を入力してください",
    "contact person details": "担当者の詳細",
    "I confirm to add this new customer to my account": "この新しい顧客を自分のアカウントに追加することを確認します",
    "Update Avatar": "アバターを更新する",
    "Enter Email ID": "メールIDを入力してください",
    "enter phone number": "電話番号を入力してください",
    "enter contact person name": "担当者名を入力してください",
    "all customers": "すべてのお客様",
    "all sites": "すべてのサイト",
    "all zones": "すべてのゾーン",
    "customer, site, zone": "顧客、サイト、ゾーン",
    "manage sites": "サイトの管理",
    "postal code": "郵便番号",
    "phone": "電話",
    "enter address": "住所を入力してください",
    "phone number": "電話番号",
    "email id": "電子メールID",
    "customer address": "お客様の住所",
    "Manage Sites and Zones": "サイトとゾーンを管理する",
    "contact person name": "担当者名",
    "I confirm to add this new customer to my account, and to all the reporting managers above me.": "この新しい顧客を自分のアカウントと、私の上のすべてのレポートマネージャーに追加することを確認します。",
    "This role name is already taken. Please try a different one!": "この役割名はすでに使用されています。別のものを試してみてください！",
    "I confirm to add the above sensors to my account, and to all the managers of this site.": "上記のセンサーを自分のアカウントとこのサイトのすべての管理者に追加することを確認します。",
    "please select the checkbox": "チェックボックスを選択してください",
    "remove customer": "顧客を削除する",
    "customer removed successfully": "お客様が正常に削除されました",
    "please enter valid sensor id": "有効なセンサー識別子を入力してください",
    "This site name is already taken. Please try a different one!": "このサイト名はすでに使用されています。別のものを試してみてください！",
    "This zone name is already taken. Please try a different one!": "このゾーン名はすでに使用されています。別のものを試してみてください！",
    "This sensor name is already taken. Please try a different one!": "このセンサー名はすでに使用されています。別のものを試してみてください！",
    "sensor name already added": "センサー名はすでに追加されています",
    "24 Hours": "24時間",
    "No crawl directional chart event detection.": "クロール方向チャートイベントの検出はありません。",
    "This customer name is already taken. Please try a different one!": "この顧客名はすでに使用されています。別のものを試してみてください！",
    "resolved": "解決済み",
    "resolve": "解決する",
    "Recent Detection": "最近の検出",
    "detection log": "検出ログ",
    "time stamp": "タイムスタンプ",
    "detection details": "検出の詳細",
    "new detection": "新しい検出",
    "new detections": "新しい検出",
    "no detections": "検出なし",
    "Proof of Detection Resolution": "検出分解能の証明",
    "I have completed the pest management and sensor services required to resolve this detection.": "この検出を解決するために必要な害虫管理とセンサーサービスを完了しました。",
    "Did you find any evidence of presence of pest?": "害虫の存在の証拠を見つけましたか？",
    "Successfully resolved activity": "正常に解決されたアクティビティ",
    "Resolution Information": "解決情報",
    "Resolution Number": "決議番号",
    "resolved by": "によって解決",
    "resolved on": "解決済み",
    "Date": "日付",
    "Time": "時間",
    "view location": "場所を表示",
    "Detection has been successfully resolved.": "検出は正常に解決されました。",
    "sensor is malfunctioning": "センサーが誤動作している",
    "sensor is no longer required": "センサーは不要になりました",
    "This sensor will be marked as malfunctioning and removed permanently. The removed sensor will not be allowed to be re-added.": "このセンサーは故障としてマークされ、永久に削除されます。削除されたセンサーを再度追加することはできません。",
    "This sensor will be removed and can be later added back as required.": "このセンサーは削除され、必要に応じて後で追加し直すことができます。",
    "I confirm to permanently remove this sensor.": "このセンサーを完全に取り外すことを確認します。",
    "I confirm to remove this sensor.": "このセンサーを取り外すことを確認しました。",
    "The sensor has been permanently removed": "センサーは完全に取り外されました",
    "The sensor has been removed successfully": "センサーは正常に削除されました",
    "date range": "日付範囲",
    "no Glue board details": "接着剤ボードの詳細なし",
    "replace sensor": "センサーを交換してください",
    "basic information": "基本情報",
    "sensor replacement": "センサーの交換",
    "Glueboard detection breach limit": "グルーボード検出違反制限",
    "Glueboard duration breach limit": "グルーボード持続時間違反制限",
    "Communication status": "通信状態",
    "pictures": "ピクチャー",
    "What is the Reason behind to replace the sensor?": "センサーを交換する理由は何ですか？",
    "Sensor malfunctioning": "センサーの誤動作",
    "Sensor Damaged": "センサーが損傷している",
    "Sensor Lost": "センサーが失われました",
    "Technician can give the statement of the existing sensor condition.": "技術者は、既存のセンサーの状態を説明できます。",
    "Please describe below": "以下に説明してください",
    "confirm & repalce": "確認と交換",
    "enter the new sensor details": "新しいセンサーの詳細を入力します",
    "I confirm to add the new sensor in the place of the sensor selected for replacement. You cannot undo this action once completed.": "交換用に選択したセンサーの代わりに新しいセンサーを追加することを確認します。完了したら、このアクションを元に戻すことはできません。",
    "The sensor has been replaced successfully!": "センサーは正常に交換されました！",
    "View Sensor list": "センサーリストを見る",
    "breach limits": "違反制限",
    "glueboard": "接着剤ボード",
    "Default No. of days for the glueboard expiry": "グルーボードの有効期限のデフォルトの日数",
    "Default max fly detections allowed on the glueboard": "グルーボードで許可されるデフォルトの最大フライ検出",
    "please enter No. of days for the glueboard expiry": "接着剤の有効期限の日数を入力してください",
    "please enter max fly detections allowed on the glueboard": "グルーボードで許可されている最大フライ検出を入力してください",
    "Default fly detections limit": "デフォルトのハエ検出制限",
    "Default Rodent detections limit": "デフォルトの齧歯動物の検出制限",
    "Please Enter Fly Critical Control Limit": "フライクリティカルコントロールリミットを入力してください",
    "Please Enter Crawling Insect Critical Control Limit": "這う昆虫の重要管理限界を入力してください",
    "Please Enter Rodent Critical Control Limit": "齧歯動物の重要な管理限界を入力してください",
    "language": "言語",
    "No. of days for the glueboard expiry": "グルーボードの有効期限の日数」：「いいえ。接着剤の有効期限の日数",
    "max fly detections allowed on the glueboard": "グルーボードで許可される最大のフライ検出",
    "Default No. of days for the glueboard expiry between 1 to 999": "グルーボードの有効期限が1〜999のデフォルトの日数",
    "Default max fly detections allowed on the glueboard between 1 to 999999": "1〜999999のグルーボードで許可されるデフォルトの最大フライ検出",
    "Default fly detections limit between 1 to 999999": "デフォルトのフライ検出の制限は1〜999999です。",
    "Default Crawling Insect detections limit between 1 to 999999": "デフォルトのクロール昆虫検出数は 1 から 999999 の間で制限されます",
    "Default Rodent detections limit between 1 to 999999": "デフォルトの齧歯動物の検出は1から999999の間に制限されます",
    "This sensor will be marked as malfunctioning and removed permanently. The replaced sensor will not be allowed to be re-added.": "このセンサーは故障としてマークされ、永久に削除されます。交換したセンサーを再度追加することはできません。",
    "max fly detections allowed on the glueboard between 1 to 999999": "1〜999999のグルーボードで許可される最大フライ検出",
    "No. of days for the glueboard expiry between 1 to 999": "グルーボードの有効期限が1〜999の日数",
    "new sensors": "新しいセンサー",
    "active sensors": "アクティブセンサー",
    "idle sensors": "アイドルセンサー",
    "offline sensors": "オフラインセンサー",
    "breach": "違反",
    "to create a customer": "顧客を作成するには",
    "Replacing sensor should be of the same pest type.": "交換センサーは同じ害虫タイプでなければなりません。",
    "site type": "サイトタイプ",
    "No customer available.": "利用可能な顧客はありません。",
    "no site available": "利用可能なサイトはありません",
    "site event log": "サイトイベントログ",
    "add site & zone": "サイトとゾーンを追加",
    "add more": "さらに追加",
    "enter state": "状態に入る",
    "Enter zip code/postal code": "郵便番号を入力",
    "enter site name": "サイト名を入力",
    "enter city": "市に入る",
    "enter country": "国を入力してください",
    "service team": "サービスチーム",
    "user name": "ユーザー名",
    "I confirm to add this new site to my account, service team and to all the reporting managers.": "この新しいサイトを私のアカウント、サービス チーム、およびすべてのレポート マネージャーに追加することを確認します。",
    "please select timezone": "タイムゾーンを選択してください",
    "site information": "サイト情報",
    "edit site & zone": "サイトとゾーンを編集",
    "Fly Glueboard Settings": "FlyGlueboardの設定",
    "Critical Control Limits": "重要な管理限界",
    "Default Fly Critical Control Limit of the Zone": "ゾーンのデフォルトのフライクリティカルコントロール制限",
    "Default Crawling Insect Critical Control Limit of the Zone": "ゾーンのデフォルトの這う昆虫の重要管理限界",
    "Default Rodent Critical Control Limit of the Zone": "ゾーンのデフォルトの齧歯類の重要な管理限界",
    "resolved detections": "解決された検出",
    "is Breached on": "に違反しています",
    "is Breached at": "で違反されています",
    "Fly Glueboard Detections": "フライグルーボードの検出",
    "Zone Rodent Critical Control Limit": "ゾーン齧歯動物の重要な管理限界",
    "Zone Fly Critical Control Limit": "ゾーンフライクリティカルコントロールリミット",
    "Zone Crawling Insect Critical Control Limit": "ゾーンゴキブリの重要な管理限界",
    "manage zones": "ゾーンの管理",
    "breach limit": "違反制限",
    "click": "クリック",
    "This service team member is already taken. Please try a different one!": "このサービスチームのメンバーはすでに採用されています。別のものを試してみてください！",
    "add team member": "チームメンバーを追加する",
    "no service team available": "利用可能なサービスチームはありません",
    "to add team member": "チームメンバーを追加するには",
    "revoke access": "アクセス権を取り消す",
    "updated on": "に更新",
    "add member": "メンバーを追加",
    "number of sites assigned": "割り当てられたサイトの数",
    "select user": "ユーザーを選択",
    "please select user": "ユーザーを選択してください",
    "zone detection resolution": "ゾーン検出の解像度",
    "action": "アクション",
    "site detection resolution": "サイト検出の解決",
    "I have completed the pest management and sensor services required to resolve the new detections in this site.": "このサイトでの新しい検出を解決するために必要な害虫管理とセンサーサービスを完了しました。",
    "Sensor Detection Resolution": "センサー検出解像度",
    "Grant Access": "アクセス許可",
    "Add team member to the site": "チームメンバーをサイトに追加する",
    "I confirm to grant site access to this member and to all the reporting managers.": "このメンバーとすべてのレポートマネージャーにサイトアクセスを許可することを確認します",
    "has been granted access to the selected site": "選択したサイトへのアクセスが許可されました",
    "Remove team member from the site": "サイトからチームメンバーを削除する",
    "I confirm to revoke site access to this member.": "このメンバーへのサイトアクセスを取り消すことを確認します。",
    "has been revoked access to the selected site": "選択したサイトへのアクセスが取り消されました",
    "detected": "検出されました",
    "house flies detected": "イエバエが検出されました",
    "fruit flies detected": "ショウジョウバエが検出されました",
    "total flies detected": "検出されたハエの総数",
    "notifications center": "通知センター",
    "Edit Service Team Members for the site by changing users below": "以下のユーザーを変更して、サイトのサービスチームメンバーを編集します",
    "Add Service Team Members for the site by choosing users below": "以下のユーザーを選択して、サイトのサービスチームメンバーを追加します",
    "please use different username": "別のユーザー名を使用してください",
    "is": "です",
    "edit": "編集",
    "replace": "交換してください",
    "remove": "削除する",
    "Zone Information": "ゾーン情報",
    "User": "ユーザー",
    "important notifications": "重要なお知らせ",
    "confirmation": "確認",
    "Are you sure you want to mark all the new notifications as read?": "すべての新しい通知を既読としてマークしてもよろしいですか？",
    "Yes, Proceed": "はい、続行します",
    "The new notifications has been successfully cleared. Those notifications can be still accessed from the Notifications Center.": "新しい通知は正常にクリアされました。これらの通知には、通知センターから引き続きアクセスできます。",
    "Site Detections Resolved by": "サイトの検出が解決されました",
    "Zone Detections Resolved by": "によって解決されたゾーン検出",
    "New Fly Detections at Site": "サイトでの新しいハエの検出",
    "New Crawling Insect Detections at Site": "サイトでの新しい這う昆虫の検出",
    "New Rodent Detections at Site": "サイトでの新しい齧歯動物の検出",
    "Fly Sensors": "フライセンサー",
    "Rodent Sensors": "げっ歯類センサー",
    "zone detections resolved": "ゾーン検出が解決されました",
    "site detections resolved": "サイトの検出が解決されました",
    "by": "沿って",
    "is Breached!": "違反しています！",
    "Breached!": "違反しました！",
    "All Notifications": "すべての通知",
    "Breach Alerts": "違反アラート",
    "Site Events": "サイトイベント",
    "Sensor Events": "センサーイベント",
    "Mark all as read": "すべて既読としてマーク",
    "I have completed the pest management and sensor services required to resolve the new detections in this zone.": "このゾーンでの新しい検出を解決するために必要な害虫管理とセンサーサービスを完了しました。",
    "total sensors": "トータルセンサー",
    "IoP® Dashboard": "IoP®ダッシュボード",
    "Fly Detections": "フライ検出",
    "Rodent Detections": "げっ歯類の検出",
    "Fly Detection": "フライ検出",
    "Rodent Detection": "齧歯動物の検出",
    "Fly Trend": "フライトレンド",
    "Rodent Trend": "げっ歯類の傾向",
    "New Detections by Pest Type": "害虫の種類による新しい検出",
    "Detections - New vs Resolved": "検出-新規vs解決済み",
    "View All New Detections": "すべての新しい検出を表示",
    "Sensors grouped by Pest Type": "害虫の種類ごとにグループ化されたセンサー",
    "View All Sensors": "すべてのセンサーを表示",
    "Sensors grouped by Communication Status": "通信ステータスごとにグループ化されたセンサー",
    "© 2022 Evoscien. All Rights Reserved.": "© 2022 Evoscien。全著作権所有。",
    "There are": "がある",
    "more new notifications,": "より多くの新しい通知、",
    "detections overview": "検出の概要",
    "Fly Sensor Detections Resolved by": "によって解決されたフライセンサーの検出",
    "Fly Sensor Detections Resolved": "フライセンサーの検出が解決されました",
    "New Fly Detections": "新しいハエの検出",
    "New Rodent Detections": "新しい齧歯動物の検出",
    "create site": "サイトを作成する",
    "to create a site.": "サイトを作成します。",
    "Add Zone Map": "ゾーンマップの追加",
    "please enter the role description": "役割の説明を入力してください",
    "Sensor Detections Resolved by": "によって解決されたセンサー検出",
    "sensor detections resolved": "センサー検出が解決されました",
    "current password": "現在のパスワード",
    "reset password": "パスワードを再設定する",
    "Additional Comments": "追加コメント",
    "Do you want to resolve the detections on this site?": "このサイトでの検出を解決しますか？",
    "Do you want to resolve the detections on this sensor?": "このセンサーの検出を解決しますか？",
    "Do you want to resolve the detections on this zone?": "このゾーンでの検出を解決しますか？",
    "Yes, Resolve": "はい、解決します",
    "No, Cancel": "いいえ、キャンセルします",
    "pest infestation summary": "害虫の蔓延の概要",
    "time series comparison": "時系列比較",
    "Time Series": "時系列",
    "Cumulative Sum": "累積合計",
    "Hours Distribution": "時間配分",
    "Percentage": "パーセンテージ",
    "Trend is unavailable for the current selection criteria.": "現在の選択基準ではトレンドは利用できません。",
    "Data Aggregation By": "によるデータ集約",
    "Detections Report": "検出レポート",
    "- Create Customer -": "顧客を作成する",
    "Critical Control Limit": "重要な管理限界",
    "username must be a minimum of 6 characters": "ユーザー名は6文字以上である必要があります",
    "max length 12": "最大長さ12",
    "create user": "ユーザーを作成",
    "to create a user": "ユーザーを作成するには",
    "We use cookies to ensure that we give you the best experience on our website.": "私たちはあなたに私たちのウェブサイトで最高の体験を提供することを確実にするためにクッキーを使用します。",
    "By closing this message, you consent to our use of cookies on this device in accordance with our privacy policy unless you have disabled them in your browser settings.": "このメッセージを閉じることにより、ブラウザの設定でCookieを無効にしない限り、プライバシーポリシーに従ってこのデバイスでCookieを使用することに同意したことになります。",
    "I Agree!": "同意します！",
    "View Privacy Policy": "プライバシーポリシーを見る",
    "privacy policy": "プライバシーポリシー",
    "Brandenburg UK LTD is part of the Brandenburg Group which includes Brandenburg NA LLC and Brandenburg India Pvt Ltd. The Brandenburg Group is committed to complying with the relevant data protection legislations. In this privacy notice we will explain how our organisation uses the personal data we collect from you when you use our website.": "Brandenburg UK LTDは、Brandenburg NALLCおよびBrandenburgIndia PvtLtdを含むBrandenburgGroupの一部です。BrandenburgGroupは、関連するデータ保護法の遵守に取り組んでいます。このプライバシー通知では、お客様が当社のWebサイトを使用する際に、お客様から収集した個人データを当社の組織がどのように使用するかについて説明します。",
    "Brandenburg Group is the data controller for this website.": "Brandenburg Groupは、このWebサイトのデータ管理者です。",
    "What data we collect and how we collect it": "収集するデータとその収集方法",
    "Brandenburg Group collects the following data personally identifiable information directly from yourself;": "Brandenburg Groupは、以下のデータを個人を特定できる情報から直接収集します。",
    "Name": "名前",
    "Email address": "電子メールアドレス",
    "This information is provided by yourself when completing our contact form.": "この情報は、お問い合わせフォームに記入する際にご自身で提供されます。",
    "We also use a number of third party resources on the website that collect information about your usage on the site through the use of cookies.": "また、Cookieを使用してサイトでの使用状況に関する情報を収集する、Webサイト上の多数のサードパーティリソースを使用します。",
    "How will we use your data?": "お客様のデータをどのように使用しますか？",
    "Brandenburg Group collects personal data through the website so that we can communicate with you regarding our products and services. This may include relevant marketing materials.": "Brandenburg Groupは、当社の製品およびサービスに関してお客様と連絡を取ることができるように、Webサイトを通じて個人データを収集します。これには、関連するマーケティング資料が含まれる場合があります。",
    "In submitting this information through our form, you give us consent to use your data as described above. We hold the data under legitimate interest such that we can contact you about legitimate opportunities.": "フォームからこの情報を送信する場合、上記のようにデータを使用することに同意したことになります。当社は、正当な機会についてお客様に連絡できるように、正当な利益の下でデータを保持します。",
    "Data sharing": "データ共有",
    "We work with a number of third parties to deliver our services and share your data with these parties when necessary to fulfil our service to you.": "当社は多くの第三者と協力して当社のサービスを提供し、お客様へのサービスを遂行するために必要な場合はこれらの当事者とお客様のデータを共有します。",
    "Brandenburg will never sell your data and the third parties that we work with do not have implied consent to send you marketing materials, only to process your information for the purpose of fulfilling a service.": "ブランデンブルクは決してあなたのデータを販売することはなく、私たちが協力する第三者はあなたにマーケティング資料を送ることに暗黙の同意を持っておらず、サービスを遂行する目的であなたの情報を処理するだけです。",
    "How do we protect your data?": "データをどのように保護しますか？",
    "We utilise industry standard encryption on our website to protect your data. We also implement other relevant technical and organisational measures to protect data.": "当社は、お客様のデータを保護するために、当社のWebサイトで業界標準の暗号化を利用しています。また、データを保護するために、その他の関連する技術的および組織的対策を実施します。",
    "What are your data protection rights?": "あなたのデータ保護権は何ですか？",
    "Brandenburg Group would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:": "Brandenburg Groupは、お客様がすべてのデータ保護権を完全に認識していることを確認したいと考えています。すべてのユーザーは、次の権利があります。",
    "The right to access": "アクセスする権利",
    "You have the right to request Brandenburg Group for copies of your personal data. We may charge you a small fee for this service.": "あなたには、あなたの個人データのコピーをブランデンブルクグループに要求する権利があります。このサービスには少額の料金がかかる場合があります。",
    "The right to rectification": "是正する権利",
    "You have the right to request that Brandenburg Group correct any information you believe is inaccurate. This also forms part of your responsibility to ensure that our records for you are kept up to date and relevant.": "あなたには、BrandenburgGroupにあなたが不正確であると信じる情報を訂正するよう要求する権利があります。これはまた、あなたのための私たちの記録が最新で関連性のあるものに保たれることを保証するあなたの責任の一部を形成します。",
    "The right to erasure": "消去する権利",
    "You have the right to request that Brandenburg Group erase your personal data, under certain conditions.": "あなたには、特定の条件下で、ブランデンブルクグループがあなたの個人データを消去することを要求する権利があります。",
    "The right to restrict processing": "処理を制限する権利",
    "You have the right to request that Brandenburg Group restrict the processing of your personal data, under certain conditions.": "お客様には、特定の条件下で、BrandenburgGroupがお客様の個人データの処理を制限するよう要求する権利があります。",
    "The right to object to processing": "処理に異議を唱える権利",
    "You have the right to object to Brandenburg Group’s processing of your personal data, under certain conditions.": "    お客様には、特定の条件下で、BrandenburgGroupによるお客様の個人データの処理に異議を唱える権利があります。",
    "The right to data portability": "データの移植性に対する権利",
    "You have the right to request that Brandenburg Group transfer the data that we have collected to another organization, or directly to you, under certain conditions.": "お客様には、特定の条件下で、BrandenburgGroupが収集したデータを別の組織に転送するか直接お客様に転送するよう要求する権利があります。",
    "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:": "ご要望がございましたら、1ヶ月以内に対応させていただきます。これらの権利のいずれかを行使したい場合は、当社の電子メールでご連絡ください。",
    "Alternatively you can write to us. See the How to contact us section lower in this notice for additional contact details.": "または、私たちに手紙を書くこともできます。連絡先の詳細については、この通知の下にある「お問い合わせ方法」セクションを参照してください。",
    "Cookies": "クッキー",
    "Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.": "Cookieは、標準のインターネットログ情報と訪問者の行動情報を収集するためにコンピューターに配置されるテキストファイルです。お客様が当社のWebサイトにアクセスすると、Cookieまたは同様の技術を通じてお客様から情報を自動的に収集する場合があります。",
    "For further information, visit": "詳細については、次のWebサイトをご覧ください。",
    "How do we use cookies?": "クッキーはどのように使用しますか？",
    "Brandenburg Group uses cookies in a range of ways to improve your experience on our website, including:": "Brandenburg Groupは、次のようなさまざまな方法でCookieを使用してWebサイトでのエクスペリエンスを向上させています。",
    "Understanding how you use our website": "あなたが私たちのウェブサイトをどのように使用するかを理解する",
    "Providing features that are operated by a third party. For example, the hosting of video content.": "サードパーティが運営する機能を提供します。たとえば、ビデオコンテンツのホスティング。",
    "Remembering your preferences for cookies.": "クッキーの好みを覚えています。",
    "What types of cookies do we use?": "どのような種類のクッキーを使用していますか？",
    "There are a number of different types of cookies, however, our website uses:": "クッキーにはさまざまな種類がありますが、当社のWebサイトでは次のものを使用しています。",
    "Functionality": "機能性",
    "Brandenburg Group uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.": "Brandenburg Groupは、これらのCookieを使用して、当社のWebサイトでお客様を認識し、以前に選択した設定を記憶します。これらには、好みの言語と現在の場所が含まれる場合があります。ファーストパーティとサードパーティのCookieが混在して使用されます。",
    "Analytics": "分析",
    "Brandenburg Group use analytics tools to identify areas for improvement in the service that we provide. These cookies provide information such as landing/exit pages and the pages you visited whilst on the website so that we can identify any required improvements to content.": "Brandenburg Groupは、分析ツールを使用して、提供するサービスの改善点を特定します。これらのCookieは、コ​​ンテンツの必要な改善を特定できるように、ランディング/終了ページやWebサイトでアクセスしたページなどの情報を提供します。",
    "Advertising": "広告",
    "Brandenburg Group uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Brandenburg Group sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.": "Brandenburg Groupは、これらのCookieを使用して、当社のWebサイトへのアクセス、表示したコンテンツ、アクセスしたリンク、およびブラウザー、デバイス、IPアドレスに関する情報を収集します。 Brandenburg Groupは、広告目的でこのデータの一部の限定された側面を第三者と共有する場合があります。また、Cookieを通じて収集されたオンラインデータを広告パートナーと共有する場合があります。これは、別のWebサイトにアクセスすると、当社のWebサイトでの閲覧パターンに基づいて広告が表示される場合があることを意味します。",
    "How to manage cookies": "クッキーの管理方法",
    "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.": "クッキーを受け入れないようにブラウザを設定することができます。上記のウェブサイトでは、ブラウザからクッキーを削除する方法を説明しています。ただし、場合によっては、当社のWebサイト機能の一部が結果として機能しないことがあります。",
    "Google Analytics opt-out": "GoogleAnalyticsのオプトアウト",
    "If you wish to opt out of Google Analytics cookies completely, you can do so by following the instructions here:": "Google AnalyticsのCookieを完全にオプトアウトする場合は、次の手順に従ってください。",
    "Other websites": "他のウェブサイト",
    "The Brandenburg Group website contains links to other websites. We are not responsible for the privacy practices of these external sites therefore our privacy notice applies only to our website. If you click on a link to another website, you should read their privacy notice.": "Brandenburg GroupのWebサイトには、他のWebサイトへのリンクが含まれています。当社はこれらの外部サイトのプライバシー慣行について責任を負わないため、当社のプライバシー通知は当社のWebサイトにのみ適用されます。別のウェブサイトへのリンクをクリックした場合は、そのプライバシーに関する通知を読む必要があります。",
    "Underage data collection": "未成年者のデータ収集",
    "Brandenburg does not knowingly collect personally identifiable information from anyone under the age of 16. Any such data that is collected is removed.": "Brandenburgは、16歳未満の人から故意に個人を特定できる情報を収集することはありません。収集されたそのようなデータはすべて削除されます。",
    "Changes to our privacy notice": "プライバシーに関するお知らせの変更",
    "Brandenburg Group keeps its privacy notice under regular review and places any updates on this web page. This privacy notice was last updated on August 2nd 2021.": "Brandenburg Groupは、プライバシーに関する通知を定期的に確認し、このWebページに更新を掲載しています。このプライバシー通知の最終更新日は2021年8月2日です。",
    "How to contact us": "お問い合わせ方法",
    "If you have any questions about Brandenburg Group’s privacy notice, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.": "Brandenburg Groupのプライバシーに関する通知、当社が保持しているデータについて質問がある場合、またはデータ保護権のいずれかを行使したい場合は、遠慮なくご連絡ください。",
    "Email us at:": "で私達に電子メールを送りなさい：",
    "Or write to us at:": "または、次のアドレスまでご連絡ください。",
    "FAO: Data Protection": "FAO：データ保護",
    "Brandenburg UK Limited": "Brandenburg UK Limited",
    "29 Navigation Drive,": "29ナビゲーションドライブ、",
    "Hurst Business Park,": "ハーストビジネスパーク、",
    "Brierley Hill, West Midlands,": "ブライアリーヒル、ウェストミッドランズ、",
    "DY5 1UT": "DY5 1UT",
    "How to contact the appropriate authority": "適切な当局に連絡する方法",
    "Should you wish to report a complaint or if you feel that Brandenburg Group has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office. However we would request that you contact us directly first with the information provided above so that we can attempt to resolve your complaint first.": "苦情を報告したい場合、またはBrandenburg Groupが懸念に満足のいく方法で対処していないと思われる場合は、情報コミッショナーオフィスに連絡することができます。ただし、最初に苦情の解決を試みることができるように、上記の情報を最初に直接ご連絡ください。",
    "UK": "イギリス",
    "Information Commissioner's Office": "情報コミッショナーオフィス",
    "Wycliffe House": "ウィクリフハウス",
    "Water Lane": "ウォーターレーン",
    "Wilmslow": "ウィルムズロー",
    "Cheshire": "チェシャー",
    "SK9 5AF": "SK9 5AF",
    "Phone: 0303 123 1113": "電話番号：0303 123 1113",
    "Web address:": "ウェブアドレス：",
    "pest detections report": "害虫検出レポート",
    "all crawling insect": "すべてのクロール昆虫",
    "all rodents": "すべての齧歯動物",
    "all crawling insect sensors": "すべての這う昆虫センサー",
    "all rodent sensors": "すべての齧歯動物センサー",
    "all fly sensors": "すべてのフライセンサー",
    "Crawling Insect Critical Control Limit": "這う昆虫の重要管理限界",
    "Fly Critical Control Limit": "フライクリティカルコントロールリミット",
    "Rodent Critical Control Limit": "齧歯類の重要な管理限界",
    "max length 24": "最大長さ24",
    "min length 6": "最小長さ6",
    "year I": "I年",
    "year II": "II年",
    "week I": "週I",
    "week II": "II週目",
    "month I": "月I",
    "month II": "IIか月目",
    "day I": "1日目",
    "day II": "2日目",
    "comparison type": "比較タイプ",
    "Max Custom Date Range selection is 90 days at a stretch": "最大カスタム日付範囲の選択は一気に90日です",
    "Crawling Insect Cumulative Sum": "這う昆虫累計",
    "Rodent Cumulative Sum": "齧歯類の累積合計",
    "Fly Cumulative Sum": "累積合計を飛ばす",
    "glue board expiry": "接着剤ボードの有効期限",
    "Wi-Fi": "Wi-Fi",
    "max allowed on glueboard": "グルーボードで許可される最大値",
    "please select upto current month": "今月まで選択してください",
    "events log": "イベントログ",
    "event": "イベント",
    "month of the year": "今年の月",
    "day of the month": "月の日",
    "day of the week": "曜日",
    "Sensor Management": "センサー管理",
    "LPWAN": "LPWAN",
    "all detections": "すべての検出",
    "Glue Trap": "グルートラップ",
    "Standalone": "スタンドアロン",
    "Bait Station": "ベイトステーション",
    "max": "マックス",
    "years": "年",
    "hr": "時間",
    "hrs": "時間",
    "min": "分",
    "mins": "分",
    "sec": "秒",
    "secs": "秒",
    "ago": "前に",
    "days left": "残りの日数",
    "day left": "残り日",
    "Expired": "期限切れ",
    "days ago": "数日前",
    "day ago": "前日",
    "Entry from Left Side": "左側からのエントリー",
    "Entry from Right side": "右側からのエントリー",
    "Values denote the side of entry of rodent on the sensor": "値は、センサー上の齧歯動物の侵入側を示します",
    "Values denote the side of entry of crawling insect on the sensor": "値は、センサー上の這う昆虫の侵入側を示します",
    "Due to variations in size, the side of entry for": "サイズのばらつきにより、エントリー側は",
    "some rodent detections remains unidentified.": "一部のげっ歯類の検出は未確認のままです。",
    "events": "イベント",
    "added": "追加した",
    "replaced": "交換済み",
    "removed": "削除されました",
    "sensor idle": "センサーアイドル",
    "sensor active": "センサーアクティブ",
    "sensor offline": "センサーオフライン",
    "zone critical control limit breached": "ゾーンクリティカルコントロール制限に違反しました",
    "fly max detections breached": "フライマックス の検出に違反しました",
    "fly glueboard expiry breached": "フライグルーボードの有効期限が切れました",
    "sensor established connection": "センサー確立接続",
    "wifi configuration successful": "WiFi構成が成功しました",
    "fly glueboard replaced": "フライグルーボードを交換",
    "sensor name edited": "編集されたセンサー名",
    "sensor pictures edited": "編集されたセンサー画像",
    "location description edited": "場所説明編集済み",
    "Detections between": "間の検出",
    "in": "の",
    "Hrs": "時間",
    "on": "の上",
    "during": "その間",
    "between": "の間に",
    "hrs on": "何時間も",
    "detection": "検出",
    "Day vs Day": "日対日",
    "Week vs Week": "週対週",
    "Month vs Month": "月対月",
    "Year vs Year": "年対年",
    "Educational Institution": "教育機関",
    "Food Processing": "食品加工",
    "Home": "家",
    "Hospital": "病院",
    "Hotel": "ホテル",
    "Loading Bay Warehouse": "ベイウェアハウスの積み込み",
    "Office": "オフィス",
    "Restaurant": "飲食店",
    "Retail": "小売",
    "Theatre": "シアター",
    "Airport": "空港",
    "Bar": "バー",
    "Cafe": "カフェ",
    "Cruise line": "クルーズライン",
    "Supermarket": "スーパーマーケット",
    "Train Station": "でんしゃのりば",
    "glueboard expiry": "グルーボードの有効期限",
    "glueboard max detections": "接着剤ボードの最大検出",
    "zone ccl breached": "ゾーンccl違反",
    "established connection": "確立された接続",
    "established connection wifi": "確立された接続WiFi",
    "wifi config successful": "WiFi構成が成功しました",
    "glueboard changed": "接着剤が変更されました",
    "detections resolved": "検出が解決されました",
    "Web App": "ウェブアプリ",
    "Mobile App": "モバイルアプリ",
    "sensor add": "センサー追加",
    "sensor edit": "センサー編集",
    "sensor remove": "センサーの取り外し",
    "sensor replace": "センサー交換",
    "Site deleted successfully.": "サイトが正常に削除されました。",
    "Zone deleted successfully.": "ゾーンが正常に削除されました。",
    "Super Admin": "スーパー管理者",
    "Regional Manager": "地域担当マネージャー",
    "Branch Manager": "支店長",
    "Customer Service Executive": "顧客対応責任者",
    "Service Manager": "サービスマネージャ",
    "Service Technician": "サービス技術者",
    "Customer Representative": "顧客担当者",
    "Super Admin is the root level access of the organisation.": "スーパー管理者は、組織のルートレベルのアクセスです。",
    "Regional Manager is the managerial role with scope of multiple branches. Multiple Branch Managers can report under Regional Manager.": "リージョナルマネージャーは、複数の支店にまたがる管理職です。複数のブランチマネージャーは、リージョナルマネージャーの下でレポートできます。",
    "Branch Manager is the managerial role for one branch. Branch manager is empowered with all functions within scope of a branch.": "支店長は、1つの支店の管理者の役割です。ブランチマネージャーは、ブランチのスコープ内のすべての機能を利用できます。",
    "Customer Service Executive is the custodian of achieving customer satisfaction within a branch. One or more Customer Service Executives can manage the customers in a branch as required.": "カスタマーサービスエグゼクティブは、支店内で顧客満足を達成するためのカストディアンです。 1人以上のカスタマーサービスエグゼクティブは、必要に応じて支店の顧客を管理できます。",
    "In large branches, Service Managers manage the routing and productivity of the Service Technicians on the field. One or more Service Managers, manage technicians under them for best utilisation of their time.": "大規模な支店では、サービスマネージャーが現場のサービス技術者のルーティングと生産性を管理します。 1人以上のサービスマネージャーは、時間を最大限に活用するために、その下の技術者を管理します。",
    "Service Technicians (a.k.a PMP, Pest Management Personnel) are the field agents who visit the customers site to deliver the Sensor Installation, Pest Control, Sensor Malfunction and Periodic maintenance services.": "サービス技術者（別名PMP、害虫管理要員）は、センサーの設置、害虫駆除、センサーの誤動作、および定期的なメンテナンスサービスを提供するために顧客のサイトを訪問するフィールドエージェントです。",
    "Customer Representatives are the site/facility managers of the customer for which the services are provided. A Customer Representative can be mapped to only one customer": "顧客担当者は、サービスが提供される顧客のサイト/施設管理者です。顧客担当者は、1人の顧客にのみマップできます",
    "site events log": "サイトイベントログ",
    "Site Management": "サイト管理",
    "Zone Management": "ゾーン管理",
    "This sensor ID does not exist! Kindly recheck and try again.": "このセンサーIDは存在しません！もう一度確認して、もう一度お試しください。",
    "Invalid Sensor! This sensor does not belong to your organisation.": "センサーが無効です！このセンサーはあなたの組織のものではありません。",
    "Sensor is validated successfully!": "センサーが正常に検証されました！",
    "Invalid sensor! This sensor is already added elsewhere.": "センサーが無効です！このセンサーはすでに他の場所に追加されています。",
    "Invalid sensor! This sensor has been removed as malfunctioning and can no longer be added.": "センサーが無効です！このセンサーは誤動作として削除され、追加できなくなりました。",
    "Invalid sensor! This sensor has been replaced as malfunctioning / damaged / lost and can no longer be added.": "センサーが無効です！このセンサーは、誤動作/損傷/紛失として交換され、追加できなくなりました。",
    "Invalid sensor! This sensor was added to site": "センサーが無効です！このセンサーはサイトに追加されました",
    " zone": " ゾーン",
    "and is currently in use.": "現在使用中です。",
    "site created": "作成されたサイト",
    "site edited": "編集されたサイト",
    "service team edited": "サービスチーム編集",
    "zone added": "ゾーンが追加されました",
    "zone name edited": "ゾーン名を編集しました",
    "zone deleted": "ゾーンが削除されました",
    "zone ccl changed rodent": "ゾーンCCLは齧歯動物を変更しました",
    "zone ccl changed fly": "ゾーンCCL変更フライ",
    "Zone Rodent critical control limit changed from": "ゾーン齧歯動物の重要な管理限界がから変更されました",
    "Zone Crawling Insect Critical Control Limit changed from": "ゾーン クロール昆虫のクリティカル コントロール リミットが から変更されました",
    "Zone Fly critical control limit changed from": "ゾーンフライのクリティカルコントロールリミットがから変更されました",
    " to ": " に ",
    "sensor added": "センサー追加",
    "delete site": "サイトを削除",
    "delete zone": "ゾーンを削除",
    "zone fly critical control limit breached": "ゾーンフライのクリティカルコントロール制限に違反しました",
    "grouped crawling insect detections by site": "サイトごとにグループ化された這う昆虫の検出",
    "grouped rodent detections by site": "サイトごとのグループ化された齧歯動物の検出",
    "grouped fly detections by site": "サイトごとのグループ化されたハエの検出",
    "type": "タイプ",
    "site contact person name": "サイト担当者名",
    "None Selected": "何も選択されていません",
    "All Selected": "選択したすべて",
    "Multiple Selected": "複数選択",
    "glueboard max fly detections": "接着剤ボードの最大フライ検出",
    "edited": "編集",
    "sensor photo": "センサー写真",
    "add sensor pictures": "センサー画像を追加",
    "sensor picture(s)": "センサー画像",
    "sensor picture": "センサー画像",
    "fly glueboard max detections": "フライグルーボードの最大検出数",
    "fly glueboard expiry": "フライグルーボードの有効期限",
    "Sensor WiFi Configuration Successful": "センサーWiFi構成が成功しました",
    "zone crawling insect breached": "ゾーンクロール昆虫違反",
    "zone fly breached": "ゾーンフライ違反",
    "zone rodent breached": "ゾーン齧歯動物が違反しました",
    "fly glueboard max detections breached": "フライグルーボードの最大検出数が違反しました",
    "sensor edited": "センサー編集",
    "sensor removed": "センサーが取り外されました",
    "sensor replaced": "センサーを交換",
    "edit service team": "サービスチームの編集",
    "sensor established connection via wifi": "WiFi経由でセンサーが接続を確立",
    "sensor wifi configuration successful": "センサーWiFi構成が成功しました",
    "zone rodent critical control limit edited": "ゾーン齧歯動物の重要な管理限界を編集",
    "zone fly critical control limit edited": "ゾーンフライクリティカルコントロールリミット編集",
    "zone crawling insect critical control limit edited": "ゾーン クロール昆虫の重要な制御制限が編集されました",
    "zone crawling insect critical control limit breached": "ゾーンクロール昆虫のクリティカルコントロール限界突破",
    "fly glueboard expiry changed": "フライグルーボードの有効期限が変更されました",
    "fly glueboard max detections changed": "フライグルーボードの最大検出が変更されました",
    "Detections Resolution": "検出の解決",
    "Fly Glueboard Expiry Changed from": "飛ぶ接着剤ボードの有効期限がから変更されました",
    "days to": "までの日数",
    "Days": "日々",
    "Fly Glueboard Max Detections Changed from": "飛ぶ接着剤ボードの最大検出数がから変更されました",
    "site information edited": "編集されたサイト情報",
    "contact person details edited": "担当者の詳細を編集",
    "zone edited": "ゾーン編集",
    "Changed from": "から変更",
    "contact phone number": "連絡先の電話番号",
    "contact email id": "連絡先メールID",
    "zone map edited": "ゾーンマップ編集",
    "Zone Name, Map Edited": "ゾーン名、マップ編集",
    "New Password and Confirm New Password does not match": "新しいパスワードと新しいパスワードの確認が一致しません",
    "You have entered invalid old password.": "無効な古いパスワードを入力しました。",
    "profile": "プロフィール",
    "preferences": "環境設定",
    "New Password shoudn't be same as old password.": "新しいパスワードは古いパスワードと同じであってはなりません。",
    "Fly Glueboard": "フライボード",
    "user roles": "ユーザーの役割",
    "The side of the entry for": "のエントリの側面",
    "rodent detections is unknown due to variations in the size of the rodent.": "齧歯動物のサイズが異なるため、齧歯動物の検出は不明です。",
    "Critically low, requires replacement!": "非常に低く、交換が必要です！",
    "total sites": "総サイト数",
    "100% fully charged": "100％フル充電",
    "remaining": "残り",
    "administration": "管理の概要",
    "created by (username)": "作成者 (ユーザー名)",
    "reporting manager (username)": "報告マネージャー (ユーザー名)",
    "Cigarette Beetle Glueboard": "シガレット ビートル グルーボード",
    "Default max cigarette beetle detections allowed on the glueboard": "グルーボードで許容されるデフォルトの最大シガレットビートル検出",
    "please enter max cigarette beetle detections allowed on the glueboard": "グルーボードで許容されるシガレットビートルの最大検出数を入力してください",
    "Default max cigarette beetle detections allowed on the glueboard between 1 to 999999": "グルーボードで許容されるデフォルトの最大シガレットビートル検出数は 1 ～ 999999 です",
    "Default Cigarette Beetle Critical Control Limit of the Zone": "デフォルト シガレット ビートル ゾーンのクリティカル コントロール リミット",
    "Please Enter Cigarette Beetle Critical Control Limit": "シガレットビートル クリティカル コントロール リミットを入力してください",
    "Default cigarette beetle detections limit between 1 to 999999": "デフォルトのシガレットビートル検出制限は 1 ～ 999999 です",
    "cigarette beetle": "シガレットビートル",
    "max cigarette beetle detections allowed on the glueboard between 1 to 999999": "グルーボードで許容されるシガレットビートルの最大検出数は 1 ～ 999999 です",
    "max cigarette beetle detections allowed on the glueboard": "グルーボードで許容される最大のシガレットビートル検出",
    "cigarette beetle sensor": "シガレットビートルセンサー",
    "Cigarette Beetle Detection": "シガレットビートルの検出",
    "cigarette beetles": "シガレットビートルズ",
    "Cigarette Beetle Detections": "シガレットビートルの検出",
    "Cigarette Beetle Trend": "シガレットビートルトレンド",
    "Cigarette Beetle Critical Control Limit": "シガレットビートルのクリティカルコントロールリミット",
    "Cigarette Beetle Glueboard Expiry": "シガレット ビートル グルーボードの有効期限",
    "Cigarette Beetle Glueboard Detections": "シガレットビートルの接着剤検出",
    "Zone Cigarette Beetle Critical Control Limit": "ゾーン シガレット ビートル クリティカル コントロール リミット",
    "missing mandatory zoneName field": "必須のゾーン名フィールドがありません",
    "Cigarette Beetle Glueboard Expiry Changed from": "シガレットビートル グルーボードの有効期限が から変更されました",
    "Cigarette Beetle Glueboard Max Detections Changed from": "シガレットビートル グルーボードの最大検出数が から変更されました",
    "zone cigarette beetle critical control limit changed from": "ゾーン シガレット ビートルのクリティカル コントロール リミットが から変更されました",
    "cigarette beetle sensors": "シガレットビートルセンサー",
    "cigarette beetle glueboard max detections breached": "シガレットビートルグルーボードの最大検出数を突破",
    "cigarette beetle glueboard expiry breached": "シガレットビートルグルーボードの有効期限が切れました",
    "cigarette beetle glueboard expiry changed": "シガレットビートルグルーボードの有効期限が変更されました",
    "cigarette beetle glueboard max detections changed": "シガレットビートルグルーボードの最大検出数が変更されました",
    "cigarette beetle glueboard replaced": "シガレットビートルの接着板を交換しました",
    "all cigarette beetle sensors": "すべてのシガレットビートル センサー",
    "Cigarette Beetle Cumulative Sum": "シガレットビートル累計",
    "detection count": "検出回数",
    "New Cigarette Beetle Detections at Site": "サイトでの新しいシガレットビートルの検出",
    "New Cigarette Beetle Detections": "新しいシガレットビートルの検出",
    "zone cigarette beetle critical control limit edited": "ゾーン シガレットビートルの重要管理限界 編集済み",
    "zone cigarette beetle critical control limit breached": "ゾーンシガレットビートルの重要管理限界突破",
    "grouped cigarette beetle detections by site": "サイトごとにグループ化されたタバコの甲虫の検出",
    "max. detections limit on the glueboard": "最大グルーボードの検出限界",
    "please enter max. detections limit on the glueboard": "最大入力してください。グルーボードの検出限界",
    "max. detections limit allowed on the glueboard between 1 to 999999": "最大グルーボードで許可される検出数の上限は 1 ～ 999999 です",
    "enter a value between 1 to 999999": "1 ～ 999999 の値を入力してください",
    "New Crawling Insect Detections": "新しい這う昆虫の検出",
    "crawling insect sensors": "這う昆虫センサー",
    "Crawling Insect Detections": "這う昆虫の検出",
    "Crawling Insect Detection" : "這う昆虫の検出",
    "Crawling Insect Trend": "這う昆虫の傾向",
    "Crawling Insect Activity": "這う昆虫活動",
    "crawling insect sensor": "這う昆虫センサー",
    "Pest activity trend is not computed for the replaced sensor(s).": "害虫の活動傾向は、交換されたセンサーでは計算されません。",
    "Pest activity trend is displayed for all the sites available.": "利用可能なすべてのサイトについて、害虫の活動傾向が表示されます。",
    "Pest activity trend is displayed for all the sites available for the customers.": "顧客が利用できるすべてのサイトについて、害虫の活動傾向が表示されます。",
    "Search by Sensor ID": "センサーIDで検索",
    "Search by Sensor Name": "センサー名で検索",
    "not found floor map, Please upload the floor map in the edit zone screen.": "フロアマップが見つかりません。ゾーン編集画面でフロアマップをアップロードしてください。",
    "No zone map found! Please upload the zone map to map the sensors on the map.": "ゾーンマップが見つかりません!マップ上にセンサーをマッピングするには、ゾーン マップをアップロードしてください。",
    "max length 50": "最大長 50",
    "max length 100": "最大長 100",
    "max length 30": "最大長 30",
    "max length 16": "最大長 16",
    "Default No. of days for Fly Glue Board Expiry": "ハエのりのデフォルトの日数 ボードの有効期限",
    "Default max. Fly detections allowed on the glue board": "デフォルトの最大グルーボードで許可されているハエの検出",
    "Fly Glue Board Expiring Notification (days)": "ハエのりボードの有効期限切れ通知 (日)",
    "Default No. of days for Cigarette Beetle Glue Board Expiry": "タバコのデフォルトの日数 ビートル 接着剤 ボードの有効期限",
    "Default max. Cigarette Beetle detections allowed on the glue board": "デフォルトの最大接着剤ボードでシガレットビートルの検出が可能",
    "Cigarette Beetle Glue Board Expiring Notification (days)": "シガレット ビートル グルー ボードの有効期限切れ通知 (日)",
    "The number of days before a glue board expires and a breach is triggered.": "グルーボードの有効期限が切れて違反がトリガーされるまでの日数。",
    "The maximum detections allowed on a glue board before a breach is triggered for the sensor.": "センサーの違反がトリガーされる前に接着ボードで許可される最大検出。",
    "This setting defines the early notification generation - 'X' number of days before the expiry of the glue board.": "この設定は、早期通知の生成を定義します - グルー ボードの有効期限が切れる 'X' 日前。",
    "The maximum rodent detection limit for a zone before a breach is triggered.": "違反がトリガーされる前のゾーンの最大げっ歯類検出制限。",
    "The maximum crawling insect detection limit for a zone before a breach is triggered.": "違反がトリガーされる前のゾーンの這う昆虫の最大検出制限。",
    "The maximum fly detection limit for a zone before a breach is triggered.": "違反がトリガーされる前のゾーンの最大ハエ検出制限。",
    "The maximum cigarette beetle detection limit for a zone before a breach is triggered.": "違反がトリガーされる前のゾーンのシガレットビートルの最大検出制限。",
    "value": "価値",
    "edit setting value": "設定値の編集",
    "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?": "ゾーン マップを削除すると、ゾーン マップ上の既存のセンサーのマッピングがリセットされます。本気ですか？",
    "mapped sensors": "マッピングされたセンサー",
    "unmapped sensors": "マッピングされていないセンサー",
    "save": "保存",
    "Click & drag each sensor onto the zone floor plan to assign it to the desired location.": "各センサーをクリックしてゾーン フロア プランにドラッグし、目的の場所に割り当てます。",
    "Error occupied in Sensor Mapping. Do it again!": "センサー マッピングで占有されているエラー。再びそれを行う！",
    "updated successfully!": "更新成功！",
    "view zone map": "ゾーンマップを見る",
    "upload zone map": "ゾーンマップのアップロード",
    "please enter No. of days for the glueboard expiry notification": "接着剤の有効期限通知の日数を入力してください",
    "Default No. of days for the glueboard expiry notification between 1 to 999": "グルーボードの有効期限通知のデフォルトの日数 (1 ～ 999)",
    "please enter No. of days for the cigarette beetle glueboard expiry notification": "シガレットビートルグルーボードの有効期限通知の日数を入力してください",
    "Default No. of days for the cigarette beetle glueboard expiry notification between 1 to 999": "シガレット ビートル グルーボードの有効期限通知のデフォルトの日数 (1 ～ 999)",
    "last seen / alerts": "最終確認 / アラート",
    "security": "安全",
    "This setting specifies the number of days for a password to get expired for a user after the user logs into the application for the first time or when it is last changed.": "この設定では、ユーザーがアプリケーションに初めてログインした後、または最後に変更されたときに、ユーザーのパスワードが期限切れになるまでの日数を指定します。",
    "This setting determines if the user password should expire or not.": "この設定は、ユーザー パスワードの有効期限が切れるかどうかを決定します。",
    "Password expiry (days)": "パスワードの有効期限 (日)",
    "Enable password expiry": "パスワードの有効期限を有効にする",
    "please enter password expiry (days)": "パスワードの有効期限 (日) を入力してください",
    "password expiry (days) Min value: 90  to Max: 730": "パスワードの有効期限 (日) 最小値: 90 から最大: 730",
    "please change your password to continue.": "続行するにはパスワードを変更してください。",
    "already added to the service team": "すでにサービスチームに追加されています",
    "undo revoke": "取り消しを元に戻す",
    "This setting defines the early notification generation - \"X\" number of days before the expiry of the glue board.": "この設定は、早期通知の生成を定義します - 接着剤ボードの有効期限が切れるまでの \"X\" 日数。",
    "enabled": "有効",
    "disabled": "無効",
    "Enable / Disable password expiry": "パスワードの有効期限を有効/無効にする",
    "Please your enter username": "ユーザー名を入力してください",
    "When enabled, this setting specifies the number of days for passwords to get expired since it was created or changed. When the password expiry is enabled (if disabled before), it is reset on the next successful login for all users.": "この設定を有効にすると、パスワードが作成または変更されてから有効期限が切れるまでの日数が指定されます。パスワードの有効期限が有効になっている場合 (以前に無効になっていた場合)、すべてのユーザーが次回ログインに成功したときにリセットされます。",
    "No unmapped sensors exist!": "マッピングされていないセンサーはありません!",
    "Your password has expired! Please change your password to continue.": "パスワードの有効期限が切れています。続行するには、パスワードを変更してください。",
    "cigarette beetle glueboard expiring": "シガレット ビートル グルーボードの有効期限が近づいています",
    "fly glueboard expiring": "ハエのりボード 期限切れ",
    "Fly Glue Board expiring in": "ハエの接着剤ボードの有効期限が切れる",
    "Cigarette Beetle Glue Board expiring in": "シガレット ビートル グルー ボードの有効期限が切れるまでに",
    "Customer cannot be deleted, as it is associated with one or more sites.": "顧客は 1 つ以上のサイトに関連付けられているため、削除できません。",
    "zone rodent critical control limit breached": "​ゾーン齧歯動物の重要な管理限界に違反",
    "update site": "サイトの更新",
    "do you want to remove": "削除しますか",
    "Rodent": "齧歯類",
    "Fly": "飛ぶ",
    "Cigarette Beetle": "シガレットビートル",
    "no records found" : "レコードが見つかりません",
    "Your password has been changed successfully! Please login with your new password.": "パスワードが正常に変更されました！新しいパスワードでログインしてください。",
    "Invalid username or password. Please enter valid credentials and try again!": "ユーザー名かパスワードが無効。有効な資格情報を入力して、もう一度お試しください!",
    "Inactive user!": "非アクティブなユーザー！",
    "The new password cannot be the same as the current password.": "新しいパスワードを現在のパスワードと同じにすることはできません。",
    "The current password entered is incorrect!": "現在入力されているパスワードが正しくありません!",
    "sensors info" : "センサー情報",
    "breached" : "違反した",
    "help": "ヘルプ",
    "Internet of Pests Help Center": "害虫のインターネット ヘルプセンター",
    "Go to IoP Dashboard": "IoP ダッシュボードに移動",
    "sensors list": "センサー一覧",
    "The Comm. Status Column": "コム。ステータス列",
    "All sensors communicate with the IoP® platform whenever it detects “pests or in each heartbeat”. The heartbeat time for rodent and crawling sensors is 24 hrs. and for fly and cigarette beetle sensors is 30 mins.": "すべてのセンサーは、「害虫または各心拍」を検出するたびに IoP® プラットフォームと通信します。げっ歯類および這うセンサーの心拍時間は 24 時間です。ハエとタバコハムシのセンサーの場合は 30 分です。",
    "The Comm. Status or Communication Status lets you know what is the current status of a sensor and whether it’s functional. There are 4 possible values for comm. status:": "コム。ステータスまたは通信ステータスにより、センサーの現在のステータスと、センサーが機能しているかどうかを知ることができます。 comm には 4 つの可能な値があります。スターテス：",
    "Displays when a sensor is newly added to your account and has not yet been switched “On”.": "センサーがアカウントに新しく追加され、まだ「オン」になっていない場合に表示されます。",
    "Displays when a sensor is online and has detected any pest or has sent a “heartbeat” message in the last 25 hours (for Rodent and Crawling Insect sensors) or in the last 40 minutes (for Fly and Cigarette Beetle sensors)": "センサーがオンラインで、過去 25 時間以内 (げっ歯類および這う昆虫センサーの場合)、または過去 40 分間 (ハエおよびタバコハムシセンサーの場合) に害虫を検出したか、「ハートビート」メッセージを送信したときに表示されます。",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message between last 25 hours and 48 hours (for Rodent and Crawling Insect sensors) or between last 40 minutes and 60 minutes (for Fly and Cigarette Beetle sensors)": "過去 25 時間から 48 時間 (げっ歯類および這性昆虫センサーの場合)、または過去 40 分から 60 分 (ハエおよびタバコハムシ センサーの場合) の間、センサーが害虫を検出しなかった場合、または「ハートビート」メッセージを送信しなかった場合に表示されます。",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message for more than 48 hours (for Rodent and Crawling Insect sensors) or for more than 60 minutes (for Fly and Cigarette Beetle sensors)": "センサーが害虫を検出しなかった場合、または「ハートビート」メッセージを 48 時間以上 (げっ歯類および這う昆虫センサーの場合) または 60 分以上 (ハエおよびシガレットビートル センサーの場合) 送信しなかった場合に表示されます。",
    "The Last Seen/Alerts Column": "最後に見たもの / アラート カラム",
    "The Last Seen/Alerts column lets you know the last time and date an update (communication) was received from the sensor. It also includes three types of alert icons.": "[最後に見たもの / アラート] 列では、センサーから最後に更新 (通信) を受信した日時を確認できます。また、3 種類の警告アイコンも含まれています。",
    "The New Detections Column": "新しい検出列",
    "The new detections column displays the number of “triggers” or pests the sensor has detected since the last time they were resolved. These are also displayed on the Detections List screen from where the Pest Management Personnel can resolve them.": "新しい検出列には、センサーが最後に解決されてから検出した「トリガー」またはペストの数が表示されます。これらは、害虫管理担当者がそれらを解決できる場所から検出リスト画面にも表示されます。",
    "How often does the data on the sensor’s list page refresh?": "センサーのリスト ページのデータはどのくらいの頻度で更新されますか?",
    "By default, the page will refresh automatically when the sensor detects pests or changes it's communication status.": "デフォルトでは、センサーが害虫を検出したり、通信ステータスが変化したりすると、ページが自動的に更新されます。",
    "sensors list help": "センサーリストのヘルプ",
    "Sensor Communication Channel": "センサー通信チャネル",
    "LPWAN or Wi-Fi - if a sensor is communicating over an LPWAN network or is configured to communicate over a local Wi-Fi network.": "LPWAN または Wi-Fi - センサーが LPWAN ネットワーク経由で通信しているか、ローカル Wi-Fi ネットワーク経由で通信するように構成されている場合。",
    "Critical Battery Status": "重大なバッテリー状態",
    "Breach Status": "違反状況",
    "when a zone with the specific pest type has exceeded (breached) the detections or Critical Control Limit for a specific pest.": "特定の害虫タイプのゾーンが、特定の害虫の検出または重要管理限界を超えた (違反した) 場合。",
    "when a Fly or Cigarette Beetle sensor's glue board has expired. The glue board expiry is calculated from the date the sensor was added to the platform.": "飛ぶ または シガレットビートル センサーの接着剤ボードの有効期限が切れたとき。接着剤ボードの有効期限は、センサーがプラットフォームに追加された日付から計算されます。",
    "when a Fly or Cigarette Beetle sensor's max detections limit on the glue board has exceeded.": "ハエまたはシガレット ビートル センサーの接着ボード上の最大検出制限を超えた場合。",
    "Rodent and Crawling Insect sensors contains a lithium-ion non rechargeable cell that is permanently pre-installed for the 5 year or 10,000 transmissions expected operational lifetime.": "げっ歯類および這う昆虫センサーにはリチウムイオン非充電式セルが含まれており、5 年間または 10,000 回の送信が予想される動作寿命に対して永久的に事前に取り付けられています。",
    "A critical battery status is displayed when a sensor's battery is below 10%. This percentage value is calculated based on the total number of “triggers” generated by the sensor; i.e. (10,000 - Total Triggers) %.": "センサーのバッテリーが 10% を下回ると、重大なバッテリー ステータスが表示されます。このパーセンテージ値は、センサーによって生成された「トリガー」の総数に基づいて計算されます。つまり、(10,000 - トリガーの合計) %。",
    "The total triggers is maintained from the time the sensor was first turned on.": "合計トリガーは、センサーが最初にオンになった時点から維持されます。",
    "co2": "CO2",
    "co": "CO",
    "pm2.5": "PM2.5",
    "voc": "VOC",
    "7 Days" : "7日",
    "30 Days" : "30日",
    "temperature": "温度",
    "humidity": "湿度",
    "no matches": "一致しません",
    "valid format JPG/JPEG/PNG": "有効な形式 JPG/JPEG/PNG",
    "max file size: 5MB": "最大ファイルサイズ: 5MB",
    "Filter data by selecting the legends": "凡例を選択してデータをフィルター処理する",
    "day vs day": "日対日",
    "week vs week": "週対週",
    "month vs month": "月対月",
    "year vs year": "年対年",
    "Aggregated for": "集計対象",
    "Filters": "フィルター",
    "zone map successfully updated.": "ゾーン マップが正常に更新されました。",
    "English" : "English",
    "Japanese" : "日本",
    "Spanish" : "Español",
    "submit" : "送信",
    "Did you forgot your password?" : "パスワードを忘れましたか?",
    "Submit your Username or registered Email ID and you will receive a temporary password to login." : "ユーザー名または登録済みの電子メール ID を送信すると、ログイン用の一時パスワードが届きます。",
    "Back to Sign in" : "サインインに戻る",
    "Temporary password has been sent to your email successfully." : "一時パスワードがメールに送信されました。",
    "Username / Email ID" : "ユーザー名/メールID",
    "please enter your username / email id": "ユーザー名/メールIDを入力してください",
    "site pest detections report" : "現場の害虫検出レポート",
    "No unMapped sensor" : "マップされていないセンサーはありません",
    "Hourly cumulative detections" : "時間ごとの累積検出数",
    "Percentage detections" : "検出率",
    "Sensor name" : "センサー名",
    "count" : "カウント",
    "total detections" : "総検出数",
    "Please select the customer, site, date range and time zone to generate the report." : "レポートを生成する顧客、サイト、日付範囲、およびタイムゾーンを選択してください。",
    "system error!" : "システムエラー！",
    "download" : "ダウンロード",
    "site address" : "サイトアドレス",
    "site timezone" : "サイトアドレス",
    "Pest Management Company" : "害害虫管理会社虫会社",
    "Evoscien Main Office" : "Evoscien Main Office",
    "generated on" : "にダウンロードされました",
    "generated by" : "によってダウンロードされました",
    "Date range" : "日付範囲",
    "Time zone" : "タイムゾーン",
    "Powered by" : "搭載",
    "This report was generated" : "このレポートは生成されました",
    "Percentage change" : "変化率",
    "generate report" : "レポートの生成",
    "stakeholder performance analysis" : "ステークホルダーのパフォーマンス分析",
    "Sort by Site" : "サイトごとに並べ替え",
    "Sort by Zone" : "ゾーンごとに並べ替え",
    "Week I: Starting Date Of" : "第 1 週: 開始日",
    "Week II: Starting Date Of" : "第 2 週: 開始日",
    "Please select the customer, pest and comparison type and time zone to generate the report." : "レポートを生成するには、顧客、害虫、比較のタイプとタイムゾーンを選択してください。",
    "deleted" : "削除されました",
    "original day" : "元の日",
    "final day" : "最終日",
    "Original Day should be less than Final Day" : "元の日は最終日よりも前の日である必要があります",
    "Final Day should be greater than Original Day" : "最終日は元の日よりも大きい必要があります",
    "original week" : "オリジナルウィーク",
    "final week" : "最終週",
    "Original Week should be less than Final Week" : "元の週は最終週よりも小さい必要があります",
    "Final Week should be greater than Original Week" : "最終週は元の週より大きくなければなりません",
    "original month" : "元の月",
    "final month" : "最終月",
    "Original Month should be less than Final Month" : "元の月は最終月よりも小さい必要があります",
    "Final Month should be greater than Original Month": "最終月は元の月より大きくなければなりません",
    "original year" : "元の年",
    "final year" : "最終年",
    "Original Year should be less than Final Year" : "元の年は最終年よりも小さい必要があります",
    "Final Year should be greater than Original Year" : "最終年は元の年より大きい必要があります",
    "Please select the customer and pest to generate the report." : "レポートを生成する顧客と害虫を選択してください。",
    "progress" : "進捗",
    "success" : "成功",
    "failed" : "失敗した",
    "retry" : "リトライ",
    "status" : "状態",
    "percentage detections" : "検出率",
    "faqs" : "よくある質問",
    "What is remote pest monitoring? Why is it important?" : "害虫の遠隔監視とは何ですか?どうしてそれが重要ですか？",
    "Remote pest monitoring technology allows pest management professionals to track and monitor pest activity from anywhere in real-time via mobile or web-based platforms. It allows pest control technicians to quickly identify and treat hot spots on the premises, potentially reducing labour time and chemical use." : "リモート害虫監視テクノロジーにより、害虫管理の専門家は、モバイルまたは Web ベースのプラットフォームを介して、どこからでもリアルタイムで害虫の活動を追跡および監視できます。これにより、害虫駆除技術者は敷地内のホットスポットを迅速に特定して処理できるため、労働時間と化学薬品の使用が削減される可能性があります。",
    "What is the point of using sensors without a trap?" : "トラップなしでセンサーを使用することに何の意味があるのでしょうか?",
    "The sensor’s flexible form factor allows it to be placed in usually inaccessible areas, such as roof spaces and ventilation shafts, where they can provide early warning of potential pest breeding sites and threats to infrastructure." : "このセンサーの柔軟なフォームファクターにより、屋根スペースや換気シャフトなど、通常はアクセスできないエリアに設置することができ、潜在的な害虫の繁殖場所やインフラへの脅威を早期に警告できます。",
    "Can a single sensor detect rodents, crawling, and flying insects?" : "単一のセンサーでげっ歯類、這う昆虫、飛んでいる昆虫を検出できますか?",
    "No. While the underlying technology is the same, each sensor is designed for a particular pest type." : "いいえ、基礎となるテクノロジーは同じですが、各センサーは特定の害虫の種類に合わせて設計されています。",
    "Will my sensor battery last 7 years? Are the batteries replaceable?" : "センサーのバッテリーは 7 年間持続しますか?電池は交換可能ですか?",
    "The expected battery life of the rodent and crawling pest sensors is 7 years or 10,000 transmissions. Actual battery life would vary depending on the frequency of pest activity in the area. Batteries can only be replaced by a trained technician." : "げっ歯類および這う害虫センサーの予想バッテリー寿命は 7 年または 10,000 回の送信です。実際のバッテリー寿命は、その地域での害虫の活動の頻度によって異なります。バッテリーの交換は訓練を受けた技術者のみが行うことができます。",
    "Flying insect sensors are powered by the main power supply, and do not require batteries." : "飛翔昆虫センサーは主電源から電力を供給されるため、電池は必要ありません。",
    "Do I need Wi-Fi for my IoP® setup?" : "IoP® セットアップには Wi-Fi が必要ですか?",
    "A Wi-Fi or ethernet connection is only required for the local gateway to receive signals from the rodent and crawling pest sensors. Wi-Fi is preferred for flying insect sensors, but they can transmit data to rodents and crawling insect gateways." : "Wi-Fi またはイーサネット接続は、ローカル ゲートウェイがげっ歯類および這う害虫センサーから信号を受信する場合にのみ必要です。飛行昆虫センサーには Wi-Fi が推奨されますが、げっ歯類や這う昆虫のゲートウェイにデータを送信する可能性があります。",
    "Since data transmission is minimal, low-data pre-paid SIMs can be used if local Wi-Fi or ethernet connections are unavailable." : "データ送信が最小限であるため、ローカル Wi-Fi またはイーサネット接続が利用できない場合でも、低データ通信量のプリペイド SIM を使用できます。",
    "Can I add, relocate, or remove sensors if needed?" : "必要に応じてセンサーを追加、移動、または削除できますか?",
    "Yes, the IoP® system allows seamless sensor addition, removal, replacement, and relocation without data loss. For more information, refer to the Technician’s Handbook." : "はい、IoP® システムを使用すると、データを損失することなくセンサーの追加、削除、交換、再配置をシームレスに行うことができます。詳細については、技術者用ハンドブックを参照してください。",
    "What do I do if my sensors stop working?" : "センサーが機能しなくなったらどうすればよいですか?",
    "In the absence of pest activity, sensors transmit a heartbeat every 24 hours to indicate their functionality. Contact your provider to repair or replace the sensor if no heartbeat signal is received, and the sensor status changes to idle or offline.": "害虫の活動がない場合、センサーは 24 時間ごとに心拍を送信し、その機能を示します。ハートビート信号が受信されず、センサーのステータスがアイドルまたはオフラインに変化した場合は、プロバイダーに連絡してセンサーを修理または交換してください。",
    "How do I sign up for an IoP® account?" : "",
    "Your IoP® super-admin account will be generated upon confirmation of your order. Contact our support team for more information, or to request a demo." : "IoP® スーパー管理者アカウントは、注文の確認時に生成されます。詳細について、またはデモをリクエストするには、サポート チームにお問い合わせください。",
    "I’ve received a breach alert. What do I do next?" : "侵害警告を受け取りました。次は何をすればいいでしょうか？",
    "Critical control limits refer to the acceptable level of pest activity. The values can be manually adjusted per pest according to customer requirements and/or local regulations. An alert is sent when sensor detections exceed the critical control limit. Breach alerts call for immediate action by your pest control service provider." : "重要防除限界とは、害虫の活動の許容可能なレベルを指します。値は、顧客の要件および/または地域の規制に従って、害虫ごとに手動で調整できます。センサーの検出が重要な管理限界を超えると、アラートが送信されます。侵入アラートは、害虫駆除サービスプロバイダーによる即時の行動を求めます。",
    "What type of alerts does the IoP® platform provide?" : "IoP® アカウントにサインアップするにはどうすればよいですか?",
    "The IoP® platform sends real-time alerts for critical control limit breaches and sensor communication status updates, including idle/offline/active, site-related alerts like site access granted, site detections resolved, and zone-related alerts when pest detections are resolved in a zone." : "IoP® プラットフォームは、アイドル/オフライン/アクティブ、サイトへのアクセス許可、サイト検出の解決などのサイト関連アラート、害虫検出が解決されたときのゾーン関連アラートなど、重大な管理限界違反とセンサー通信ステータスの更新に関するリアルタイムのアラートを送信します。ゾーンで。",
    "How does IoP® help identify pest entry points?" : "IoP® は害虫の侵入ポイントを特定するのにどのように役立ちますか?",
    "IoP® sensors provide valuable insight into pest movement patterns by recording which side of the sensor the pest contacted first. Taken together, this data can help PMPs to identify and target potential breeding sites and points of entry." : "IoP® センサーは、害虫が最初にセンサーのどちら側に触れたかを記録することで、害虫の移動パターンに関する貴重な洞察を提供します。総合すると、このデータは、PMP が潜在的な繁殖地と侵入ポイントを特定してターゲットにするのに役立ちます。",
    "Does the IoP® platform provide documentation for regulatory compliance?" : "IoP® プラットフォームは規制遵守のための文書を提供しますか?",
    "Yes, all pest activity is securely stored and accessible on the IoP® platform anytime. Users can generate and view historical and current pest activity reports to demonstrate regulatory compliance." : "はい、すべての害虫の活動は安全に保存され、IoP® プラットフォームでいつでもアクセスできます。ユーザーは、過去および現在の害虫活動レポートを生成および表示して、法規制への準拠を実証できます。",
    "Where can the sensors be used?" : "センサーはどこで使用できますか?",
    "The sensors can be deployed in areas prone to pest activity, like kitchens, storage areas, waste disposal areas, etc. They can also be used in hard-to-access areas like roof spaces and ventilation ducts." : "このセンサーは、キッチン、保管エリア、廃棄物処理エリアなど、害虫の活動が起こりやすいエリアに設置できます。また、屋根スペースや換気ダクトなどのアクセスが難しいエリアでも使用できます。",
    "Is the sensor waterproof?" : "センサーは防水ですか？",
    "Yes, the sensor is IP67 rated, but we recommend turning the unit off and drying it thoroughly before reuse to reduce the possibility of false detections." : "はい、センサーは IP67 等級ですが、誤検出の可能性を減らすために、再使用する前にユニットの電源を切り、完全に乾燥させることをお勧めします。",
    "If you have more queries please click below our official website" : "さらに質問がある場合は、以下をクリックして当社の公式ウェブサイトをご覧ください",
    "Follow us on" : "フォローしてください",
    "Download Apps" : "アプリをダウンロードする",
    "User Guides" : "ユーザーガイド",
    "IoP® Platform User Manual" : "IoP® プラットフォーム ユーザーマニュアル",
    "IoP® Technician’s Handbook" : "IoP® 技術者向けハンドブック",
    "Frequently Asked Questions" : "よくある質問",
    "Visit" : "訪問",
    "IoP® website" : "IoP®ウェブサイト",
    "for more information" : "詳細については",
    "Sensor detected possible pest shelter or entrance point." : "センサーが害虫の避難所または侵入口の可能性を検出しました。",
    "Sensors detected possible food source or entrance point." : "センサーが食物源または侵入口の可能性を検出しました。",
    "Error: Unable to connect to the backend server." : "エラー: バックエンド サーバーに接続できません。",
    "Sensor type not enabled: Crawling Insect" : "センサーのタイプが有効になっていません: 這う昆虫",
    "Sensor type not enabled: Rodent" : "センサーのタイプが有効になっていません: げっ歯類",
    "Sensor type not enabled: Fly" : "センサータイプが有効になっていません: フライ",
    "Sensor type not enabled: Cigarette Beetle" : "センサータイプが有効になっていません:タバコビートル",
    "Zone Events" : "ゾーンイベント",
    "zone live monitoring disabled" : "ゾーンのライブ監視が無効になっています",
    "zone live monitoring enabled" : "ゾーンのライブ監視が有効になっています",
    "live monitoring toggle" : "ライブモニタリングの切り替え",
    "live monitoring" : "ライブモニタリング",
    "live monitoring disabled" : "ライブモニタリングが無効です",
    "live monitoring enabled" : "ライブモニタリングを有効にする",
    "enable live monitoring" : "ライブモニタリングを有効にする",
    "Enabling live monitoring will allow pest detections and alerts in real-time from being updated on the platform." : "ライブモニタリングを有効にすると、害虫の検出とアラートがプラットフォーム上でリアルタイムに更新されるようになります。",
    "I confirm that I have turned ON all the sensors and they are reporting Active." : "すべてのセンサーがオンになっており、アクティブであると報告されていることを確認します。",
    "successfully enabled the live monitoring" : "ライブモニタリングが正常に有効になりました",
    "disable live monitoring" : "ライブモニタリングを無効にする",
    "Disabling live monitoring will ignore any pest detections that would be sent by the sensors and will prevent real-time detections and alerts from being updated on the platform." : "ライブモニタリングを無効にすると、センサーから送信される害虫の検出が無視され、プラットフォーム上でリアルタイムの検出とアラートが更新されなくなります。",
    "I confirm to disable live monitoring for this zone." : "このゾーンのライブ監視を無効にすることを確認します。",
    "Daily heartbeat and power ON messages will NOT be ignored and the application will continue to update the Communication Status and Last Seen Timestamp for all the sensors in this zone." : "毎日のハートビートと電源オンのメッセージは無視されず、アプリケーションはこのゾーン内のすべてのセンサーの通信ステータスと最終検出タイムスタンプを更新し続けます。",
    "successfully disabled the live monitoring" : "ライブモニタリングが正常に無効になりました",
    "sensor event rejected due to disabled zone live monitoring" : "無効なゾーンライブモニタリングによりセンサーイベントが拒否されました",
    "Warning! Zone Live monitoring is currently disabled. Last sensor detection was" : "警告！ゾーンのライブ監視は現在無効になっています。最後のセンサー検出は",
    "Enable Live Monitoring for the zone to start logging  sensor detections." : "ゾーンのライブ モニタリングを有効にして、センサー検出のログを開始します。",
    "The following sensors are not Active. Please cycle the sensor power and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : "次のセンサーはアクティブではありません。このゾーンのライブ モニタリングを有効にする前に、センサーの電源を入れ直し、アプリケーションでセンサーがアクティブであることを報告していることを確認してください。",
    "I acknowledge the above issues and still want to enable live monitoring." : "私は上記の問題を認識しており、ライブモニタリングを有効にしたいと考えています。",
    "The following Active sensors were last seen before Live Monitoring for this Zone was Disabled on" : "次のアクティブ センサーは、このゾーンのライブ モニタリングが無効になる前に最後に確認されました。",
    ". If you may have switched OFF the following sensors, then please switch them back ON and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : "。次のセンサーのスイッチをオフにした可能性がある場合は、このゾーンのライブ モニタリングを有効にする前に、スイッチをオンに戻し、アプリケーションでセンサーがアクティブであることを報告していることを確認してください。",
    "Warning: Enable Live Monitoring Failed" : "警告: ライブモニタリングの有効化に失敗しました",
    "remove floor map" : "フロアマップを削除",
    "Live Monitoring" : "ライブモニタリング",
    "for the zone where you are adding the sensor is" : "センサーを追加するゾーンの場合は、",
    "Disabled" : "無効",
    "Please" : "お願いします",
    "Enable" : "有効にする",
    "after you have added and turned ON all the sensors in this zone to monitoring pest detections" : "害虫の検出を監視するために、このゾーン内のすべてのセンサーを追加してオンにした後",
    "Remove Zone Map" : "ゾーンマップの削除",
    "Live Monitoring for this zone is" : "このゾーンのライブモニタリングは",
    "disabled." : "無効。",
    "After you have added and turned ON all the sensors in this zone;" : "このゾーン内のすべてのセンサーを追加してオンにした後、",
    "enable " : "有効にする ",
    "Live Monitoring to monitor pest detections." : "害虫の検出を監視するライブモニタリング。",
    "Disabled Live Monitoring Zones" : "無効化されたライブ監視ゾーン",
    "Zone Map Removed Successfully" : "ゾーン マップが正常に削除されました",
    "Upload zone map" : "ゾーンマップをアップロードする",
    "No zone map found!" : "ゾーンマップが見つかりません!",
    "last updated timestamp" : "最終更新タイムスタンプ",
    "Zone Map Uploaded Successfully" : "ゾーン マップが正常にアップロードされました",
    "all roles" : "すべての役割",
    "no detections reported!" : "検出は報告されていません!",
    "Removing the zone map will un-map all the currently mapped sensor and delete the zone map. Are you sure you want to remove the zone map?" : "ゾーン マップを削除すると、現在マッピングされているすべてのセンサーのマッピングが解除され、ゾーン マップが削除されます。ゾーン マップを削除してもよろしいですか?",
    "Successfully added sensor(s)." : "センサーが正常に追加されました。",
    "Click here" : "ここをクリック",
    "to map these sensors on the zone map." : "これらのセンサーをゾーン マップにマッピングします。",
    "has been automatically" : "自動的に行われました",
    "for the" : "のために",
    "click here " : "ここをクリック",
    "live monitoring for this zone only after turning" : "このゾーンのライブ監視は回転後にのみ行われます",
    "zone to prevent any false detections during handling of the sensors." : "センサーの取り扱い中の誤検知を防ぐためのゾーン。",
    "ON" : "の上",
    "the newly added sensors." : "新しく追加されたセンサー。",
    "is not mapped on the zone map" : "ゾーンマップにマッピングされていません",
    "latest sensor image" : "最新のセンサー画像"
 }