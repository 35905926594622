import { Component, Input, Inject, OnInit } from '@angular/core';
import { RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-navigate-items',
  templateUrl: './navigate-items.component.html',
  styleUrls: ['./navigate-items.component.scss']
})
export class NavigateItemsComponent implements OnInit {
  @Input() name: string;
  constructor(@Inject(RouterLinkActive) public active: RouterLinkActive) {
  }

  ngOnInit() {
  }

}
