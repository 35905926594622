import { Component, OnInit, Input } from '@angular/core';
import { Status } from 'src/app/helpers/config/status';
import { CustomerService } from 'src/app/modules/iop-module/customers/customer.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sensor-donut-chart',
  templateUrl: './sensor-donut-chart.component.html',
  styleUrls: ['./sensor-donut-chart.component.scss']
})
export class SensorDonutChartComponent implements OnInit {

  urlId: any;
  @Input() getRecords: any;
  @Input() getTitle: string;
  flyData: any;
  breachedLink: string;
 // breachedFlyLink: string;
  //breachedRodentLink: string;

  constructor(private _customerService : CustomerService, private _routeParam: ActivatedRoute, private _router: Router ) { }

  ngOnInit() {

    this._routeParam.params.subscribe((param: any) => {

      if(param['customerId'] && param['siteId'] && param['zoneId']){

        this.breachedLink = '/home/customers/list/'+ param['customerId'] +'/site/'+ param['customerId']+ '/'+param['siteId']+'/zone/'+param['customerId'] + '/' +param['siteId'] +'/'+ param['zoneId'] + '/activities/' + param['customerId'] + '/'+param['siteId'] +'/'+ param['zoneId'];

      }else if (param['customerId'] && param['siteId']){

        this.breachedLink = '/home/customers/list/'+ param['customerId'] +'/site/'+ param['customerId']+'/'+param['siteId']+'/activities/'+param['customerId'] + '/' + param['siteId'];

      }else if(param['customerId'] && param['zoneId']){

        this.breachedLink = '/home/customers/list/'+param['customerId'] +'/zone/'+param['customerId']+'/'+param['zoneId']+'zone/activities/'+param['customerId']+'/'+ param['zoneId'];

      }else if(param['customerId']){

        this.breachedLink = '/home/customers/list/'+param['customerId']+'/activities/'+param['customerId'];
        
      };

    })

// breached api call
    // this._customerService.getBreachedCount(this.getRecords).subscribe((breachedResp : breached.breachedRespon) =>{

    //   if (breachedResp.status = Status.OK) { 
        
    //     const data = breachedResp['data'] ? breachedResp['data'] : {};
    //     let fly_activity = data['Fly Activity'] ? data['Fly Activity'] : { "false": 0, "true": 0 };
    //     fly_activity['false'] = fly_activity['false'] + fly_activity['true'];
    //     this.flyData = fly_activity;

    //     // let cockroach_activity = data['Cockroach Activity'] ? data['Cockroach Activity'] : { "false": 0, "true": 0 };
    //     // cockroach_activity['false'] = cockroach_activity['false'] + cockroach_activity['true'];
    //     // this.cockroachData = cockroach_activity;
    //     // let rodent_activity = data['Rodent Activity'] ? data['Rodent Activity'] :  { "false": 0, "true": 0 };
    //     // rodent_activity['false'] = rodent_activity['false'] + rodent_activity['true'];
    //     // this.rodentData = rodent_activity;

    //   }
     
    // })

  }

  // nav to selected activity
  onNavActivities(getUrl,getParamType){
    this._router.navigate([getUrl], { queryParams: { 'filter': getParamType } });
  }

}