import { AuthenticationService } from './../authentication.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private _authService: AuthenticationService, private _route: Router) { }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this._authService.isLoggedIn()) {
      this._route.navigate(['./']);
      return false;
    }
    
    // Store last active URL prior to logout, so user can be redirected on re-login
    // localStorage.setItem('returnUrl', JSON.stringify(state.url));
    
    localStorage.setItem('returnUrl', undefined);
    return true;
  }

  canActivateChild( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad( route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})

export class Login implements CanActivate, CanActivateChild, CanLoad {

  constructor(private _authService: AuthenticationService, private _route: Router) {  }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.isLoggedIn()) {
      this._route.navigate(['./home']);
      return false;
    }
    return true;
  }

  canActivateChild( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad( route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

}

@Injectable({
  providedIn: 'root'
})
export class Rules implements CanActivate, CanActivateChild, CanLoad {

  constructor(private _authService: AuthenticationService, private _route: Router) { }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let isAllow: boolean = false;
      //const ruleSet = this._authService.getRoleModule();   
      const ruleSet = this._authService.getRolePermissions(); 
      const keys = next.data.rules;
      //console.log("HTML ruleSet ",ruleSet);
      // console.log("next.data.rules >>", next.data.rules);
      ruleSet.filter(rules => {
        // if(rules.resourceName.toLowerCase() == keys[0].toLowerCase()) { // Resource Name
          if(parseInt(rules.permissionId) == parseInt(keys[1])) { // permission ID
            // if(rules.actionName.toLowerCase() == keys[2].toLowerCase()) { // Action Name
              isAllow = true;
            // }
          }
        // }
      })

      return isAllow;// true;

    // ruleSet.filter(rules => {
    //   if (rules.name === keys[0]) {
    //     rules.components.filter(component => {
    //       if (component.name === keys[1]) {
    //         component.allowPermissions.filter(allowPermission => {
    //           if (allowPermission.name === keys[2]) {
    //             isAllow = allowPermission.status
    //           }
    //         })
    //       }
    //     })
    //   }
    //})
    
  }

  canActivateChild( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad( route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

}